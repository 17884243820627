import { IProductMeta } from 'types/product';

import { getIndexes } from '..';
import {
  productHasPriceAndStock,
  productMetaAttributes,
} from '../../query-fragments';

const { anonUserToken, productIndex } = getIndexes();

const getProductMetaByVariantCode = async (
  variantCodes: [string, string][],
) => {
  let mappedCodes: string[] = [];
  let mappedSkus: string[] = [];

  variantCodes.forEach(([sku, code]) => {
    mappedCodes.push(`variationGroupCode:${code}`);
    mappedSkus.push(`sproutlSku:${sku}`);
  });

  if (mappedCodes.length < 1) {
    return {
      products: [],
    };
  }

  const { hits, queryID, facets } = await productIndex.search<IProductMeta>(
    '',
    {
      attributesToRetrieve: productMetaAttributes,
      clickAnalytics: true,
      filters: `(${mappedCodes.join(' OR ')}) AND NOT ${mappedSkus.join(
        ' AND NOT ',
      )} AND (${productHasPriceAndStock})`,
      hitsPerPage: 1000,
      userToken: anonUserToken,
    },
  );

  return {
    products: hits,
    queryId: queryID,
    facets,
  };
};

export default getProductMetaByVariantCode;
