import { groq } from 'next-sanity';

export const partnersListingPageQuery = groq`
  *[_type == "partnerIndex"] {
    _id,
    meta,
    partners[]->{
      slug,
      name,
      shortLocation,
      banner {
          ...,
          image {
              asset->
          }
      }
    }
  }
`;

export const partnerPageQuery = groq`
{
  "partner": *[_type == "partner" && slug.current == $slug] {
    ...,
    "favPicks": favPicks[]->{
      sku,
    },
    "products": products[]->{
      sku,
    }
  }
}
`;
