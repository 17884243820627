export enum GlobalEvents {
  closeSearch = 'close-search',
  openSizeGuide = 'open-size-guide',
  openEmailPopup = 'open-email-popup',
  openAccordion = 'open-accordion',
  headerBasketRefetch = 'header-basket-refetch',
}

export const trigger = (name: GlobalEvents, detail?: any) => {
  const event = detail ? new CustomEvent(name, { detail }) : new Event(name);
  window.dispatchEvent(event);
};
