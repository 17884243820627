import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

import { Flow } from '../flow';

interface Heading6Props {
  inline?: boolean;
}

const Heading6 = styled.h6<Heading6Props>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-2);
  color: inherit;

  p + &,
  h1 + &,
  h2 + & {
    margin-top: ${({ inline }) => (inline ? 0 : '1.5em')};
  }

  ${Flow} & {
    margin-bottom: 0;
  }
`;

export default Heading6;
