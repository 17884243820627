import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

import { Flow } from '../flow';

interface Heading4Props {
  inline?: boolean;
}

const Heading4 = styled.h4<Heading4Props>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-4);
  color: inherit;

  p + &,
  h1 + &,
  h2 + & {
    margin-top: ${({ inline }) => (inline ? 0 : '1.5em')};
  }

  ${Flow} & {
    margin-bottom: 0;
  }
`;

export default Heading4;
