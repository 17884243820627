export const LatestArticlesQuery = `
  _type == 'latestArticles' => {
    "latestArticles": *[_type == "article"] | order(_createdAt desc)[0..3] {
      ...,
      category->,
      image {
        ...,
        asset->{
          url
        }
      }
    },
    "featuredArticle": featuredArticle -> {
      ...,
      category->,
      image {
        ...,
        asset->{
          url
        }
      }
    }
  }
`;
