import { createClient } from 'next-sanity';

import { sanityConfig } from './config';

// Set up the client for fetching data in the getProps page functions
const sanityClient = createClient(sanityConfig);

/**
 * Set up a preview version of the sanity client
 * @link https://github.com/sanity-io/next-sanity#usage
 */
const previewClient = createClient({
  ...sanityConfig,
  useCdn: false,
  token: process.env.SANITY_API_TOKEN,
  withCredentials: true,
});

export const isPreviewServer = () => !!process.env.SANITY_API_TOKEN;

// Return the appropriate client for the type of server
export const getClient = () =>
  isPreviewServer() ? previewClient : sanityClient;
