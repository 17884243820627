import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';

export const articlePageQuery = groq`
  *[_type == "article" && slug.current == $slug] {
    ...,
    image {
      ...,
      asset->
    },
    headerBlocks[] {
      ${BlocksInnerFragment}
    },
    footerBlocks[] {
      ${BlocksInnerFragment}
    }
  }
`;
