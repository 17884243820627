import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { BoldableSiteLink } from 'components/common/boldable-cta';
import Container from 'components/common/container';
import SiteLink from 'components/common/site-link';
import { PortableText } from 'framework/sanity/portable-text';
import { urlFor } from 'framework/sanity/url-for';

import { MegaNavAdvert } from '../advert';
import { NavColumn, NavTab } from '../types';

import {
  DesktopOnly,
  Wrapper,
  LeftColumn,
  Columns,
  Column,
  Underlay,
  TabsRow,
  ContentRow,
  TabsFlexRow,
  AdditionalContent,
} from './styles';
import { MegaNavDesktopProps, MegaNavDesktopContentProps } from './types';

function DesktopColumn({ column }: { column: NavColumn }) {
  const titleEl = column.path ? (
    <SiteLink variant="secondary" href={column.path}>
      {column.title}
    </SiteLink>
  ) : (
    <strong>{column.title}</strong>
  );

  return (
    <Column allowWrap={column.allowWrap}>
      {titleEl}
      {!!column.links?.length && (
        <ul>
          {column.links?.map((link) => {
            if (!link.path || !link.title) {
              return null;
            }

            const icon = link.icon
              ? urlFor(link.icon).width(50).auto('format').fit('max').url()
              : '';

            return (
              <li key={link._key}>
                <SiteLink href={link.path} variant="secondary">
                  {icon && <img src={icon} alt="" />}
                  {link.title}
                </SiteLink>
              </li>
            );
          })}
        </ul>
      )}
    </Column>
  );
}

function MegaNavDesktopContent({
  section,
  selectedTab,
  setSelectedTab,
}: MegaNavDesktopContentProps) {
  const adverts = selectedTab?.hideAdverts
    ? []
    : selectedTab?.adverts?.length
    ? selectedTab.adverts
    : section.adverts;

  const hasContentRow =
    !!selectedTab?.subItems?.length || !!selectedTab?.content?.length;

  return (
    <>
      {!!section.subItems?.length ? (
        <TabsRow>
          <Container noGutter>
            <TabsFlexRow>
              {section.subItems?.map((tab) => (
                <BoldableSiteLink
                  variant="secondary"
                  key={tab._key}
                  href={tab.path || ''}
                  active={selectedTab === tab}
                  onMouseOver={() => setSelectedTab(tab)}
                  underline={selectedTab === tab}
                  label={tab.title}
                />
              ))}
            </TabsFlexRow>
          </Container>
        </TabsRow>
      ) : null}
      {hasContentRow ? (
        <ContentRow>
          <Container noGutter>
            <LeftColumn size="m-xl">
              {selectedTab.content?.length && (
                <PortableText blocks={selectedTab.content} />
              )}
              <Columns>
                {selectedTab.subItems?.map((column) => (
                  <DesktopColumn column={column} key={column._key} />
                ))}
              </Columns>
            </LeftColumn>
            {!!adverts?.length && (
              <AdditionalContent
                prioritiseAdverts={selectedTab.prioritiseAdverts}
                advertCount={adverts.length}
              >
                {adverts.map((advert) => (
                  <MegaNavAdvert advert={advert} key={advert._key} />
                ))}
              </AdditionalContent>
            )}
          </Container>
        </ContentRow>
      ) : null}
    </>
  );
}

export default function MegaNavDesktop({
  section,
  isVisible,
  transitionDuration,
  onClose,
}: MegaNavDesktopProps) {
  const [selectedTab, setSelectedTab] = useState<NavTab | undefined>(
    section.subItems?.length === 1 ? section.subItems[0] : undefined,
  );

  useEffect(() => {
    setSelectedTab(
      section.subItems?.length === 1 ? section.subItems[0] : undefined,
    );
  }, [section]);

  const router = useRouter();

  const internalOnClose = useCallback(() => {
    onClose && onClose();
    setSelectedTab(undefined);
  }, [onClose]);

  useEffect(() => {
    router.events.on('routeChangeComplete', internalOnClose);

    return () => {
      router.events.off('routeChangeComplete', internalOnClose);
    };
  }, [router.events, internalOnClose]);

  return (
    <DesktopOnly>
      {!!section.subItems?.length && (
        <Wrapper isVisible={isVisible || !!selectedTab}>
          <MegaNavDesktopContent
            key={section._key}
            section={section}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </Wrapper>
      )}
      <Underlay
        isOpen={!!selectedTab}
        isClear={
          !!selectedTab &&
          !selectedTab?.subItems?.length &&
          !selectedTab?.adverts?.length &&
          !selectedTab?.content
        }
        transitionDuration={transitionDuration}
        onMouseOver={internalOnClose}
      />
    </DesktopOnly>
  );
}
