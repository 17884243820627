import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';

import { throwServerSideError } from 'utils/helpers';

import { ConfirmAddressProps, ConfirmAddressResponse } from './types';

/**
 * Makes a POST call to /api/checkout/confirm-address
 * Intended for client-side use only
 * @param {ConfirmAddressProps} shippingAddress
 * @async
 */
export default async function checkoutConfirmAddress({
  cartId,
  shippingAddress,
}: ConfirmAddressProps): Promise<ConfirmAddressResponse> {
  throwServerSideError();

  try {
    const result: ConfirmAddressResponse = await fetch(
      '/api/checkout/confirm-address',
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ cartId, shippingAddress }),
      },
    ).then((response) => response.json());

    if (result.error) {
      throw new Error(result.error?.message);
    }

    return result;
  } catch (error: any) {
    console.error(error);
    Sentry.captureException(error, { level: Severity.Fatal });
    throw new Error('Failed to save delivery address, please try again.');
  }
}
