import buildUrl from 'cloudinary-build-url';
import { ImageLoaderProps } from 'next/image';

const cleanImageRegex =
  /(https:\/\/res\.cloudinary\.com\/sproutl\/images\/f_auto,q_auto,dpr_auto,w_1000,h_1000,c_limit\/|#.+)/g;

export default function cloudinaryLoader({
  src,
  width,
  quality = 80,
}: Pick<ImageLoaderProps, 'src' | 'width' | 'quality'>): string {
  try {
    // PDP format has hardcoded transforms for CS Cart compatibility. TODO update once switchover is complete.
    let path = decodeURI(src).replace(cleanImageRegex, '');

    const newSrc = buildUrl(path, {
      cloud: {
        cloudName: 'sproutl',
      },
      transformations: {
        quality,
        resize: {
          type: 'limit',
          width,
          aspectRatio: '1:1',
        },
      },
    }).replace('image/upload', 'images');

    return `${newSrc}.jpg`;
  } catch {
    // Temp fix to prevent malformed URI crash
    return src;
  }
}
