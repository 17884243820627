import styled, { css } from 'styled-components';

import { mobile } from 'utils/media';
import theme from 'utils/theme';

import SiteLink from '../site-link';

import { IBoldableCTAProps, IBoldableLabelProps } from './types';

export const CTA = styled.button<IBoldableCTAProps>`
  color: ${({ colorScheme = 'pineGreen' }) => theme.colors[colorScheme]};

  &:hover {
    --weight: ${theme.weights.bold};
    --underline: underline;
  }

  ${mobile} {
    --underline: underline;
  }

  svg {
    margin-right: var(--space-2xs);
  }
`;

export const CTALink = styled(SiteLink)`
  color: ${theme.colors.black};

  ${({ active }) =>
    active &&
    css`
      --weight: ${theme.weights.bold};
      --underline: underline;
      color: ${theme.colors.irisPurple};
    `}

  &:hover {
    --weight: ${theme.weights.bold};
    --underline: underline;
    color: ${theme.colors.irisPurple};
  }
`;

export const BoldableLabel = styled.span<IBoldableLabelProps>`
  display: inline-block;
  font-weight: var(--weight);
  text-decoration: var(--underline);

  // font weight changes on hover causes elements to shift because of the change in size
  // add pseudo content with the hover font weight applied so the container element is always at full size.
  &::before {
    display: block;
    content: '${({ label }) => label}';
    height: 0;
    overflow: hidden;
    visibility: hidden;
    font-weight: ${theme.weights.bold};
  }
`;
