import { ClientConfig } from 'next-sanity';

interface TempClientConfig extends ClientConfig {
  documentLimit?: number;
}

export const sanityConfig: TempClientConfig = {
  /**
   * Find your project ID and dataset in `sanity.json` in your studio project.
   * These are considered “public”, but you can use environment variables
   * if you want differ between local dev and production.
   *
   * https://nextjs.org/docs/basic-features/environment-variables
   * */
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET || 'headless',
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID || 'fpwrylru',
  /**
   * Set useCdn to `false` if your application require the freshest possible
   * data always (potentially slightly slower and a bit more expensive).
   * Authenticated request (like preview) will always bypass the CDN
   * */
  useCdn: true,
  apiVersion: '2021-12-20',
};
