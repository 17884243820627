import { FC, useState } from 'react';

import { Chevron } from 'components/common/icons';
import SiteLink from 'components/common/site-link';

import * as St from '../styles';

import { INavColProps } from './types';

export const NavCol: FC<INavColProps> = ({ column }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenToggle = () => setIsOpen(!isOpen);

  return (
    <St.NavCol isOpen={isOpen}>
      <St.ColTitle onClick={handleOpenToggle}>
        {column.title}
        <Chevron />
      </St.ColTitle>
      <St.NavColLinks>
        {column?.links?.map((link) => (
          <SiteLink
            key={link._key}
            href={link.path || '/'}
            title={link.title}
            variant="secondary"
          >
            {link.title}
          </SiteLink>
        ))}
      </St.NavColLinks>
    </St.NavCol>
  );
};
