import React from 'react';

interface Props {
  height?: number;
}

export default function Plant({ height = 61 }: Props) {
  return (
    <svg
      height={height}
      viewBox="0 0 34 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7 42H8.3v5h16.4v-5ZM20.4 39.5c1.1 0 2.3-.1 3.2-.6 1.4-.6 2-2.2 1.9-3.6 0-.9-.4-1.7-.9-2.3 2 .5 4.3.1 5.2-2.2.7-1.8.2-3.8-1.3-4.8.7 0 1.4 0 2-.2a3.8 3.8 0 0 0 2.5-4c-.3-2-2.4-3.5-4.4-3 .7-.5 1.2-1.2 1.6-2 .5-1 .7-2 .6-3.1-.1-1-.7-2.1-1.7-2.6-1.3-.6-2.7-.4-4 .2l-.5.2.2-.2v-.2l.4-.7a8.7 8.7 0 0 0 .6-3v-.2a2.8 2.8 0 0 0-3-2.6l-.5.1c-.5 0-2 .6-2.4 1.1.1-1 .3-2.2 0-3.3v-.1l-.1-.2a4.2 4.2 0 0 0-.3-.5v-.1h-.1v-.1l-.1-.1v-.1L19 1c-.3-.2-.5-.5-.8-.6a3.4 3.4 0 0 0-.3-.2h-.2V.1h-.1l-.3-.1a2.8 2.8 0 0 0-.7-.1H16.1l-.3.1-.2.1h-.1v.1h-.2a3.4 3.4 0 0 0-1 .8v.2h-.1l-.1.2-.1.1-.1.2-.1.2V2l-.1.1v.1l-.1.2c-.4 1-.2 2.2 0 3.3-.4-.5-2-1-2.4-1.1a3.6 3.6 0 0 0-.8 0A2.7 2.7 0 0 0 7.7 7v1c0 1 .3 1.8.7 2.6v.3l.4.6.1.3v-.1a2.2 2.2 0 0 0-.8-.5 5 5 0 0 0-4-.1c-1 .5-1.6 1.5-1.7 2.6-.1 1 .2 2.1.6 3.1.4.8.9 1.5 1.6 2-.6-.4-2 0-2.5.3-.8.5-1.4 1.2-1.7 2-.7 2 .5 4.1 2.3 4.7.6.2 1.3.2 2 .2-1.5 1-2 3-1.3 4.8 1 2.3 3.2 2.7 5.2 2.1-.5.7-.8 1.5-.9 2.4 0 1.4.5 3 1.9 3.6 1 .5 2.2.6 3.2.6h7.6Z"
        fill="#000"
      />
    </svg>
  );
}
