import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';
import {
  CollectionGroupFragment,
  CollectionPreviewFragment,
} from './fragments/collections';

export const collectionPageQuery = groq`
  *[_type == "collection" && slug.current == $slug]{
    _id,
    pimId,
    'slug': slug.current,
    meta,
    title,
    filters[] {
      'id': _key,
      'type': _type,
      facet,
      label,
      tooltip,
      iconPosition,
      'value': defaultValue,
      options[] {
        label,
        value,
        filterValues,
        subtitle,
        icon->{
          title,
          icon {
            ...
          }
        }
      }
    },
    'content': {
      ...content,
      'hero': content.hero[] {
        ...,
        image {
          ...,
          'lqip': asset->metadata.lqip,
          asset->
        },
        mobileImage {
          ...,
          'lqip': asset->metadata.lqip,
          asset->
        }
      },
      'nutshells': content.nutshells[]{
        "_id": _key,
        "value": name,
        ...,
        "icon": icon->icon
      },
    },
    supergroups[] {
      _key,
      title,
      id,
      requiredFilterTags,
      groups[] {
        ${CollectionGroupFragment}
      }
    },
    compostSupergroup[] {
      ${CollectionGroupFragment}
    },
    toolsSupergroup[] {
      ${CollectionGroupFragment}
    },
    blocks[] {
      ${BlocksInnerFragment}
    },
    preferredPartners
  }
`;

export const collectionListingPageQuery = groq`
  *[_type == "collectionIndex"] {
    _id,
    meta,
    blocks[] {
      ${BlocksInnerFragment}
    }
  }
`;

export const collectionPDPQuery = groq`
  *[_type == "collection" && pimId in $collectionPimIds && preview.image != undefined][0]{
    ${CollectionPreviewFragment}
  }
`;
