import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentRequest,
  PaymentRequestPaymentMethodEvent,
  PaymentRequestShippingAddressEvent,
} from '@stripe/stripe-js';
import axios, { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  AddressDataFragment,
  Maybe,
  MyPaymentVersionFragmentDoc,
  PaymentVersionFragmentDoc,
} from 'generated/api/graphql';
import { getAddressFromObject } from 'utils/address';
import { initializeApollo } from 'utils/apollo-client';
import checkoutConfirmAddress from 'utils/checkout/confirm-address';
import { checkoutConfirmExpressOrder } from 'utils/checkout/confirm-express-order';
import { checkoutConfirmOrder } from 'utils/checkout/confirm-order';
import checkoutCreateMerchantCart from 'utils/checkout/create-merchant-cart';
import {
  ActiveStep,
  AddressFields,
  UseTotalsResult,
  MaybeCartOrOrder,
  UsePayNowProps,
  UsePayNowResult,
  UseExpressPaymentProps,
  UseExpressProductPaymentProps,
  UsePlaceOrderProps,
  UsePlaceOrderResult,
} from 'utils/checkout/types';
import { useGroupedActiveCart } from 'utils/commercetools/cart';
import { IGroupedOrder } from 'utils/commercetools/types';
import { trackMailingOptOut } from 'utils/gtm/events';
import { scrollToTop } from 'utils/helpers';
import { getExpressCheckoutDeliveryRate } from 'utils/shipping/rates';
import { isValidUKPhoneNumber } from 'utils/validation';

import { isPostcodeValidAndAllowed } from './utils';

const CHECKOUT_STEPS_ARRAY: ActiveStep[] = [
  'basket',
  'details',
  'delivery',
  'payment',
];

export const CHECKOUT_STEPS: Record<Uppercase<ActiveStep>, ActiveStep> = {
  BASKET: 'basket',
  DETAILS: 'details',
  DELIVERY: 'delivery',
  PAYMENT: 'payment',
};

const CHECKOUT_STEPS_INDEX: Record<Uppercase<ActiveStep>, number> = {
  BASKET: 0,
  DETAILS: 1,
  DELIVERY: 2,
  PAYMENT: 3,
};

function getInitialActiveStep(hasShippingAddress: boolean): ActiveStep {
  if (hasShippingAddress) {
    return CHECKOUT_STEPS.DELIVERY;
  }
  return CHECKOUT_STEPS.DETAILS;
}

/**
 * Hook for setting up the checkout steps
 * @returns
 */
export function useCheckoutSteps() {
  const { data: groupedActiveCart } = useGroupedActiveCart();

  const hasShippingAddress = !!groupedActiveCart?.shippingAddress?.streetName;

  const [activeStep, setActiveStep] = useState<ActiveStep>(
    getInitialActiveStep(hasShippingAddress),
  );

  const handleNextStep = useCallback(() => {
    setActiveStep((currentActiveStep) => {
      if (currentActiveStep === CHECKOUT_STEPS.DETAILS) {
        return CHECKOUT_STEPS.DELIVERY;
      }
      if (currentActiveStep === CHECKOUT_STEPS.DELIVERY) {
        return CHECKOUT_STEPS.PAYMENT;
      }
      return currentActiveStep;
    });
    scrollToTop();
  }, []);

  const handleBackToDetailsStep = useCallback(() => {
    setActiveStep(CHECKOUT_STEPS.DETAILS);
    scrollToTop();
  }, []);

  const handleBackToDeliveryStep = useCallback(() => {
    setActiveStep(CHECKOUT_STEPS.DELIVERY);
    scrollToTop();
  }, []);

  const isActiveStepAfterDetails = useCallback(
    () =>
      CHECKOUT_STEPS_ARRAY.indexOf(activeStep) > CHECKOUT_STEPS_INDEX.DETAILS,
    [activeStep],
  );

  const isActiveStepAfterDelivery = useCallback(
    () =>
      CHECKOUT_STEPS_ARRAY.indexOf(activeStep) > CHECKOUT_STEPS_INDEX.DELIVERY,
    [activeStep],
  );

  return {
    activeStep,
    handleNextStep,
    handleBackToDetailsStep,
    handleBackToDeliveryStep,
    isActiveStepAfterDetails,
    isActiveStepAfterDelivery,
  };
}

/**
 * Hook to update the Payment Intent and CT Payment with the latest totalPrice
 * @param {number} totalPrice
 * @returns
 */
export function useUpdatePaymentIntent(totalPrice: number) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (totalPrice) {
      setLoading(true);
      setError(null);

      axios({
        method: 'post',
        url: `/api/checkout/update-payment-intent`,
      })
        .then(
          (
            result: AxiosResponse<{
              success: boolean;
              payment: { id: string; version: number };
            }>,
          ) => {
            setLoading(false);

            const apolloClient = initializeApollo();

            // MyPayment is used by client-side requests
            apolloClient.writeFragment({
              id: `MyPayment:${result.data.payment.id}`,
              fragment: MyPaymentVersionFragmentDoc,
              data: {
                id: result.data.payment.id,
                version: result.data.payment.version,
              },
            });

            // Payment is used by server-side requests
            apolloClient.writeFragment({
              id: `Payment:${result.data.payment.id}`,
              fragment: PaymentVersionFragmentDoc,
              data: {
                id: result.data.payment.id,
                version: result.data.payment.version,
              },
            });
          },
        )
        .catch((e) => {
          setError(
            "Sorry, there was an error updating your cart's payment. Please refresh to try again.",
          );
          Sentry.captureException(
            new Error('Payment intent update failed in checkout'),
            {
              contexts: {
                originalException: e,
                axiosResponse: e.response,
                axiosRequest: e.request,
              },
              level: Severity.Fatal,
            },
          );
        });
    }
  }, [totalPrice, setLoading]);

  return { loading, error };
}

/**
 * Hook to return a concatendated string of an address object
 * @param {Maybe<AddressFields | AddressDataFragment>} address
 * @param {boolean} excludeName
 * @returns {string | null} addressPreview
 */
export function useAddressPreview(
  address: Maybe<AddressFields | AddressDataFragment>,
  excludeName: boolean = false,
): string | null {
  return useMemo(() => {
    if (!address) {
      return null;
    }

    const addressArray = [
      `${address?.firstName || ''} ${address?.lastName || ''}`,
      address?.streetName,
      address?.additionalStreetInfo,
      address?.city,
      address?.postalCode,
    ];

    if (excludeName === true) {
      addressArray.shift();
    }

    const addressPreview = addressArray
      .map((val) => (val && typeof val === 'string' ? val.trim() : ''))
      .filter((val) => val)
      .join(', ');

    return addressPreview;
  }, [address, excludeName]);
}

/**
 * Hook to get the computed cart or order totals
 * @param cartOrOrder
 * @returns
 */
export function useTotals(cartOrOrder: MaybeCartOrOrder): UseTotalsResult {
  return useMemo(() => {
    const totals = {
      subtotal: 0,
      qty: 0,
      discount: 0,
    };

    if (!cartOrOrder || !cartOrOrder?.lineItems) {
      return totals;
    }

    cartOrOrder.lineItems.forEach((lineItem) => {
      // Discounts
      lineItem.discountedPricePerQuantity.forEach(
        (discountedPricePerQuantity) => {
          discountedPricePerQuantity.discountedPrice.includedDiscounts.forEach(
            (includedDiscount) => {
              totals.discount -=
                includedDiscount.discountedAmount.centAmount *
                discountedPricePerQuantity.quantity;
            },
          );
        },
      );

      // Subtotal
      totals.subtotal += lineItem.price.value.centAmount * lineItem.quantity;

      // Qty
      totals.qty += lineItem.quantity;
    });

    // Add shipping discounts to discount total
    if (cartOrOrder.shippingInfo?.discountedPrice?.includedDiscounts) {
      cartOrOrder.shippingInfo?.discountedPrice?.includedDiscounts.forEach(
        (discount) => {
          // Used this to get around the types for HighPrecisionMoney being incorrect
          if (discount.discountedAmount.__typename === 'Money') {
            totals.discount -= discount.discountedAmount.centAmount;
          }
        },
      );
    }

    return totals;
  }, [cartOrOrder]);
}

/**
 * Hook to trigger the confirm order API and confirm the stripe payment
 * @param {UsePayNowProps} props
 * @returns {UsePayNowResult}
 */
export function usePayNow({
  order,
  setOrder,
  setError,
  setIsLoading,
  setPaymentIntent,
  paymentIntentClientSecret,
}: UsePayNowProps): UsePayNowResult {
  const { data: groupedCart } = useGroupedActiveCart();

  const shippingAddress = groupedCart?.shippingAddress;

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = useCallback(
    async (values) => {
      // Stripe.js has not loaded yet
      if (!stripe || !elements || !shippingAddress) return;
      const cardNumberElement = elements.getElement(CardNumberElement);
      // CardNumber element is not available
      if (!cardNumberElement) return;

      setIsLoading(true);
      setError(null);

      // Get billing address either from Shipping Address or form values
      let newBillingAddress;
      if (values.billingSameAsShipping === true) {
        newBillingAddress = getAddressFromObject(shippingAddress);
      } else {
        // The billing address form doesn't have email or phone number fields
        newBillingAddress = {
          ...getAddressFromObject(values),
          phone: shippingAddress?.phone || '',
          email: shippingAddress?.email || '',
        };
      }

      try {
        // Don't recreate an order if it's already been created
        // @todo add in scenario to update the order billing address after it's created
        if (!order) {
          // Call confirm order API
          const newOrder = await checkoutConfirmOrder({
            billingAddress: newBillingAddress,
            // Has to explicitly not opt out to prevent undefined as subscription
            joinMailingList: values.optOutMailingList === false ? true : false,
          });

          setOrder(newOrder);
          if (values.optOutMailingList) {
            trackMailingOptOut();
          }
        }

        // Pay with stripe
        // Create a Stripe PaymentMethod from the card details, this will later
        // be used to confirm the payment intent
        const paymentIntentResponse = await stripe.confirmCardPayment(
          paymentIntentClientSecret,
          {
            payment_method: {
              card: cardNumberElement,
              billing_details: {
                address: {
                  city: newBillingAddress.city,
                  line1: newBillingAddress.streetName,
                  line2: newBillingAddress.additionalStreetInfo,
                  postal_code: newBillingAddress.postalCode,
                  country: newBillingAddress.country,
                },
                name: values.ccname,
                email: shippingAddress.email || undefined,
                phone: shippingAddress.phone || undefined,
              },
            },
          },
        );

        if (paymentIntentResponse.error) {
          setError(`Payment failed, ${paymentIntentResponse.error.message}`);
          setIsLoading(false);
        } else {
          setPaymentIntent(paymentIntentResponse.paymentIntent);
          setIsLoading(false);
        }
      } catch (error: any) {
        setError(`${error}`);
        setIsLoading(false);

        Sentry.captureException(error, {
          contexts: {
            request: error.request,
            response: error.response,
          },
          level: Severity.Fatal,
        });
      }
    },
    [
      order,
      stripe,
      elements,
      setOrder,
      setError,
      setIsLoading,
      shippingAddress,
      setPaymentIntent,
      paymentIntentClientSecret,
    ],
  );

  return {
    onPaymentFormSubmit: onSubmit,
  };
}

/**
 * Hook to trigger the confirm order API and confirm the stripe payment
 * @param {UsePlaceOrderProps} props
 * @returns {UsePlaceOrderResult}
 */
export function usePlaceOrder({
  order,
  setOrder,
  setError,
  setIsLoading,
}: UsePlaceOrderProps): UsePlaceOrderResult {
  const { data: groupedCart } = useGroupedActiveCart();

  const shippingAddress = groupedCart?.shippingAddress;

  const onSubmit = useCallback(async () => {
    if (!shippingAddress) return;

    setIsLoading(true);
    setError(null);

    try {
      // Don't recreate an order if it's already been created
      if (!order) {
        // Call confirm order API
        const newOrder = await checkoutConfirmOrder();

        setOrder(newOrder);
      }

      setIsLoading(false);
    } catch (error: any) {
      setError(`${error}`);
      setIsLoading(false);

      Sentry.captureException(error, {
        contexts: {
          request: error.request,
          response: error.response,
        },
        level: Severity.Fatal,
      });
    }
  }, [order, setOrder, setError, setIsLoading, shippingAddress]);

  return {
    onPlaceOrder: onSubmit,
  };
}

export function useExpressPayment({
  setOrder,
  setError,
  setIsLoading,
  setPaymentIntent,
  paymentIntentClientSecret,
}: UseExpressPaymentProps) {
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const [enabledPayments, setEnabledPayments] = useState({
    applePay: false,
    googlePay: false,
    paymentRequest: false,
  });

  const { data: groupedActiveCart, refetch } = useGroupedActiveCart();

  const showPaymentRequest = useCallback(() => {
    if (paymentRequest) {
      setIsLoading(true);
      paymentRequest.show();
    }
  }, [paymentRequest, setIsLoading]);

  useEffect(() => {
    // This should only run once, paymentRequest is truthy on future calls
    if (!stripe || !groupedActiveCart || paymentRequest) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: String(groupedActiveCart.totalPrice.currencyCode).toLowerCase(),
      total: {
        label: 'Total',
        amount: groupedActiveCart?.totalPrice.centAmount,
        pending: true,
      },
      displayItems: groupedActiveCart?.lineItems.map(
        ({ name, quantity, totalPrice }) => ({
          amount: totalPrice?.centAmount,
          label: `${quantity} x ${name}`,
        }),
      ),
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: true,
    });

    // Check the availability of the Payment Request API
    pr.canMakePayment().then((result) => {
      // result is null if Payment Request isn't available
      if (result) {
        setPaymentRequest(pr);
        setEnabledPayments({
          applePay: result.applePay,
          googlePay: result.googlePay,
          // Payment Request should only be enabled if both apple pay and google pay are not availble
          paymentRequest:
            result.applePay === false && result.googlePay === false,
        });
      }
    });

    pr.on('cancel', () => {
      // Update the cart data from the server and remove the loading spinner
      if (refetch) {
        refetch().finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
      }
    });

    pr.on('shippingaddresschange', async (ev) => {
      const { shippingAddress, updateWith } = ev;
      const isValidPostcode = isPostcodeValidAndAllowed(
        shippingAddress?.postalCode,
      );

      if (shippingAddress.country !== 'GB' || !isValidPostcode) {
        updateWith({ status: 'invalid_shipping_address' });
      } else {
        let addressLine1 = '';
        let addressLine2 = '';
        if (Array.isArray(shippingAddress.addressLine)) {
          [addressLine1, addressLine2] = shippingAddress.addressLine;
        }

        // Perform server-side request to fetch shipping options
        const response = await checkoutConfirmAddress({
          shippingAddress: {
            firstName: '',
            lastName: '',
            streetName: addressLine1,
            additionalStreetInfo: addressLine2,
            city: shippingAddress.city || '',
            postalCode: shippingAddress.postalCode || '',
            phone: shippingAddress.phone || '',
            email: '',
            country: 'GB',
            additionalAddressInfo: '',
            useAsDefaultAddress: false,
          },
        });

        if (!response?.rates || !response?.cart) {
          return updateWith({
            status: 'fail',
          });
        }

        updateWith({
          status: 'success',
          total: {
            label: 'Total',
            amount: response.cart.totalPrice.centAmount,
            pending: false,
          },
          shippingOptions: getExpressCheckoutDeliveryRate(response.rates),
        });
      }
    });

    pr.on('paymentmethod', async (ev) => {
      const {
        paymentMethod,
        complete,
        payerEmail,
        payerPhone,
        shippingAddress,
      } = ev;

      if (!isValidUKPhoneNumber(payerPhone)) {
        return complete('invalid_payer_phone');
      }

      const isValidPostcode = isPostcodeValidAndAllowed(
        shippingAddress?.postalCode,
      );

      if (
        !shippingAddress ||
        shippingAddress.country !== 'GB' ||
        !isValidPostcode
      ) {
        return complete('invalid_shipping_address');
      }

      let order: IGroupedOrder;

      try {
        order = await checkoutConfirmExpressOrder({
          paymentMethod,
          payerEmail,
          payerPhone,
          shippingAddress,
        });

        if (!order) {
          return complete('fail');
        }
      } catch (e: any) {
        return complete('fail');
      }

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const paymentIntentResponse = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        { payment_method: paymentMethod.id },
        { handleActions: false },
      );

      if (paymentIntentResponse.error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        complete('fail');
      } else {
        const { paymentIntent } = paymentIntentResponse;
        setPaymentIntent(paymentIntent);

        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        complete('success');

        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow.
        if (paymentIntent && paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(
            paymentIntentClientSecret,
          );
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            if (refetch) {
              refetch().then(() => {
                setError('An error occured, please try again');
                setIsLoading(false);
              });
            } else {
              setError('An error occured with your payment, please try again');
              setIsLoading(false);
            }
          } else {
            setOrder(order);
            setIsLoading(false);
          }
        } else {
          setOrder(order);
          setIsLoading(false);
        }
      }
    });
  }, [
    stripe,
    refetch,
    setError,
    setOrder,
    setIsLoading,
    paymentRequest,
    setPaymentIntent,
    groupedActiveCart,
    paymentIntentClientSecret,
  ]);

  return { showPaymentRequest, enabledPayments };
}

export function useExpressProductPayment({
  productData: {
    projection: { productWithBestOffer: product },
    name,
  },
  setOrder,
  setError,
  quantity,
  setIsLoading,
  setPaymentIntent,
  supplementalProducts,
}: UseExpressProductPaymentProps) {
  const stripe = useStripe();
  const bestOffer = product.bestOffer;

  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
    null,
  );

  const [cartId, setCartId] = useState<string | undefined>();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false);

  const [cartLoading, setCartLoading] = useState(false);

  const [paymentRequestClientSecret, setPaymentRequestClientSecret] = useState<
    string | undefined
  >();

  const [enabledPayments, setEnabledPayments] = useState({
    applePay: false,
    googlePay: false,
    paymentRequest: false,
  });

  const closeConfirmation = useCallback(() => setIsConfirmationOpen(false), []);

  const openConfirmation = useCallback(async () => {
    if (paymentRequest && bestOffer && bestOffer.channel) {
      setCartLoading(true);
      setIsConfirmationOpen(true);

      const lineItems = [
        {
          name,
          sku: product.sku || '',
          photos: product.images,
          channel: bestOffer.channel,
          quantity,
          price: bestOffer.price,
        },
      ];

      if (!!supplementalProducts && supplementalProducts.length > 0) {
        try {
          supplementalProducts.forEach((supplementalProduct) => {
            lineItems.push({
              name: supplementalProduct?.productName,
              sku: supplementalProduct?.sproutlSku,
              photos: supplementalProduct?.images,
              channel: {
                typeId: 'channel',
                id: supplementalProduct?.selectedPartner?.id || '',
                key: undefined,
              },
              price: {
                centAmount: supplementalProduct?.centAmount,
                fractionDigits: 2,
              },
              quantity: supplementalProduct.quantity,
            });
          });
        } catch (error: any) {
          Sentry.captureException(error, { level: Severity.Fatal });
        }
      }

      const { cart, paymentRequestClientSecret } =
        await checkoutCreateMerchantCart(lineItems);

      setCartId(cart.id);
      setPaymentRequestClientSecret(paymentRequestClientSecret);

      paymentRequest.update({
        total: {
          label: 'Total',
          amount: cart.totalPrice.centAmount,
          pending: true,
        },
        displayItems: cart.lineItems.map(({ name, quantity, totalPrice }) => ({
          amount: totalPrice?.centAmount,
          label: `${quantity} x ${name['en-GB']}`,
        })),
      });

      setCartLoading(false);
    }
  }, [
    name,
    product.images,
    product.sku,
    bestOffer,
    paymentRequest,
    quantity,
    supplementalProducts,
  ]);

  const showPaymentRequest = useCallback(async () => {
    if (paymentRequest && bestOffer) {
      setIsLoading(true);

      paymentRequest.show();
    }
  }, [bestOffer, setIsLoading, paymentRequest]);

  useEffect(() => {
    // Don't attempt anything if we don't have stock or a price
    if (!bestOffer) {
      return;
    }

    // This effect should only run once. paymentRequest is not null on future calls
    if (!stripe || paymentRequest !== null) {
      return;
    }

    const pr = stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: 'Total',
        amount: bestOffer.price.centAmount,
        pending: true,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      requestPayerPhone: true,
      requestShipping: true,
    });

    // Check the availability of the Payment Request API
    pr.canMakePayment().then((result) => {
      // result is null if Payment Request isn't available
      if (result) {
        setPaymentRequest(pr);
        setEnabledPayments({
          applePay: result.applePay,
          googlePay: result.googlePay,
          // Payment Request should only be enabled if both apple pay and google pay are not availble
          paymentRequest:
            result.applePay === false && result.googlePay === false,
        });
      }
    });
  }, [bestOffer, paymentRequest, stripe]);

  const onCancel = useCallback(() => {
    // Update the cart data from the server and remove the loading spinner
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('cancel', onCancel);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('cancel', onCancel);
      }
    };
  }, [onCancel, paymentRequest]);

  const onShippingAddressChange = useCallback(
    async (ev: PaymentRequestShippingAddressEvent) => {
      const { shippingAddress, updateWith } = ev;
      const isValidPostcode = isPostcodeValidAndAllowed(
        shippingAddress.postalCode,
      );

      if (shippingAddress.country !== 'GB' || !isValidPostcode) {
        updateWith({ status: 'invalid_shipping_address' });
      } else {
        let addressLine1 = '';
        let addressLine2 = '';
        if (Array.isArray(shippingAddress.addressLine)) {
          [addressLine1, addressLine2] = shippingAddress.addressLine;
        }

        // Perform server-side request to fetch shipping options
        const response = await checkoutConfirmAddress({
          cartId,
          shippingAddress: {
            firstName: '',
            lastName: '',
            streetName: addressLine1,
            additionalStreetInfo: addressLine2,
            city: shippingAddress.city || '',
            postalCode: shippingAddress.postalCode || '',
            phone: shippingAddress.phone || '',
            email: '',
            country: 'GB',
            additionalAddressInfo: '',
            useAsDefaultAddress: false,
          },
        });

        if (!response?.rates || !response?.cart) {
          return updateWith({
            status: 'fail',
          });
        }

        const { rates, cart } = response;

        let displayItems = cart.lineItems.map(
          ({ name, quantity, totalPrice }) => ({
            amount: totalPrice?.centAmount,
            label: `${quantity} x ${name['en-GB']}`,
          }),
        );

        if (cart.shippingInfo) {
          displayItems = displayItems.concat([
            {
              amount: cart.shippingInfo?.price.centAmount,
              label: 'Delivery',
            },
          ]);
        }

        updateWith({
          status: 'success',
          total: {
            label: 'Total',
            amount: cart.totalPrice.centAmount,
            pending: false,
          },
          shippingOptions: getExpressCheckoutDeliveryRate(rates),
          displayItems,
        });
      }
    },
    [cartId],
  );

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('shippingaddresschange', onShippingAddressChange);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off('shippingaddresschange', onShippingAddressChange);
      }
    };
  }, [onShippingAddressChange, paymentRequest]);

  const onPaymentMethod = useCallback(
    async (ev: PaymentRequestPaymentMethodEvent) => {
      if (!stripe || !paymentRequestClientSecret) {
        return;
      }

      const {
        complete,
        payerEmail,
        payerPhone,
        paymentMethod,
        shippingAddress,
      } = ev;

      if (!isValidUKPhoneNumber(payerPhone)) {
        return complete('invalid_payer_phone');
      }

      const isValidPostcode = isPostcodeValidAndAllowed(
        shippingAddress?.postalCode,
      );

      if (
        !shippingAddress ||
        shippingAddress.country !== 'GB' ||
        !isValidPostcode
      ) {
        return complete('invalid_shipping_address');
      }

      let order: IGroupedOrder;

      try {
        order = await checkoutConfirmExpressOrder({
          cartId,
          paymentMethod,
          payerEmail,
          payerPhone,
          shippingAddress,
        });

        if (!order) {
          return complete('fail');
        }
      } catch (e: any) {
        return complete('fail');
      }

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const paymentIntentResponse = await stripe.confirmCardPayment(
        paymentRequestClientSecret,
        { payment_method: paymentMethod.id },
        { handleActions: false },
      );

      if (paymentIntentResponse.error) {
        // Report to the browser that the payment failed, prompting it to
        // re-show the payment interface, or show an error message and close
        // the payment interface.
        complete('fail');
      } else {
        const { paymentIntent } = paymentIntentResponse;
        setPaymentIntent(paymentIntent);

        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        complete('success');

        // Check if the PaymentIntent requires any actions and if so let Stripe.js
        // handle the flow.
        if (paymentIntent && paymentIntent.status === 'requires_action') {
          // Let Stripe.js handle the rest of the payment flow.
          const { error } = await stripe.confirmCardPayment(
            paymentRequestClientSecret,
          );
          if (error) {
            // The payment failed -- ask your customer for a new payment method.
            setError('An error occured with your payment, please try again');
            setIsLoading(false);
          } else {
            setOrder(order);
            setIsLoading(false);
          }
        } else {
          setOrder(order);
          setIsLoading(false);
        }
      }
    },
    [
      cartId,
      stripe,
      setError,
      setOrder,
      setIsLoading,
      setPaymentIntent,
      paymentRequestClientSecret,
    ],
  );

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on('paymentmethod', onPaymentMethod);
    }

    return () => {
      if (paymentRequest) {
        paymentRequest.off('paymentmethod', onPaymentMethod);
      }
    };
  }, [onPaymentMethod, paymentRequest]);

  return {
    cartLoading,
    enabledPayments,
    isConfirmationOpen,
    openConfirmation,
    closeConfirmation,
    showPaymentRequest,
  };
}
