import { FC } from 'react';
import styled from 'styled-components';

import { LogoIcon } from '../icons';

const IconListEl = styled.li.attrs(
  (props: Pick<IIconListItemProps, 'iconSize' | 'space'>) => ({
    iconSize: props.iconSize || 'var(--space-s-xl)',
    space: props.space || 'var(--space-s-l)',
  }),
)`
  list-style: none;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--space-2xs);
  align-items: center;

  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
  }

  & + & {
    margin-top: ${({ space }) => space};
  }
`;

interface IIconListItemProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  iconSize?: string;
  space?: string;
}

export const IconListItem: FC<IIconListItemProps> = ({
  children,
  icon,
  iconSize,
  space,
}) => {
  return (
    <IconListEl iconSize={iconSize} space={space}>
      {icon || <LogoIcon color="currentColor" />} {children}
    </IconListEl>
  );
};
