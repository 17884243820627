import { IMappedProductTileData } from 'types/product-commercetools';

import { ADD_RECENT_PRODUCT_TO_STATE, SET_INITIAL_STATE } from './constants';

function addRecentProductToState(product: IMappedProductTileData) {
  return {
    type: ADD_RECENT_PRODUCT_TO_STATE,
    product,
  };
}

function setInitialState(skus: string[], products: IMappedProductTileData[]) {
  return {
    type: SET_INITIAL_STATE,
    skus,
    products,
  };
}

export { addRecentProductToState, setInitialState };
