import Link from 'next/link';
import React from 'react';

import { relativeUrl } from 'utils/helpers';

import { SLink } from './styles';
import { ILinkProps } from './types';

const SiteLink: React.FC<ILinkProps> = ({
  href,
  title,
  children,
  variant = 'primary',
  scroll,
  target,
  external,
  active,
  ariaLabel,
  underline,
  'data-testid': dataTestId,
  prefetch = true,
  rel = '',
  replace,
  ...rest
}) => (
  <Link
    href={relativeUrl(href)}
    scroll={scroll}
    passHref
    prefetch={!prefetch ? prefetch : undefined}
    replace={replace}
  >
    <SLink
      {...rest}
      title={title}
      variant={variant}
      target={external ? '_blank' : target}
      rel={`${rel}${external ? ' noopener noreferrer' : ``}`}
      active={active}
      aria-label={ariaLabel}
      underline={underline}
      data-testid={dataTestId}
    >
      {children}
    </SLink>
  </Link>
);

export default SiteLink;
