import Link from 'next/link';
import { FC } from 'react';

import Image from 'components/common/image';
import ResponsiveBox from 'components/common/responsive-box';
import { Heading3 } from 'components/common/text';
import { trackHeaderAdvert } from 'utils/gtm/events';

import { NavAdvert } from '../types';

import * as St from './styles';

export const MegaNavAdvert: FC<{ advert: NavAdvert }> = ({ advert }) => {
  return (
    <St.Advert
      as="li"
      onClick={() => trackHeaderAdvert(advert.trackingId || advert.path || '')}
    >
      {advert.image && (
        <figure>
          <ResponsiveBox aspectRatio={0.77} rounded size="tile">
            <Image src={advert.image} aspectRatio={0.77} alt="" sizes="500px" />
          </ResponsiveBox>
        </figure>
      )}
      {(advert.title || advert.cta) && (
        <header>
          {advert.title && (
            <Heading3>
              {advert.path ? (
                <Link href={advert.path}>{advert.title}</Link>
              ) : (
                advert.title
              )}
            </Heading3>
          )}
          {advert.cta && <p>{advert.cta}</p>}
        </header>
      )}
    </St.Advert>
  );
};
