import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { debounce } from 'lodash';

import { getAddress } from 'components/common/forms/address-form/utils';

import { trackBlacklistedPostcode } from './gtm/events';

type PostcodeBlacklist =
  | string
  | {
      prefix: string;
      blacklist: number[];
    };

const debounedPostcodeTrack = debounce(trackBlacklistedPostcode, 500);

const POSTCODE_BLACKLIST: PostcodeBlacklist[] = [
  'IM',
  'GY',
  'JE',
  'BT',
  'IV',
  'HS',
  'ZE',
  'AB',
  'KW',
  'PH',
  {
    prefix: 'PO',
    blacklist: [30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41],
  },
  {
    prefix: 'TR',
    blacklist: [21, 22, 23, 24, 25],
  },
  {
    prefix: 'KA',
    blacklist: [26, 27, 28],
  },
  {
    prefix: 'PA',
    blacklist: [
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
      38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 60, 61, 62, 63, 64, 65, 66, 67,
      68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 80,
    ],
  },
];

export const ALLOWED_CHARACTER_REGEX = /^[a-zA-Z0-9\.\-\\\/@_'&,()\s]*$/gi;
export const ALLOWED_CHARACTER_REGEX_WITH_DIACRITICS =
  /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\.\-\\\/@_'&,()\s]*$/gi;
// Source: http://emailregex.com/
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const POSTCODE_REGEX = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
const ALPHABET_REGEX = /[a-z]/i;
const SPACE_REGEX = /\s/g;
const UK_PHONE_NUMBER_REGEX =
  /^((((\+|00)44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;

export const isPostcodeBlacklisted = (
  postCode: string | undefined | null,
): boolean => {
  if (!postCode) {
    return false;
  }

  const formattedPostcode = postCode.toUpperCase().replace(SPACE_REGEX, '');

  const isBlacklisted = POSTCODE_BLACKLIST.some((blacklistedPostcode) => {
    if (typeof blacklistedPostcode === 'string') {
      return formattedPostcode.startsWith(blacklistedPostcode);
    } else {
      if (!formattedPostcode.startsWith(blacklistedPostcode.prefix)) {
        return false;
      }
    }

    const secondPortion = formattedPostcode.slice(
      blacklistedPostcode.prefix.length,
      -3,
    );

    return blacklistedPostcode.blacklist.includes(Number(secondPortion));
  });

  if (isBlacklisted) {
    debounedPostcodeTrack(postCode);
  }

  return isBlacklisted;
};

export const isValidPostcode = (postcode?: string): boolean =>
  POSTCODE_REGEX.test(postcode || '');

// @TODO: This could use some better validation
export const isValidPhoneNumber = (phoneNumber: string | undefined): boolean =>
  !ALPHABET_REGEX.test(String(phoneNumber).trim());

export const isValidUKPhoneNumber = (
  phoneNumber: string | undefined,
): boolean =>
  UK_PHONE_NUMBER_REGEX.test(String(phoneNumber).replace(SPACE_REGEX, ''));

let lastPostcode = '';
let lastPostcodeValidationResult = true;

export async function isPostcodeReal(postcode?: string): Promise<boolean> {
  if (!postcode) {
    return false;
  }

  const cleanedPostcode = postcode.replace(SPACE_REGEX, '').toUpperCase();

  if (cleanedPostcode === lastPostcode) {
    return lastPostcodeValidationResult;
  }

  lastPostcode = cleanedPostcode;

  if (!isValidPostcode(cleanedPostcode)) {
    lastPostcodeValidationResult = false;

    return lastPostcodeValidationResult;
  }

  try {
    const { data } = (await getAddress(cleanedPostcode)) || {};
    const { addresses } = data || {};

    lastPostcodeValidationResult = addresses?.length > 0;
  } catch (error) {
    console.error('Error contacting postcode service:', error);
    Sentry.captureException(error, { level: Severity.Fatal });

    // default to a validated postcode on API error
    lastPostcodeValidationResult = true;
  }

  return lastPostcodeValidationResult;
}
