import { MultipleQueriesQuery, SearchResponse } from '@algolia/client-search';

import { getIndexes } from 'framework/algolia/api';

const { searchClient } = getIndexes();

const multiindexSearch = async <T>(
  queries: MultipleQueriesQuery[],
): Promise<SearchResponse<T>[]> => {
  const { results } = await searchClient.search<T>(queries.filter(Boolean));
  return results;
};

export default multiindexSearch;
