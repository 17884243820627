import { ProductProjection } from '@commercetools/platform-sdk';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';

import { getProjectionForSkus } from 'framework/commercetools/api';
import { IProductProjection } from 'types/product-commercetools';
import { normalizeAttributes } from 'utils/helpers';

import findMatchingVariant from '../find-matching-variant';
import getProjectionBestOffers from '../get-projection-best-offers';

import { TOptions } from './types';

async function getProducts(
  skus: string[],
  options: TOptions = {},
): Promise<IProductProjection[]> {
  const { hideOffStock } = options;

  if (!skus?.length) {
    return [];
  }

  try {
    const ctProducts = await getProjectionForSkus(skus);

    const productsOnStock = ctProducts
      .reduce((acc: IProductProjection[], curr: ProductProjection) => {
        // add best offers to all Variants
        const projectionWithOffers = getProjectionBestOffers(curr);
        const productsWithBestOffer = findMatchingVariant(projectionWithOffers);
        const projectionsWithProduct: IProductProjection[] =
          productsWithBestOffer
            .map((productWithBestOffer) => ({
              ...projectionWithOffers,
              productWithBestOffer,
              attributesNormalized: normalizeAttributes(productWithBestOffer),
            }))
            // remove products with published set to false
            .filter(
              ({ attributesNormalized }) =>
                attributesNormalized.published !== false,
            );

        // remove OOS Products
        if (hideOffStock) {
          return [
            ...acc,
            ...projectionsWithProduct.filter(
              ({ productWithBestOffer }) =>
                !!productWithBestOffer.bestOffer?.isOnStock,
            ),
          ];
        }

        // TODO: add optional Variants data
        // * Because this may merge all variant data it should be set as
        // * a new property on the updated Projection - a type is already
        // * in place for this (variantsWithBestOffer)

        return [...acc, ...projectionsWithProduct];
      }, [])
      // sort by provided SKU order
      .sort((a: any, b: any) => {
        return skus.indexOf(a.productWithBestOffer.sku) >
          skus.indexOf(b.productWithBestOffer.sku)
          ? 1
          : -1;
      });

    return productsOnStock;
  } catch (error: any) {
    Sentry.captureException(error, { level: Severity.Error });

    return [];
  }
}

export default getProducts;
