// Downwards
export const smallMobile = '@media(max-width: 374px)';
export const mobile = '@media(max-width: 699px)';
export const belowDesktop = '@media(max-width: 1024px)';
export const belowLargeDesktop = '@media(max-width: 1400px)';

// Upwards
export const tablet = '@media(min-width: 700px)';
export const desktopMinusOne = '@media(min-width: 1024px)';
export const desktop = '@media(min-width: 1025px)';
export const largeDesktop = '@media(min-width: 1400px)';

// Between
export const tabletToDesktop =
  '@media(min-width: 700px) and (max-width:1024px)';
