import { groq } from 'next-sanity';

import { ArticleFragment, BlocksInnerFragment } from './fragments';
import { ContentBlocksFragment } from './fragments/contentBlocks';
import { GenusTileFragment } from './fragments/genus';

const handleSharedOrManualText = (key: string) => `
  ${key} {
    'shared': sharedTextReference->text,
    'manual': manualText,
  }
`;

export const SEASON_CONTENT_KEYS = ['spring', 'summer', 'autumn', 'winter'];
export const CARE_CONTENT_KEYS = [
  'welcomeHome',
  'position',
  'watering',
  'feeding',
  'diseasesAndPests',
  'pruning',
  'protection',
  'dividing',
  'maintenance',
];

export const genusPageQuery = groq`
  *[_type == 'genus' && slug.current == $slug] {
    _id,
    title,
    name,
    slug,
    varieties,
    botanicalName,
    'productImages': images[] {
      photoIndex,
      'sku': productVariant->sku
    },
    aftercareGroup-> { 
      name,
      group,
        'content': { ${[...SEASON_CONTENT_KEYS, ...CARE_CONTENT_KEYS].map(
          handleSharedOrManualText,
        )} 
      },
      'extraBlocks': extraBlocks->blocks[] { ${BlocksInnerFragment} },
    },
    communityTips[] {
      ...,
      comment->,
    },
    meta,
  }
`;

export const genusPreviewQuery = groq`*[_type == "genus" && name == $genus][0] {
  ${GenusTileFragment}
  ${ContentBlocksFragment}
}`;
export const genusSlugMultipleQuery = groq`*[_type == "genus" && name in $genusList] {
  name,
  'slug': slug.current
}`;

export const aftercareLandingPageQuery = groq`
  *[_type == "aftercareIndex"] {
    _id,
    meta,
    heroImage {
      ...,
      asset->
    },
    benefits,
    relatedContent[] {
      _type,
      _id,
      alternativeImage {
        ...,
        asset->{
          url
        }
      },
      'article': article-> {
        ${ArticleFragment}
      },
      title,
      letter,
      colorScheme,
      cta
    },
    blocks[] {
      ${BlocksInnerFragment}
    },
    'genus': *[_type == 'genus'] {
      ${GenusTileFragment}
    }
  }
`;
