import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

import { Flow } from '../flow';

interface Heading3Props {
  inline?: boolean;
}

const Heading3 = styled.h3<Heading3Props>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-5);
  color: inherit;

  p + &,
  h1 + &,
  h2 + & {
    margin-top: 1.5em;
  }

  ${Flow} & {
    margin-bottom: 0;
  }
`;

export default Heading3;
