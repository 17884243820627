import React from 'react';

export default function TikTok() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6 0H2.4C1 0 0 1 0 2.4v15.2C0 19 1 20 2.4 20h15.2c1.3 0 2.4-1 2.4-2.4V2.4C20 1 19 0 17.6 0Zm-1.9 8.7a3.6 3.6 0 0 1-3.3-1.6v5.5a4 4 0 1 1-4-4h.2v2h-.3a2 2 0 1 0 0 4.1c1.2 0 2.2-1 2.2-2V3.2h2a3.6 3.6 0 0 0 3.2 3.2v2.2"
        fill="#fff"
      />
    </svg>
  );
}
