import { ReferralState } from 'components/checkout/success/components/referral-popup/types';
import { LineItemDataFragment, MoneyFragment } from 'generated/api/graphql';
import { AddedLineItem, IGroupedOrder } from 'utils/commercetools/types';
import { convertCentAmountToDollars } from 'utils/helpers';

import { PopupTimeProps, TAawSelectionOptions } from './types';
import {
  dataLayerEcomPush,
  dataLayerPush,
  getGA4Categories,
  mapLineItemForGA4,
  mapLineItemForUA,
  mapProductForGA4,
  mapProductForUA,
} from './utils';

/**
 * Track view category / search / partner page
 * @param list pageName - Search Page | Partner Page | Category Page
 * @param products
 */
export function trackViewProductsList(
  list: string,
  products: Sproutl.ProductListing[],
) {
  dataLayerEcomPush({
    event: 'view_item_list',
    ecommerce: {
      // UA
      currencyCode: 'GBP',
      impressions: products.map(mapProductForUA(list)),
      // GA4
      item_list_name: list,
      items: products.map(mapProductForGA4),
    },
  });
}

export interface ITrackViewProductProps {
  sku: string;
  price?: number | null;
  category: string;
  name: string;
}

/**
 * Track view product details page
 * @param product Product being viewed
 * @param bestOffer Price and availability from CT
 */
export function trackViewProduct({
  sku,
  name,
  price,
  category,
}: ITrackViewProductProps) {
  dataLayerEcomPush({
    event: 'view_item',
    ecommerce: {
      // UA
      detail: {
        products: [
          {
            name,
            id: sku,
            price: convertCentAmountToDollars(price),
            category,
          },
        ],
      },
      // GA4
      items: [
        {
          item_name: name,
          item_id: sku,
          price: convertCentAmountToDollars(price),
          ...getGA4Categories(category),
        },
      ],
    },
  });
}

/**
 * Track add to basket
 * @param lineItems Products being added (can be multiple with pot / plant)
 */
export function trackAddToBasket(
  lineItems: AddedLineItem[],
  event_source?: string,
) {
  dataLayerEcomPush({
    event: 'add_to_cart',
    event_source,
    ecommerce: {
      // UA
      currencyCode: 'GBP',
      add: {
        products: lineItems.map(
          ({ name, sku, quantity, price, categorySlug }) => ({
            name,
            id: sku,
            price: price && convertCentAmountToDollars(price.centAmount),
            quantity,
            category: categorySlug,
          }),
        ),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(
        lineItems.reduce((sum, item) => sum + item?.price?.centAmount, 0),
      ),
      items: lineItems.map(
        ({ name, sku, quantity, price, categorySlug = '' }) => ({
          item_name: name,
          item_id: sku,
          price: price && convertCentAmountToDollars(price.centAmount),
          quantity,
          ...getGA4Categories(categorySlug),
        }),
      ),
    },
  });
}

/**
 * Track remove item from basket
 * @param lineItem CT LineItem being removed
 */
export function trackRemoveFromBasket(lineItem: LineItemDataFragment) {
  dataLayerEcomPush({
    event: 'remove_from_cart',
    ecommerce: {
      // UA
      remove: {
        products: [mapLineItemForUA(lineItem)],
      },
      // GA4
      items: [mapLineItemForGA4(lineItem)],
    },
  });
}

/**
 * Track view basket page
 * UA doesn't have an event for this
 * @param cart
 */
export function trackViewBasket(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  // GA4
  dataLayerEcomPush({
    event: 'view_cart',
    ecommerce: {
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
    },
  });
}

/**
 * Track checkout start
 * @param cart
 */
export function trackBeginCheckout(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'begin_checkout',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 1 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon
    },
  });
}

/**
 * Track delivery step
 * @param cart
 */
export function trackAddDeliveryInfo(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'add_shipping_info',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 2 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon, shipping_tier
    },
  });
}

/**
 * Track payment step
 * @param cart
 */
export function trackAddPaymentInfo(
  totalPrice: MoneyFragment,
  lineItems: LineItemDataFragment[],
) {
  dataLayerEcomPush({
    event: 'add_payment_info',
    ecommerce: {
      // UA
      checkout: {
        actionField: { step: 3 },
        products: lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: 'GBP',
      value: convertCentAmountToDollars(totalPrice.centAmount),
      items: lineItems.map(mapLineItemForGA4),
      // coupon, payment_type
    },
  });
}

/**
 * Adds a successful transaction to the Google Tag Manager dataLayer
 * @todo add category and brand
 * @param {IGroupedOrder | null} order
 * @returns
 */
export function trackSuccessfulTransaction(order: IGroupedOrder | null) {
  if (!order) {
    return;
  }

  dataLayerEcomPush({
    event: 'purchase',
    // Basic Ecommerce, needed for Universal Analytics (no idea why)
    transactionId: order.orderNumber || order.id,
    transactionTotal: convertCentAmountToDollars(order.totalPrice.centAmount),
    transactionShipping: order.shippingInfo
      ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
      : null,
    transactionProducts: order.lineItems.map(
      ({ name, variant, quantity, price }) => ({
        name,
        sku: variant?.sku,
        price: price && convertCentAmountToDollars(price.value.centAmount),
        quantity,
      }),
    ),
    ecommerce: {
      // UA
      purchase: {
        actionField: {
          id: order.orderNumber || order.id,
          revenue: convertCentAmountToDollars(order.totalPrice.centAmount),
          shipping: order.shippingInfo
            ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
            : null,
          coupon: order.discountCodes?.[0]
            ? order.discountCodes?.[0].discountCode?.code
            : null,
        },
        products: order.lineItems.map(mapLineItemForUA),
      },
      // GA4
      currency: order.totalPrice.currencyCode,
      transaction_id: order.orderNumber || order.id,
      value: convertCentAmountToDollars(order.totalPrice.centAmount),
      coupon: order.discountCodes?.[0]
        ? order.discountCodes?.[0].discountCode?.code
        : null,
      shipping: order.shippingInfo
        ? convertCentAmountToDollars(order.shippingInfo.price.centAmount)
        : null,
      netValueExcludingShipping:
        order.taxedPrice && order.shippingInfo && order.shippingInfo.taxedPrice
          ? convertCentAmountToDollars(
              order.taxedPrice.totalNet.centAmount -
                order.shippingInfo.taxedPrice.totalNet.centAmount,
            )
          : null,
      items: order.lineItems.map(mapLineItemForGA4),
    },
  });
}

/**
 * Track blacklisted postcode
 */
export function trackBlacklistedPostcode(postcode: string) {
  dataLayerPush({
    event: 'blacklisted_postcode',
    event_source: postcode,
  });
}

/**
 * Track email sign ups
 * @param source the component from which this fired
 */
export function trackMarketingEmailSignup(source: string) {
  dataLayerPush({
    event: 'marketing_sign_up',
    event_source: source,
  });
}

/**
 * Track email sign ups
 * @param source the component from which this fired
 */
export function trackOOSSignup(sku: string) {
  dataLayerPush({
    event: 'oos_sign_up',
    sku,
  });
}

/**
 * Track mailing opt out
 */
export function trackMailingOptOut() {
  dataLayerPush({
    event: 'mailing_opt_out',
  });
}

/**
 * Track email popup dismissals
 */
export function trackMarketingEmailDismiss() {
  dataLayerPush({
    event: 'marketing_popup_dismiss',
  });
}

/**
 * Track how long the email popup stayed open, and whether it was a successful signup
 */
export function trackMarketingPopupTime({
  time = 0,
  success = false,
}: PopupTimeProps) {
  dataLayerPush({
    event: 'marketing_popup_time',
    time_open: time,
    signup_status: success ? 'Sign up' : 'Popup Close',
  });
}

/**
 * Algolia events
 */

export function updateAlgoliaUserToken(algoliaUserToken: string) {
  dataLayerPush({
    event: 'algolia_usertoken_change',
    algolia_insights_user_token: algoliaUserToken,
  });
}

/**
 * Track views hits from Algolia
 */
export function trackAlgoliaSearchHeaderHitsViewed(objectIds: string[]) {
  dataLayerPush({
    event: 'view_search_header_hits',
    algolia_index_name: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX,
    algolia_search_header_object_ids: objectIds,
  });
}

/**
 * Track views hits from Algolia
 */
export function trackAlgoliaHitsViewed(event: string, objectIds: string[]) {
  dataLayerPush({
    event,
    algolia_index_name: process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX,
    algolia_object_ids: objectIds,
  });
}

/**
 * Track OOS PDP views
 * @param url
 */
export function dataLayerOosPdpPageView(url: string) {
  dataLayerPush({
    event: 'oos_pdp_page_view',
    page: url,
  });
}

/**
 * Track size guide opens
 */
export function trackSizeGuideOpen(event_source: string) {
  dataLayerPush({
    event: 'size_guide',
    event_source,
  });
}

/**
 * Track video player cta click
 */
export function trackVideoPlayerCta(action: string, event_source: string) {
  dataLayerPush({
    event: 'video_player_cta',
    event_source,
    action,
  });
}

/**
 * Track video player play button click
 */
export function trackVideoPlayerPlay() {
  dataLayerPush({
    event: 'video_player_play',
  });
}

/**
 * Track video player ending
 */
export function trackVideoPlayerEnded() {
  dataLayerPush({
    event: 'video_player_ended',
  });
}

/**
 * Track video player pause
 */
export function trackVideoPlayerPaused(time_open: number) {
  dataLayerPush({
    event: 'video_player_pause',
    time_open,
  });
}

/**
 * Track video player close
 */
export function trackVideoPlayerClose(time_open: number) {
  dataLayerPush({
    event: 'video_player_close',
    time_open,
  });
}

// Hero

/**
 * Track hero CTAs
 */
export function trackHeroCta(action: string, event_source: string) {
  dataLayerPush({
    event: 'hero_cta',
    event_source,
    action,
  });
}

/**
 * Track hero videos
 */
export function trackHeroVideoPlay(event_source: string) {
  dataLayerPush({
    event: 'hero_play',
    event_source,
  });
}

export function trackHeroVideoEnded(event_source: string) {
  dataLayerPush({
    event: 'hero_ended',
    event_source,
  });
}

export function trackHeroVideoPaused(time_open: number, event_source: string) {
  dataLayerPush({
    event: 'hero_pause',
    time_open,
    event_source,
  });
}

export function trackHeroVideoClose(time_open: number, event_source: string) {
  dataLayerPush({
    event: 'hero_close',
    time_open,
    event_source,
  });
}

// Compost

export function trackCompostSuggestions(action: string) {
  dataLayerPush({
    event: 'compost_suggestion_interaction',
    action,
  });
}

// Header

export function trackHeaderAlert(event_source: string) {
  if (!event_source) return;

  dataLayerPush({
    event: 'header_alert_click',
    event_source,
  });
}

export function trackHeaderAdvert(event_source: string) {
  if (!event_source) return;

  dataLayerPush({
    event: 'header_advert_click',
    event_source,
  });
}

// Product carousels

export function trackCarouselProductClick(sku: string, event_source: string) {
  dataLayerPush({
    event: 'carousel_product_click',
    event_source,
    sku,
  });
}

// Collection advert

export function trackCollectionAdvert(sku: string, collection: string) {
  dataLayerPush({
    event: 'pdp_collection_advert',
    event_source: collection,
    sku,
  });
}

// PLP Adverts

export function trackPLPContentBlockClick(event_source: string) {
  dataLayerPush({
    event: 'plp_content_block_click',
    event_source,
  });
}

// PDP Adverts

export function trackPDPContentBlockClick(event_source: string) {
  dataLayerPush({
    event: 'pdp_content_block_click',
    event_source,
  });
}

// Category link
export function trackCategoryLinkClick(
  category_header_link_source: string,
  category_header_link_destination: string,
  category_header_link_position: number,
) {
  dataLayerPush({
    event: 'category_header_link_click',
    category_header_link_source,
    category_header_link_destination,
    category_header_link_position,
  });
}

// Brand link
export function trackBrandLinkClick(
  brand_header_link_source: string,
  brand_header_link_destination: string,
  brand_header_link_position: number,
) {
  dataLayerPush({
    event: 'brand_header_link_click',
    brand_header_link_source,
    brand_header_link_destination,
    brand_header_link_position,
  });
}

// Member get member
export function trackMGM(event_source: string) {
  dataLayerPush({
    event: 'member_get_member',
    event_source,
  });
}

export function trackMGMPopupClose(state: ReferralState) {
  trackMGM(
    `Popup closed ${
      {
        [ReferralState.idle]: 'before generating',
        [ReferralState.generating]: 'while generating',
        [ReferralState.share]: 'after generating',
        [ReferralState.copied]: 'after copying',
        [ReferralState.error]: 'after error',
      }[state]
    }`,
  );
}

// Trustpilot banner/CTA click
export function trackTrustpilotClick(event_source: string) {
  dataLayerPush({
    event: 'trustpilot_click',
    event_source,
  });
}

export function trackAawSelection({
  allowMultiSelect,
  isSelection,
  isSamePartner,
  productSku,
  productType,
  selectionSku,
}: TAawSelectionOptions) {
  dataLayerPush({
    event: isSelection ? 'aaw_selection' : 'aaw_deselection',
    aaw_allow_multiple_selections: allowMultiSelect,
    aaw_is_same_partner: isSamePartner,
    aaw_product_sku: productSku,
    aaw_selection_sku: selectionSku,
    aaw_selection_product_type: productType,
  });
}
