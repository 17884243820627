export const BillboardQuery = `
  _type == 'billboard' => {
    banner {
      ...,
      asset->{
        altText,
        url,
        _id,
        _type
      }
    }
  }
`;
