import styled, {
  css,
  FlattenInterpolation,
  ThemedStyledProps,
} from 'styled-components';

import theme from 'utils/theme';

import { IInputProps, TInputVariant } from './types';

interface IStyledInputProps {
  hasError?: boolean;
  bg: IInputProps['bg'];
  variant: TInputVariant;
  blurPlaceholder: boolean;
  floatingLabel?: boolean;
}

export const BlurPlaceholder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: ${theme.colors.grey};
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const variants: Record<
  TInputVariant,
  FlattenInterpolation<ThemedStyledProps<IStyledInputProps, any>>
> = {
  primary: css<IStyledInputProps>`
    border: 1px solid ${theme.colors.lightGrey};
    padding: var(--space-xs) var(--space-l) var(--space-xs);
    border-radius: var(--space-3xl);
    background-color: ${(props) => theme.colors[props.bg || 'white']};

    ${(props) =>
      props.floatingLabel &&
      css`
        padding-top: var(--space-s);
        padding-bottom: 0;
      `}

    &:focus {
      background-color: ${theme.colors.white};
      border-color: ${theme.colors.purple};
      box-shadow: 0 0 0 1px ${theme.colors.purple};
    }
  `,
  secondary: css<IStyledInputProps>`
    height: auto;
    border-bottom: 1px solid ${theme.colors.white};
    padding: 0 0 var(--space-2xs) 0;
  `,
  range: css<IStyledInputProps>`
    width: 100px;
    height: 34px;
    padding: 4px;
    text-align: center;
    border: 2px solid ${theme.colors.grey};
    border-radius: 40px;
    color: ${(props) =>
      props.blurPlaceholder ? 'transparent' : theme.colors.grey};

    &:focus {
      border-color: ${theme.colors.black};
      color: ${theme.colors.black};

      & + ${BlurPlaceholder} {
        display: none;
      }
    }
  `,
};

export const InputWrapper = styled.div`
  position: relative;
`;

export const Input = styled.input<IStyledInputProps>`
  width: 100%;
  height: ${theme.sizes.input.height}px;
  font-size: var(--step--5);
  background-color: transparent;
  color: inherit;
  padding: 0;
  border: none;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${theme.colors.midGrey};
    opacity: 1;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* @TODO: Define autofill colours */

  transition: border-color ${theme.transitionSpeeds.fast}s ease-out;

  ${(props) => variants[props.variant]}

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${theme.colors.errorRed};
    `}
`;
