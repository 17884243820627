import { createContext, FC, useReducer } from 'react';
import { useEffectOnce } from 'react-use';

import {
  getProducts,
  mapProjectionsToProductTile,
} from 'framework/commercetools/utils';
import { IProductProjection } from 'types/product-commercetools';

import { addRecentProductToState, setInitialState } from './actions';
import { initialState, reducer } from './reducer';
import { TState } from './types';
import { getLocalData, updateLocalData } from './utils';

const RecentlyViewed = createContext<{
  state: TState;
  addRecentProduct: (product: IProductProjection) => void;
}>({
  state: initialState,
  addRecentProduct: () => {},
});

const RecentlyViewedProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function getInitialData() {
    const recentSkus = getLocalData();
    const projections = await getProducts(recentSkus);
    const products = mapProjectionsToProductTile(projections);

    dispatch(setInitialState(recentSkus, products));
  }

  async function addRecentProduct(product: IProductProjection) {
    if (!product) {
      return;
    }

    updateLocalData(product.productWithBestOffer.sku);

    const [mappedProduct] = mapProjectionsToProductTile([product]);
    dispatch(addRecentProductToState(mappedProduct));
  }

  useEffectOnce(() => {
    getInitialData();
  });

  return (
    <RecentlyViewed.Provider value={{ state, addRecentProduct }}>
      {children}
    </RecentlyViewed.Provider>
  );
};

export { RecentlyViewed, RecentlyViewedProvider };
