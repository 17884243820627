import styled from 'styled-components';

import theme from 'utils/theme';

import { IProps } from './types';

export const Link = styled.a<IProps>`
  display: inline-flex;
  align-items: flex-end;
  font-size: var(--step--1);
  line-height: 1.2;
  border-bottom: 1px solid transparent;
  color: ${(props) => (props.theme ? theme.colors[props.theme] : 'inherit')};

  &:focus,
  &:hover {
    border-bottom-color: currentColor;
  }

  & svg {
    margin-left: var(--space-3xs);
  }
`;
