import React from 'react';

interface IGiftProps {
  width?: number;
  height?: number;
}

export default function Gift({ width = 23, height = 33 }: IGiftProps) {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 23 33"
    >
      <path
        d="M6.476 11.998c.018.004.04.013.06.017.418.128.848.22 1.283.272.426.05.852.054 1.278.062l4.967.008c.365 0 1.642-.128 2.429-.376 4.198-1.331 4.824-4.162 4.824-5.728V.01c-2.36.139-4.588 1.079-6.28 2.65-1.743 1.611-2.786 3.756-2.933 6.042l-1.17.02c-.138-2.306-1.19-4.472-2.95-6.088C6.27 1.053 4.012.118 1.628 0v6.253c0 1.574.63 4.41 4.848 5.744Z"
        fill="currentColor"
      />
      <path
        d="M22.968 14.274H12.427v8.402h10.541v-8.402Z"
        fill="currentColor"
      />
      <path
        d="M22.957 14.285v8.38H12.438v-8.38h10.519Zm.023-.022H12.415v8.424H22.98v-8.424Z"
        fill="currentColor"
      />
      <path
        d="M22.968 24.583H12.427v8.402h10.541v-8.402Z"
        fill="currentColor"
      />
      <path
        d="M22.957 24.594v8.38H12.438v-8.38h10.519Zm.023-.022H12.415v8.424H22.98v-8.424Z"
        fill="currentColor"
      />
      <path d="M10.553 24.583H.01v8.402h10.542v-8.402Z" fill="currentColor" />
      <path
        d="M10.541 24.594v8.38H.023v-8.38h10.518Zm.024-.022H0v8.424h10.565v-8.424Z"
        fill="currentColor"
      />
      <path d="M10.553 14.274H.01v8.402h10.542v-8.402Z" fill="currentColor" />
      <path
        d="M10.541 14.285v8.38H.023v-8.38h10.518Zm.024-.022H0v8.424h10.565v-8.424Z"
        fill="currentColor"
      />
    </svg>
  );
}
