import { noop } from 'lodash';
import React, { useContext } from 'react';

import { TrustpilotLogo } from 'components/common/icons';
import { Settings } from 'contexts';
import { trackTrustpilotClick } from 'utils/gtm/events';

import * as St from './styles';
import { IProps } from './types';

const TrustpilotBanner = ({ theme = 'black', eventSource }: IProps) => {
  const { trustpilot } = useContext(Settings);

  const score = trustpilot?.score;
  const text = score ? `${score} rating on` : 'See our reviews on';
  const onClick = eventSource ? () => trackTrustpilotClick(eventSource) : noop;

  return (
    <St.Link
      href="https://uk.trustpilot.com/review/sproutl.com"
      target="_blank"
      rel="noreferrer"
      theme={theme}
      onClick={onClick}
    >
      {text} <TrustpilotLogo />
    </St.Link>
  );
};

export default TrustpilotBanner;
