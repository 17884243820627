import {
  IProductProjectionInProgress,
  IProductVariant,
} from 'types/product-commercetools';

function findMatchingVariant(
  projection: IProductProjectionInProgress,
): IProductVariant[] {
  const matchingVariants = projection.variants?.filter(
    ({ isMatchingVariant }) => isMatchingVariant,
  );

  if (matchingVariants?.length > 0) {
    return matchingVariants;
  }

  return [projection.masterVariant];
}

export default findMatchingVariant;
