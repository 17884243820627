import { IProductMeta } from 'types/product';

import { getIndexes } from '..';
import {
  productHasPriceAndStock,
  productMetaAttributes,
} from '../../query-fragments';

type TOptions = {
  args?: Object;
  shouldHideOOS?: boolean;
};

const { anonUserToken, productIndex } = getIndexes();

const getProductMetaBySku = async (
  productSkus: string | string[],
  { args = {}, shouldHideOOS = true }: TOptions = {},
) => {
  const mappedSkus = Array.isArray(productSkus)
    ? productSkus.map((sku) => `sproutlSku:${sku}`).join(' OR ')
    : `sproutlSku:${productSkus}`;

  const { hits, queryID, facets } = await productIndex.search<IProductMeta>(
    '',
    {
      attributesToRetrieve: productMetaAttributes,
      clickAnalytics: true,
      filters: `(${mappedSkus})${
        shouldHideOOS ? ` AND ${productHasPriceAndStock}` : ''
      }`,
      userToken: anonUserToken,
      ...args,
    },
  );

  return {
    products: hits,
    queryId: queryID,
    facets,
  };
};

export default getProductMetaBySku;
