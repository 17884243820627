import { createImageUrlBuilder } from 'next-sanity';

import { sanityConfig } from './config';

/**
 * Set up a helper function for generating Image URLs with only the asset reference data in your documents.
 * Read more: https://www.sanity.io/docs/image-url
 * */
export const urlFor = (source: any) =>
  createImageUrlBuilder(sanityConfig).image(source);
