import { STORAGE_KEY } from './constants';

export function getLocalData(): string[] {
  const dataString = window.localStorage.getItem(STORAGE_KEY) || '[]';
  const parsedString = JSON.parse(dataString);

  return Array.isArray(parsedString) ? parsedString : [parsedString];
}

export function updateLocalData(sku?: string) {
  if (!sku) {
    return;
  }

  const currentData = getLocalData();

  if (currentData.includes(sku)) {
    return;
  }

  const skusToStore = [sku, ...currentData].slice(0, 19);

  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(skusToStore));
}
