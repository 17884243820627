import React from 'react';

export default function Compost() {
  return (
    <svg width="20" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.7 24.7h.2a50 50 0 0 0 0-21.1c.6 0 1.1-.7 1.1-1.3 0-.8-.5-1.4-1.2-1.4H1.3C.5 1 0 1.5 0 2.3c0 .6.5 1.2 1.1 1.3a50 50 0 0 0 0 21h.2c-.8 0-1.3.6-1.3 1.4 0 .8.5 1.3 1.3 1.3h17.5c.7 0 1.2-.5 1.2-1.3s-.5-1.3-1.3-1.3Z"
        fill="#A374FF"
      />
      <path
        d="M15.8 15.6c.2-.5.3-1 .3-1.6V9.4h-.2c-1.5 0-2.8.7-3.9 1.8-1 1-1.6 2.5-1.6 4v.4h-.8v-.4a5.8 5.8 0 0 0-1.7-4c-1-1-2.3-1.7-3.7-1.7H4V14a4.7 4.7 0 0 0 2 3.8c.7.6 1.6.9 2.6.9v1.9h3v-2a4.5 4.5 0 0 0 4.3-3Z"
        fill="#fff"
      />
    </svg>
  );
}
