import styled from 'styled-components';
import { BaseModalBackground } from 'styled-react-modal';

import theme from 'utils/theme';

import { IMoalBackground } from './types';

export const ModalBackground = styled(BaseModalBackground)<IMoalBackground>`
  transition: all ${theme.transitionSpeeds.slow}s ease-in-out;
  opacity: ${(props) => props.opacity};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${theme.zIndexes.modals};
  background-color: rgba(0, 0, 0, 0.31);
  will-change: opacity;
`;
