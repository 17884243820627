import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { ReactElement, ReactNode } from 'react';
import { ModalProvider } from 'styled-react-modal';

import Footer from 'components/common/footer';
import Header from 'components/common/header';
import HeaderAlert from 'components/common/header-alert';
import { ModalBackground } from 'components/common/modal-background';
import PressBanner from 'components/common/press-banner';
import { SecondaryAlerts } from 'components/common/secondary-alerts';
import { ValuesBanner } from 'components/common/values';
import { ISiteGlobals } from 'utils/types';
import { getCanonicalDomain, getCanonicalUrl } from 'utils/url';

import { LayoutProps } from '../types';

import { StickyGlobalHeader, HeaderAlertContainer } from './styles';

const CookieBanner = dynamic(() => import('components/common/cookie-banner'), {
  ssr: false,
});

export default function DefaultLayout({
  children,
  siteGlobals,
  staticHeader = false,
}: LayoutProps) {
  const { asPath } = useRouter();

  return (
    <>
      <Head>
        <title key="title">Sproutl</title>
        <link key="canonical" rel="canonical" href={getCanonicalUrl(asPath)} />
        <meta
          key="og:description"
          name="og:description"
          content="Growing for everyone. Beautiful plants for any garden, delivered to your door."
        />
        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta key="og:site_name" property="og:site_name" content="Sproutl" />
        <link
          rel="alternate"
          type="application/rss+xml"
          title="Sproutl Learning Hub RSS Feed"
          href={`${getCanonicalDomain()}/articles/rss.xml`}
        />
      </Head>

      {siteGlobals.header?.alerts?.length && (
        <HeaderAlertContainer>
          <HeaderAlert {...siteGlobals.header} />
        </HeaderAlertContainer>
      )}

      <StickyGlobalHeader staticHeader={staticHeader} className="site-header">
        <Header
          nav={siteGlobals.header?.menu || []}
          searchSettings={siteGlobals.search}
        />
      </StickyGlobalHeader>

      {!!siteGlobals.header?.alerts?.length && (
        <SecondaryAlerts
          {...{
            alerts: siteGlobals.header.secondaryAlerts,
            theme: siteGlobals.header.secondaryAlertsTheme,
            transparency:
              siteGlobals.header.secondaryAlertsBackgroundTransparency,
          }}
        />
      )}

      <ModalProvider backgroundComponent={ModalBackground}>
        {children}
        <ValuesBanner images={siteGlobals.valuesBanner?.images} />
        <PressBanner images={siteGlobals.pressBanner?.images} />
        <Footer
          linkColumns={siteGlobals.footer?.linkColumns}
          newsletterColumn={siteGlobals.footer?.newsletterColumn}
          rightColumn={siteGlobals.footer?.rightColumn}
        />
      </ModalProvider>

      <CookieBanner />
    </>
  );
}

export function getDefaultLayout(
  page: ReactElement,
  siteGlobals: ISiteGlobals,
): ReactNode {
  return (
    <DefaultLayout
      siteGlobals={siteGlobals}
      staticHeader={page.props.staticHeader}
    >
      {page}
    </DefaultLayout>
  );
}
