import { MultipleQueriesQuery } from '@algolia/client-search';
import * as Sentry from '@sentry/nextjs';

import getIndexes from 'framework/algolia/api/get-indexes';
import { productMetaAttributes } from 'framework/algolia/query-fragments';
import { NormalizedAttributes } from 'utils/commercetools/types';

import { getQueryFilters } from './utils';

const { anonUserToken, QUERY_INDEX } = getIndexes();

export const getRecommendedProducts = (
  attributes: NormalizedAttributes,
  bestOffer?: Sproutl.ProductOffer | null,
): MultipleQueriesQuery[] => {
  const hasBestOffer =
    bestOffer?.availableQuantity && bestOffer?.availableQuantity > 0;
  const queryFilters = getQueryFilters(attributes);

  if (!hasBestOffer || queryFilters.length === 0) {
    return [];
  }

  try {
    return [
      {
        indexName: QUERY_INDEX,
        params: {
          filters: queryFilters.join(' AND '),
          optionalFilters: `selectedPartner.slug:${
            bestOffer?.partner?.slug || ''
          }`,
          hitsPerPage: 8,
          attributesToRetrieve: productMetaAttributes,
          clickAnalytics: true,
          userToken: anonUserToken,
        },
      },
    ];
  } catch (error: any) {
    Sentry.captureException(error);
    return [];
  }
};
