import { TBestOffer } from 'types/product-commercetools';

function sortOffersByBestPrice(a: TBestOffer, b: TBestOffer) {
  const centAmountDifference = a.price.centAmount - b.price.centAmount;

  if (centAmountDifference === 0) {
    return b.availableQuantity - a.availableQuantity;
  }

  return centAmountDifference;
}

export default sortOffersByBestPrice;
