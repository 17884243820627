import styled from 'styled-components';

import Container from 'components/common/container';
import Logo from 'components/common/logo';
import { belowDesktop, desktop, mobile } from 'utils/media';
import theme from 'utils/theme';

import { Flow } from '../flow';

export const Wrapper = styled.footer`
  position: relative;
  overflow: hidden;
  background: ${theme.colors.offBlack};
  color: ${theme.colors.white};
  line-height: 1.5;
  font-size: var(--step--1);

  a {
    color: inherit;
  }

  h2,
  p {
    margin: 0;
  }
`;

export const StyledContainer = styled(Container)`
  overflow: hidden;
  padding-top: var(--space-xl-2xl);
  padding-bottom: var(--space-s);
  display: flex;
  flex-direction: column;

  a {
    color: ${theme.colors.coleusGreen};
  }

  > * + * {
    margin-top: var(--space-2xl);
  }

  ${desktop} {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--space-l);
    margin-bottom: calc(-1 * var(--space-2xl-3xl));
    padding-bottom: 0;
    position: relative;
    z-index: ${theme.zIndexes.low};

    > * {
      + * {
        margin-top: 0;
      }

      :nth-child(1) {
        flex-basis: 377px;
      }

      :nth-child(2) {
        flex-basis: 424px;
        flex-shrink: 3;
      }

      :nth-child(3) {
        flex-basis: 250px;
        flex-shrink: 2;
      }
    }
  }
`;

export const Sustainablilty = styled(Flow)`
  p {
    max-width: 30ch;
  }

  a {
    color: inherit;
    font-weight: ${theme.weights.bold};
    text-decoration: underline;
  }

  img {
    width: 100%;
    max-width: 240px;

    ${mobile} {
      max-width: 200px;
    }
  }
`;

export const StyledLogo = styled(Logo)`
  position: absolute;
  top: 0;
  left: 2.5%;
  right: 0;
  width: 95%;
  height: auto;
  color: ${theme.colors.black};
`;

export const ColTitle = styled.button`
  margin-bottom: var(--space-s-m);
  font-weight: ${theme.weights.bold};
  font-size: var(--step-0);
  font-family: ${theme.fonts.heading};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  svg {
    transition: transform ${theme.transitionSpeeds.fast}s;
  }
`;

export const Nav = styled.nav`
  ${desktop} {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    grid-gap: var(--space-xl) var(--space-s);
  }
`;

export const NavColLinks = styled.div``;

interface INavColProps {
  isOpen: boolean;
}

export const NavCol = styled.div<INavColProps>`
  ${belowDesktop} {
    ${NavColLinks} {
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
      padding-bottom: var(--space-s-m);
    }

    ${ColTitle} {
      margin: 0;
      padding: var(--space-s-m) 0;
    }

    ${ColTitle} svg {
      transform: rotate(${({ isOpen }) => (isOpen ? -180 : 0)}deg);
    }

    & {
      border-bottom: 1px solid ${theme.colors.darkGrey};
    }

    :first-child {
      border-top: 1px solid ${theme.colors.darkGrey};
    }
  }

  ${desktop} {
    ${ColTitle} svg {
      display: none;
    }
  }

  a {
    display: block;

    + * {
      margin-top: var(--space-xs-2xs);
    }
  }
`;

export const DarkWrapper = styled.div`
  background: ${theme.colors.black};
  padding: var(--space-xl) 0;

  ${Container} {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > * + * {
      margin-top: var(--space-s);
    }
  }

  ${desktop} {
    padding: var(--space-m) 0;

    ${Container} {
      flex-direction: row;
      justify-content: space-between;

      > * + * {
        margin-top: 0;
      }
    }
  }
`;

export const ContentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  font-size: var(--step--2);

  a:hover {
    text-decoration: underline;
  }
`;
