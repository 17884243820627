import React from 'react';

export default function PaymentMethods() {
  return (
    <svg
      width="237"
      height="31"
      viewBox="0 0 237 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.84 10.53h-1.93c-.6 0-1.04.17-1.31.74l-3.7 8.2h2.62s.45-1.1.54-1.36h3.2c.09.31.31 1.32.31 1.32h2.35l-2.08-8.9zm-3.06 5.74c.22-.52.99-2.5.99-2.5 0 .05.23-.52.32-.83l.18.79.58 2.59h-2.07v-.05zm-3.7.27c0 1.84-1.71 3.07-4.37 3.07-1.13 0-2.2-.22-2.8-.49l.36-2.01.32.13a5.1 5.1 0 0 0 2.34.48c.73 0 1.5-.26 1.5-.88 0-.4-.32-.66-1.32-1.1-.94-.43-2.2-1.13-2.2-2.4 0-1.76 1.75-2.94 4.23-2.94.95 0 1.76.17 2.26.39l-.36 1.93-.18-.18a4.96 4.96 0 0 0-1.9-.35c-.94.05-1.4.44-1.4.8 0 .39.55.7 1.4 1.09 1.45.66 2.12 1.4 2.12 2.46z"
        fill="#3362AB"
      />
      <path
        d="m3.08 10.61.05-.17H7c.54 0 .94.17 1.08.74l.85 3.95c-.85-2.1-2.84-3.81-5.86-4.52z"
        fill="#F9B50B"
      />
      <path
        d="m14.4 10.53-3.92 8.9H7.82l-2.26-7.46a9.76 9.76 0 0 1 3.47 3.69l.27.92 2.44-6.1h2.66v.05zm1.04-.05h2.48l-1.58 8.95h-2.48l1.57-8.95z"
        fill="#3362AB"
      />
      <path
        d="M68.76 7.9 61 7.91l.23 14.18 7.75-.02-.23-14.18z"
        fill="#FF5F00"
      />
      <path
        d="M61.52 15.04a8.97 8.97 0 0 1 3.27-7.1 8.87 8.87 0 0 0-5.52-1.92 8.82 8.82 0 0 0-8.75 9.06 9.1 9.1 0 0 0 9.05 9 8.66 8.66 0 0 0 5.45-1.95 9.2 9.2 0 0 1-3.5-7.09z"
        fill="#EB001B"
      />
      <path
        d="M79.48 14.92c.08 5-3.83 9.04-8.75 9.06a8.87 8.87 0 0 1-5.52-1.91 8.9 8.9 0 0 0 3.27-7.11 9.24 9.24 0 0 0-3.5-7.09 8.66 8.66 0 0 1 5.45-1.95 9.12 9.12 0 0 1 9.05 9z"
        fill="#F79E1B"
      />
      <path
        d="M214.38 17.48v5.04h-1.52V10.08h4.04a3.5 3.5 0 0 1 2.61 1.07 3.45 3.45 0 0 1 1.1 2.63c0 .5-.09 1-.28 1.45a3.43 3.43 0 0 1-.82 1.2c-.7.7-1.57 1.05-2.61 1.05h-2.51zm0-5.87v4.35h2.56a1.92 1.92 0 0 0 1.5-.65c.2-.2.35-.43.45-.7a2.21 2.21 0 0 0 0-1.64 2.13 2.13 0 0 0-.45-.7 2 2 0 0 0-.68-.5 1.9 1.9 0 0 0-.82-.16h-2.56zm9.73 2.12c1.13 0 2.01.32 2.66.95.65.63.98 1.5.98 2.6v5.24h-1.46v-1.18h-.06a2.88 2.88 0 0 1-2.52 1.46c-.89 0-1.64-.28-2.24-.83a2.65 2.65 0 0 1-.67-.94 2.78 2.78 0 0 1-.23-1.15c0-.88.32-1.58.95-2.1a3.9 3.9 0 0 1 2.54-.78c.9 0 1.65.17 2.23.52v-.37a1.9 1.9 0 0 0-.16-.78 1.82 1.82 0 0 0-.47-.63 2.1 2.1 0 0 0-1.46-.58 2.3 2.3 0 0 0-2.02 1.13l-1.34-.9a3.67 3.67 0 0 1 3.27-1.66zm-1.97 6.19a1.28 1.28 0 0 0 .5 1.04c.34.28.76.42 1.19.42a2.37 2.37 0 0 0 1.71-.75c.5-.5.76-1.08.76-1.75a3 3 0 0 0-1.99-.6c-.62 0-1.13.16-1.54.47-.42.32-.63.7-.63 1.17zM236.1 14l-5.08 12.27h-1.57l1.89-4.3-3.34-7.97h1.65l2.42 6.12h.03l2.35-6.12h1.65z"
        fill="#5F6368"
      />
      <path
        d="M208.24 16.4a8.84 8.84 0 0 0-.12-1.46h-6.42v2.76h3.68c-.08.44-.24.86-.47 1.23a3.22 3.22 0 0 1-.9.94v1.79h2.2a7.18 7.18 0 0 0 2.03-5.26z"
        fill="#4285F4"
      />
      <path
        d="M201.7 23.38c1.84 0 3.38-.63 4.51-1.72l-2.2-1.79c-.6.44-1.39.68-2.31.68a4.1 4.1 0 0 1-3.82-2.95h-2.26v1.84a7.03 7.03 0 0 0 2.5 2.88 6.56 6.56 0 0 0 3.58 1.06z"
        fill="#34A853"
      />
      <path
        d="M197.88 17.6a4.5 4.5 0 0 1 0-2.73v-1.84h-2.26a7.44 7.44 0 0 0-.72 3.2c0 1.12.24 2.22.72 3.21l2.26-1.84z"
        fill="#FBBC04"
      />
      <path
        d="M201.7 11.92a3.6 3.6 0 0 1 2.61 1.07l1.94-2.04a6.37 6.37 0 0 0-4.55-1.86 6.56 6.56 0 0 0-3.57 1.06 7.03 7.03 0 0 0-2.51 2.88l2.26 1.84a4.1 4.1 0 0 1 3.82-2.95z"
        fill="#EA4335"
      />
      <path
        d="M173.7 26.03c1.98 0 2.92-.78 3.73-3.15L181 12.53h-2.07l-2.39 8h-.04l-2.4-7.99h-2.12l3.44 9.85-.18.6a1.63 1.63 0 0 1-1.72 1.4 6.3 6.3 0 0 1-.6-.03V26c.26.04.52.05.78.05zm-6.89-5.18c-1.08 0-1.77-.54-1.77-1.36 0-.85.67-1.35 1.93-1.43l2.26-.14v.76a2.24 2.24 0 0 1-2.3 2.19 2.67 2.67 0 0 1-.12-.01v-.01zm-.54 1.6a3.34 3.34 0 0 0 3-1.72h.04v1.62h1.88v-6.73c0-1.96-1.51-3.21-3.84-3.21-2.16 0-3.75 1.27-3.81 3.03h1.83a1.79 1.79 0 0 1 1.92-1.38c1.25 0 1.94.6 1.94 1.7v.74l-2.53.16c-2.36.15-3.64 1.15-3.64 2.88 0 1.76 1.32 2.93 3.2 2.93v-.01zm-10.9-11.86h2.34c1.76 0 2.77.97 2.77 2.68s-1 2.7-2.78 2.7h-2.33v-5.38zm-2.04-1.77v13.53h2.04v-4.61h2.81a4.2 4.2 0 0 0 4.38-4.46 4.2 4.2 0 0 0-3.91-4.45 4.22 4.22 0 0 0-.4 0l-4.92-.01zm-6.74 2.52c-1.17-.07-2.17.69-2.72.69-.56 0-1.42-.65-2.34-.63a3.44 3.44 0 0 0-2.93 1.84c-1.26 2.24-.33 5.55.89 7.38.6.9 1.31 1.89 2.25 1.85.9-.03 1.24-.6 2.32-.6 1.07 0 1.4.6 2.33.59.98-.02 1.6-.9 2.19-1.81a8.05 8.05 0 0 0 .97-2.08 3.28 3.28 0 0 1-1.9-2.98 3.37 3.37 0 0 1 1.54-2.8 3.3 3.3 0 0 0-2.6-1.45zm-.73-1.2a2.6 2.6 0 0 1-2.12 1.04c-.05-.83.22-1.64.76-2.26a3.12 3.12 0 0 1 2.1-1.12 3.37 3.37 0 0 1-.74 2.35"
        fill="#000"
      />
      <path
        d="M93.94 4.36h27.12c1.07 0 1.94.87 1.94 1.94v18.4c0 1.07-.87 1.94-1.94 1.94H93.94A1.94 1.94 0 0 1 92 24.7V6.3c0-1.07.87-1.94 1.94-1.94z"
        fill="#26A6D1"
      />
      <path
        d="m97 12.1-3.06 6.78h3.66l.46-1.08h1.03l.46 1.08h4.03v-.82l.36.82h2.09l.36-.84v.84h8.39l1.02-1.05.95 1.05 4.31.01-3.07-3.38 3.07-3.4h-4.24l-1 1.03-.92-1.03h-9.12l-.79 1.75-.8-1.75h-3.66v.8l-.4-.8H97zm.7.97h1.8l2.02 4.6v-4.6h1.96l1.57 3.3 1.45-3.3h1.94v4.86h-1.18l-.01-3.8-1.73 3.8h-1.06l-1.74-3.8v3.8h-2.43l-.47-1.09h-2.5l-.45 1.1h-1.3l2.14-4.87zm11.83 0h4.81l1.48 1.6 1.52-1.6h1.47l-2.24 2.44 2.24 2.42h-1.54l-1.47-1.61-1.53 1.6h-4.74v-4.85zm-10.95.82-.83 1.94h1.65l-.82-1.94zm12.14.19v.88h2.62v1h-2.62v.96h2.94l1.37-1.42-1.3-1.42h-3.01z"
        fill="#fff"
      />
    </svg>
  );
}
