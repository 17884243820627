import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

import { Close, Help, LeftArrow, RightArrow } from 'components/common/icons';
import { PortableText } from 'framework/sanity/portable-text';
import { urlFor } from 'framework/sanity/url-for';
import { setMobileDialogOpen } from 'utils/ui';

import { MegaNavAdvert } from '../advert';
import { NavLink, NavScreen, NavSection } from '../types';

import {
  MobileContent,
  MobileInternalButton,
  MobileInternalNavigation,
  MobileNavScreen,
  MobileNavScreenRows,
  NavRowButton,
  NavRowLink,
  Wrapper,
  ScreenFooterLinks,
  ScreenView,
  MobileInternalButtonAnimation,
  MobileAdverts,
  MobileHomeNav,
  MobileHomeNavButton,
  MobileNavContent,
} from './styles';
import {
  MegaNavMobileProps,
  MobileNavRowProps,
  MobileNavScreenContentProps,
} from './types';

function MobileNavRow({
  item,
  onClose,
  onNavigateToScreen,
}: MobileNavRowProps) {
  const isButton =
    ('subItems' in item && item.subItems?.length) ||
    'links' in item ||
    ('content' in item && !!item.content);

  const children = (
    <>
      <span>
        {'icon' in item && item.icon ? (
          <>
            <img
              src={
                urlFor(item.icon).width(50).auto('format').fit('max').url() ||
                ''
              }
              alt=""
            />
            <span>{item.title}</span>
          </>
        ) : (
          item?.title
        )}
      </span>
      {isButton ? <RightArrow /> : null}
    </>
  );
  return isButton ? (
    <NavRowButton
      type="button"
      onClick={() => {
        onNavigateToScreen(item as NavScreen);
      }}
    >
      {children}
    </NavRowButton>
  ) : (
    <NavRowLink
      variant="secondary"
      href={(item as NavLink).path || '#'}
      onClick={onClose}
    >
      {children}
    </NavRowLink>
  );
}

function MobileNavScreenContent({
  item,
  adverts,
  onClose,
  onNavigate,
}: MobileNavScreenContentProps) {
  let rows;
  if (Array.isArray(item)) {
    rows = item;
  } else {
    rows =
      'links' in item ? item.links : 'subItems' in item ? item.subItems : null;
  }

  return (
    <ScreenView>
      {'content' in item && !!item.content?.length && (
        <MobileNavContent>
          <PortableText blocks={item.content} />
        </MobileNavContent>
      )}

      <MobileNavScreenRows>
        {rows?.map((subItem) => (
          <MobileNavRow
            item={subItem}
            key={subItem._key}
            onClose={onClose}
            onNavigateToScreen={onNavigate}
          />
        ))}
        {rows && rows?.length && 'path' in item && 'title' in item && (
          <MobileNavRow
            item={{
              _key: '',
              title: 'View all',
              path: item.path,
            }}
            onClose={onClose}
            onNavigateToScreen={onNavigate}
          />
        )}
      </MobileNavScreenRows>

      {!!adverts?.length && (
        <MobileAdverts>
          {adverts.map((advert) => (
            <MegaNavAdvert advert={advert} key={advert._key} />
          ))}
        </MobileAdverts>
      )}

      <ScreenFooterLinks>
        <NavRowLink variant="secondary" href="/contact-us">
          <span>
            <Help />
            Need some help?
          </span>
        </NavRowLink>
      </ScreenFooterLinks>
    </ScreenView>
  );
}

export default function MegaNavMobile({
  nav,
  isOpen,
  transitionDuration,
  onClose,
}: MegaNavMobileProps) {
  const [homeScreen, setHomeScreen] = useState<NavSection>(nav[0]);
  const [currentScreens, setCurrentScreens] = useState<NavScreen[]>([]);
  const [currentRenderedScreens, setCurrentRenderedScreens] = useState<
    NavScreen[]
  >([]);
  const router = useRouter();

  useEffect(() => {
    setMobileDialogOpen(true);

    return () => {
      setMobileDialogOpen(false);
    };
  }, []);

  const screensPush = (screen: NavScreen) => {
    const screenToPush =
      'subItems' in screen && screen.subItems && screen.subItems.length === 1
        ? {
            ...screen.subItems[0],
            content: 'content' in screen ? screen.content : null,
          }
        : screen;

    setCurrentScreens([...currentScreens, screenToPush]);
    setCurrentRenderedScreens([...currentScreens, screenToPush]);
  };

  const screensBack = () => {
    const screenWrapperEl = document.getElementById('mobileNavScreens');
    if (screenWrapperEl) {
      const screenNodes = screenWrapperEl.childNodes;
      const previousScreenEl = screenNodes.item(screenNodes.length - 2);
      if (previousScreenEl) {
        (previousScreenEl as HTMLDivElement)?.focus();
      }
    }

    const newScreens = [...currentScreens];
    newScreens.pop();
    setCurrentScreens(newScreens);

    setTimeout(() => {
      setCurrentRenderedScreens(newScreens);
    }, transitionDuration);
  };

  const isOnHomeScreen = !currentRenderedScreens.length;

  const handleHomeScreenLinkTap = (item: NavSection) => {
    if (!item.subItems && !!item.path) {
      router.push(item.path);
      onClose();
    }

    setHomeScreen(item);
  };

  return (
    <Wrapper transitionDuration={transitionDuration} isOpen={isOpen}>
      <MobileInternalNavigation>
        {isOnHomeScreen ? (
          <MobileHomeNav>
            {nav.map((item) => {
              return (
                <MobileHomeNavButton
                  key={item._key}
                  type="button"
                  onClick={() => handleHomeScreenLinkTap(item)}
                  active={homeScreen._key === item._key}
                >
                  {item.title}
                </MobileHomeNavButton>
              );
            })}
          </MobileHomeNav>
        ) : (
          <div>
            <MobileInternalButtonAnimation active={!isOnHomeScreen}>
              <MobileInternalButton type="button" onClick={screensBack}>
                <LeftArrow />
              </MobileInternalButton>
            </MobileInternalButtonAnimation>
          </div>
        )}
        <MobileInternalButton type="button" onClick={onClose}>
          <Close />
        </MobileInternalButton>
      </MobileInternalNavigation>
      <MobileContent id="mobileNavScreens">
        <MobileNavScreen
          tabIndex={0}
          isOpen={true}
          transitionDuration={transitionDuration}
        >
          <MobileNavScreenContent
            item={homeScreen}
            adverts={homeScreen.adverts}
            onClose={onClose}
            onNavigate={screensPush}
          />
        </MobileNavScreen>

        {currentRenderedScreens.map((screen) => {
          const adverts = screen.hideAdverts
            ? []
            : screen.adverts || homeScreen.adverts;
          return (
            <MobileNavScreen
              tabIndex={0}
              key={`subScreen_${screen._key}`}
              isOpen={currentScreens.includes(screen)}
              transitionDuration={transitionDuration}
            >
              <MobileNavScreenContent
                item={screen}
                adverts={adverts}
                onClose={onClose}
                onNavigate={screensPush}
              />
            </MobileNavScreen>
          );
        })}
      </MobileContent>
    </Wrapper>
  );
}
