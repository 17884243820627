import React from 'react';

export default function Envelope() {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15 4.6a.7.7 0 0 0-.7.7v11.16c0 .38.33.7.7.7h18.6a.7.7 0 0 0 .7-.7V5.3a.7.7 0 0 0-.7-.7H2.16Zm.99.93h16.64l-8.32 7.28-8.32-7.28Zm-.75.59 5.27 4.61-5.27 4.89v-9.5Zm18.14 0v9.5l-5.28-4.89 5.28-4.61ZM8.36 11.34l2.8 2.45c.17.15.43.15.6 0l2.8-2.45 5.27 4.89H3.08l5.28-4.89Z"
        fill="currentColor"
      />
    </svg>
  );
}
