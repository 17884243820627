import getSearchClient from '../get-search-client';

const { anonUserToken, searchClient } = getSearchClient();

function getIndexes() {
  const QUERY_INDEX = process.env.NEXT_PUBLIC_ALGOLIA_PRODUCT_INDEX || '';
  const productIndex = searchClient.initIndex(QUERY_INDEX);
  const productIndexByNewest = searchClient.initIndex(`${QUERY_INDEX}_newest`);
  const productIndexByPopularity = searchClient.initIndex(
    `${QUERY_INDEX}_popularity`,
  );
  const productIndexByPriceAsc = searchClient.initIndex(
    `${QUERY_INDEX}_price_asc`,
  );
  const productIndexByPriceDesc = searchClient.initIndex(
    `${QUERY_INDEX}_price_desc`,
  );
  const productRecommendationsIndex = searchClient.initIndex(
    `${QUERY_INDEX}_recommended`,
  );
  const querySuggestionIndex = searchClient.initIndex(
    `${QUERY_INDEX}_query_suggestions`,
  );

  return {
    anonUserToken,
    productIndex,
    productIndexByNewest,
    productIndexByPopularity,
    productIndexByPriceAsc,
    productIndexByPriceDesc,
    querySuggestionIndex,
    searchClient,
    QUERY_INDEX,
    productRecommendationsIndex,
  };
}

export default getIndexes;
