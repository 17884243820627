import React from 'react';

export default function WashingMachine() {
  return (
    <svg
      height="90"
      viewBox="0 0 376 486"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.4 123.9V49.7A49.4 49.4 0 0 0 326.1.4H49.5A49.3 49.3 0 0 0 .2 49.7v74.2h375.2ZM106.6 48a17.4 17.4 0 1 1 0 34.8 17.4 17.4 0 0 1 0-34.8Zm-48.1.6c9.5 0 17.4 7.8 17.4 17.4 0 9.5-7.8 17.4-17.4 17.4-9.5 0-17.4-7.8-17.4-17.4 0-9.6 7.9-17.4 17.4-17.4ZM.2 436.1a49.3 49.3 0 0 0 49.3 49.3h276.6a49.3 49.3 0 0 0 49.3-49.3V138.9H.2v297.2ZM187.8 179a116.4 116.4 0 1 1 .2 232.8 116.4 116.4 0 0 1-.2-232.8Zm-50.9 200.1A91.5 91.5 0 0 0 256 364.7a92.4 92.4 0 0 0 22.2-48.4c.7-4.5-3.2-7.6-7.4-6a99.8 99.8 0 0 1-18.9 6.3c-31.5 6.9-55.3 4.2-82.3-14.1-26.2-17.6-48.2-18.9-66.6-12.2-3.1 1.1-4.7 4.1-5.2 7.3a91 91 0 0 0 39.1 81.5Z"
        fill="#000"
      />
    </svg>
  );
}
