import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';

export const aboutPageQuery = groq`
  *[_type == "aboutPage" && slug.current == $slug] {
    ...,
    blocks[] {
      ${BlocksInnerFragment}
    }
  }
`;
