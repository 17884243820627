import axios from 'axios';

import { GetAddressResponse } from './types';

const GETADDRESS_API_KEY = process.env.NEXT_PUBLIC_GET_ADDRESS_API_KEY;
const GETADDRESS_ENDPOINT = process.env.NEXT_PUBLIC_GET_ADDRESS_ENDPOINT;

export const getAddress = async (postcode: string, options?: string) =>
  axios.get<GetAddressResponse>(
    `${GETADDRESS_ENDPOINT}/${encodeURIComponent(postcode)}?${
      options ? `${options}&` : ``
    }api-key=${GETADDRESS_API_KEY}`,
  );
