export const compareAttributeToDefinition = (
  attribute: any,
  definitionValue: any,
): Boolean => {
  if (Array.isArray(attribute)) {
    return attribute.includes(definitionValue);
  }

  if (typeof attribute === 'number') {
    return attribute === Number(definitionValue);
  }

  if (typeof attribute === 'boolean') {
    return attribute === (definitionValue === 'true');
  }

  return attribute === definitionValue;
};

export const formatAttribute = (attribute: any): string => {
  switch (typeof attribute) {
    case 'object':
      return Array.isArray(attribute)
        ? attribute.join(', ')
        : Object.values(attribute).join(', ');
    case 'boolean':
      return attribute ? 'Yes' : 'No';
    default:
      return attribute.toString();
  }
};
