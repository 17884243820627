import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk';
import {
  ClientBuilder,
  createAuthForClientCredentialsFlow,
  createHttpClient,
} from '@commercetools/sdk-client-v2';

const projectKey = process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY || '';

const authMiddlewareOptions = {
  host: 'https://auth.europe-west1.gcp.commercetools.com',
  projectKey,
  credentials: {
    clientId: process.env.COMMERCETOOLS_CLIENT_ID || '',
    clientSecret: process.env.COMMERCETOOLS_CLIENT_SECRET || '',
  },
  scopes: [
    `view_orders:${projectKey}`,
    `manage_orders:${projectKey}`,
    `manage_payments:${projectKey}`,
  ],
};

const httpMiddlewareOptions = {
  host: 'https://api.europe-west1.gcp.commercetools.com',
};

const ctClientBuilder = new ClientBuilder()
  .withProjectKey(projectKey)
  .withMiddleware(createAuthForClientCredentialsFlow(authMiddlewareOptions))
  .withMiddleware(createHttpClient(httpMiddlewareOptions))
  .withUserAgentMiddleware()
  .build();

const ctClientPrivileged = createApiBuilderFromCtpClient(
  ctClientBuilder,
).withProjectKey({
  projectKey,
});

export default ctClientPrivileged;
