import React, { useState } from 'react';

import { Close } from 'components/common/icons';
import theme from 'utils/theme';

import {
  HeadingText,
  HeadingWrapper,
  StyledModal,
  Wrapper,
  CloseButton,
} from './styles';
import { IModalNotification, IModalHeading } from './types';

export const ModalHeading = ({
  heading,
  closeModal,
  disableClose,
}: IModalHeading) => (
  <HeadingWrapper>
    <HeadingText>{heading}</HeadingText>
    {disableClose ? null : (
      <CloseButton type="button" onClick={closeModal}>
        <Close height={16} width={16} />
      </CloseButton>
    )}
  </HeadingWrapper>
);

const ModalNotification = ({
  heading,
  isOpen,
  children,
  isBespokeModal,
  disableClose = false,
  closeModal = () => {},
}: IModalNotification) => {
  const [opacity, setOpacity] = useState(0);

  const afterOpen = () => {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  };

  const beforeClose = () =>
    new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, theme.transitionSpeeds.slow * 1000);
    });

  const afterClose = () => {
    setTimeout(() => {
      setOpacity(0);
    }, 100);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      afterOpen={afterOpen}
      beforeClose={beforeClose}
      afterClose={afterClose}
      onEscapeKeydown={disableClose ? () => {} : closeModal}
      onBackgroundClick={disableClose ? () => {} : closeModal}
      opacity={opacity}
      backgroundProps={{ opacity }}
    >
      {isBespokeModal ? (
        <div className="modal-wrapper modal-custom-wrapper">{children}</div>
      ) : (
        <Wrapper className="modal-wrapper">
          <ModalHeading
            heading={heading}
            closeModal={closeModal}
            disableClose={disableClose}
          />
          <div>{children}</div>
        </Wrapper>
      )}
    </StyledModal>
  );
};

export default ModalNotification;
