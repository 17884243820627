export const ProductsSkusQuery = `
  "products": products[]->{
    sku
  }
`;

export const ProductsMetaQuery = `
  "products": products[]->{
    sku,
  }
`;
