import React from 'react';

export default function Aftercare() {
  return (
    <svg
      viewBox="0 0 35 27"
      width="35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="currentColor">
        <path d="M15.22 15.378v-2.552a6.213 6.213 0 0 1-3.6-1.18 6.384 6.384 0 0 1-2.283-3.07 6.44 6.44 0 0 1-.366-2.149V.071h.4a7.721 7.721 0 0 1 5.209 2.434 7.983 7.983 0 0 1 2.154 5.404v.662h1.109v-.72a7.98 7.98 0 0 1 2.252-5.44A7.714 7.714 0 0 1 25.423.07h.2v6.35c0 .731-.122 1.458-.36 2.148a6.361 6.361 0 0 1-2.275 3.075 6.19 6.19 0 0 1-3.6 1.182v2.569M24.664 26.45l5.512-9.573c.878-1.797 1.314-3.097 1.356-4.349l-.271-4.817a1.715 1.715 0 0 0-.483-1.163c-.302-.31-.71-.49-1.139-.503a1.727 1.727 0 0 0-1.233.459c-.337.31-.54.742-.569 1.204l.033 4.468a5.437 5.437 0 0 1-.521 2.39l-2.219 3.908c-.074.132-.135.24-.258.285-.122.045-.23.08-.366 0a.573.573 0 0 1-.204-.771l1.386-2.443c.217-.39.273-.85.157-1.282a1.694 1.694 0 0 0-.775-1.023 1.644 1.644 0 0 0-1.26-.172c-.424.114-.789.394-1.012.78l-1.94 3.419a5.232 5.232 0 0 0-.549 1.86l-.301 7.26M10.024 26.45l-5.511-9.573c-.878-1.797-1.314-3.097-1.357-4.349l.272-4.817c.009-.437.182-.853.485-1.164.302-.31.71-.49 1.14-.502.454-.017.896.148 1.233.459.336.31.54.742.569 1.204l-.033 4.468c-.01.827.168 1.645.521 2.39l2.218 3.908c.075.132.136.24.258.285.123.045.231.08.366 0a.573.573 0 0 0 .204-.771l-1.386-2.443a1.724 1.724 0 0 1-.156-1.282c.116-.432.394-.8.775-1.023.381-.224.833-.286 1.259-.172.425.114.79.394 1.013.78l1.94 3.419c.297.58.483 1.21.549 1.86l.296 7.26" />
      </g>
    </svg>
  );
}
