import { throttle } from 'lodash';
import Link from 'next/link';
import React, { useState, useEffect, useCallback } from 'react';
import useDelayedRender from 'use-delayed-render';

import { Hamburger } from 'components/common/icons';
import Logo from 'components/common/logo';
import SiteLink from 'components/common/site-link';
import { GlobalEvents, trigger } from 'utils/global-events';
import theme from 'utils/theme';
import { ISearch } from 'utils/types';

import MegaNavDesktop from './components/mega-nav/desktop';
import MegaNavMobile from './components/mega-nav/mobile';
import { NavRoot, NavSection } from './components/mega-nav/types';
import {
  LogoLink,
  StyledContainer,
  Background,
  HamburgerWrapper,
  HeaderNavLinks,
  SearchWrapper,
  RelativeWrapper,
  GlobalHeaderStyle,
  HeaderNavButton,
} from './styles';

interface HeaderProps {
  nav: NavRoot;
  searchSettings: ISearch;
}

export default function Header({ nav }: HeaderProps) {
  const [navState, setNavState] = useState<NavSection>(nav[0]);
  const [isVisible, setIsVisible] = useState(true);
  const [isOpenOnMobile, setIsOpenOnMobile] = useState(false);

  /**
   * Delay the unmount of nav items, to allow a CSS transition exit. It's transition-group but tiny
   */
  const transitionDuration = theme.transitionSpeeds.fast * 1000;
  const { mounted: isMobileNavMounted, rendered: isMobileNavRendered } =
    useDelayedRender(isOpenOnMobile, {
      exitDelay: transitionDuration,
    });

  useEffect(() => {
    if (!navState) {
      (document.activeElement as HTMLElement)?.blur();
    }
  }, [navState, isMobileNavMounted]);

  const onHover = useCallback(
    (
      section: NavSection,
      event: React.PointerEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => {
      const { currentTarget } = event;

      closeSearch();

      setTimeout(() => {
        if (currentTarget.matches(':hover')) {
          setNavState(section);
          setIsVisible(true);
        }
      }, 150);
    },
    [],
  );

  const setNavVisibility = useCallback(() => {
    setIsVisible(window.scrollY < 100);
  }, []);

  useEffect(() => {
    const debounced = throttle(setNavVisibility, 100);
    window.addEventListener('scroll', debounced);
    debounced();

    return () => window.removeEventListener('scroll', debounced);
  }, [setNavVisibility]);

  const closeSearch = () => {
    trigger(GlobalEvents.closeSearch);
  };

  return (
    <>
      <GlobalHeaderStyle />
      <RelativeWrapper>
        <Background data-testid="header">
          <StyledContainer noGutter>
            <Link href="/" passHref>
              <LogoLink onClick={closeSearch}>
                <Logo />
              </LogoLink>
            </Link>
            <HeaderNavLinks>
              {nav.map((section, index) => {
                const onTouchEnd = (event: React.TouchEvent) => {
                  closeSearch();
                  if (navState?._key !== section?._key) {
                    setNavState(section);
                    event.preventDefault();
                  }
                };

                return (
                  <li key={index}>
                    {section.path ? (
                      <SiteLink
                        variant="secondary"
                        href={section.path}
                        onPointerOver={(event) => onHover(section, event)}
                        onTouchEnd={onTouchEnd}
                        active={navState?._key === section?._key}
                        underline={navState?._key === section?._key}
                      >
                        {section?.title}
                      </SiteLink>
                    ) : (
                      <HeaderNavButton
                        type="button"
                        onPointerOver={(event) => onHover(section, event)}
                        onTouchEnd={onTouchEnd}
                        onClick={() => {
                          setNavState(section);
                          setIsVisible(true);
                        }}
                        active={navState?._key === section?._key}
                      >
                        {section?.title}
                      </HeaderNavButton>
                    )}
                  </li>
                );
              })}
            </HeaderNavLinks>
            <SearchWrapper></SearchWrapper>
            <HamburgerWrapper
              onClick={() => {
                setIsOpenOnMobile(true);
              }}
              aria-label="Menu"
            >
              <Hamburger />
            </HamburgerWrapper>
          </StyledContainer>
        </Background>

        {isMobileNavMounted && (
          <MegaNavMobile
            nav={nav}
            isOpen={isMobileNavRendered}
            transitionDuration={transitionDuration}
            onClose={() => setIsOpenOnMobile(false)}
          />
        )}

        <MegaNavDesktop
          section={navState}
          isVisible={isVisible}
          transitionDuration={transitionDuration}
          isOpen
          onClose={() => {
            setIsOpenOnMobile(false);
          }}
        />
      </RelativeWrapper>
    </>
  );
}
