export const HeroTwoQuery = `
  _type == 'heroTwo' => {
    wysiwyg,
    "eyebrow": eyebrow {
      ...,
      "icon": icon->{
        ...
      }
    },
    "primaryLogo": primaryLogo {
      ...,
      "asset": asset->,
    },
    "secondaryLogo": secondaryLogo {
      ...,
      "asset": asset->,
    },
    "heroTwoMedia": media[]{
      ...,
      "poster": poster[]{
        ...,
        "asset": asset->{
          ...,
          metadata {
            dimensions
          }
        }
      },
      "layers": layers[]{
        ...,
        "asset": asset->{
          ...,
          metadata {
            dimensions
          }
        }
      }
    }
  }
`;
