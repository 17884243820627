import { FC } from 'react';

import { PortableText } from 'framework/sanity/portable-text';
import { trackHeaderAlert } from 'utils/gtm/events';
import { prepareTheme } from 'utils/theme';

import Container from '../container';

import * as St from './styles';
import { ISecondaryAlertsProps } from './types';

export const SecondaryAlerts: FC<ISecondaryAlertsProps> = ({
  alerts = [],
  theme = 'purpleChalk:black',
  transparency = 100,
}) => {
  if (!alerts.length) return null;

  return (
    <St.Wrapper {...prepareTheme(theme, transparency)}>
      <Container>
        {alerts.map((alertWrapper) => {
          const blocks = alertWrapper?.alertName?.titleBlocks || [];
          if (!blocks.length) return null;

          const alert = alertWrapper.alertName;

          return (
            <St.StyledLink
              href={alert ? alert.url : ''}
              target={alert ? '_blank' : undefined}
              key={alertWrapper._key}
              onClick={() =>
                trackHeaderAlert(alert?.trackingId || alert?.url || '')
              }
            >
              <PortableText blocks={blocks} />
            </St.StyledLink>
          );
        })}
      </Container>
    </St.Wrapper>
  );
};
