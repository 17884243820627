import {
  ADD_RECENT_PRODUCT_TO_STATE,
  PRODUCTS_TO_SHOW_BY_INDEX,
  SET_INITIAL_STATE,
} from './constants';
import { TAction, TState } from './types';

const initialState: TState = {
  skus: [],
  products: [],
};

function reducer(state: TState, action: TAction) {
  switch (action.type) {
    case ADD_RECENT_PRODUCT_TO_STATE:
      if (!action.product || state.skus.includes(action.product.sproutlSku)) {
        return state;
      }

      return {
        products: [action.product, ...state.products].slice(
          0,
          PRODUCTS_TO_SHOW_BY_INDEX,
        ),
        skus: [action.product.sproutlSku, ...state.skus].slice(
          0,
          PRODUCTS_TO_SHOW_BY_INDEX,
        ),
      };
    case SET_INITIAL_STATE:
      return {
        skus: action.skus || [],
        products: action.products || [],
      };
    default:
      return state;
  }
}

export { initialState, reducer };
