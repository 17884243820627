import { ImageLoaderProps } from 'next/image';

import { urlFor } from 'framework/sanity/url-for';

export default function sanityLoader(src: any, loaderAspectRatio: number) {
  return function ({ width, quality = 80 }: ImageLoaderProps) {
    return (
      urlFor(src)
        .width(width)
        .height(Math.round(width * loaderAspectRatio))
        .auto('format')
        .fit('max')
        .quality(quality)
        .url() || ''
    );
  };
}
