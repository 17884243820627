import { useRouter } from 'next/router';
import { ChangeEvent, FC, FormEvent, useContext, useState } from 'react';

import { ReferralPopup } from 'components/checkout/success/components/referral-popup';
import { generateReferralCode } from 'components/checkout/success/components/referral-popup/helpers';
import { ReferralState } from 'components/checkout/success/components/referral-popup/types';
import Button from 'components/common/button';
import { FormField } from 'components/common/forms/NEW/field';
import { FieldError } from 'components/common/forms/NEW/field-error-message';
import Input from 'components/common/forms/NEW/input';
import Label from 'components/common/forms/NEW/label';
import { Settings } from 'contexts';
import { trackMGM } from 'utils/gtm/events';

import * as St from './styles';
import { IReferralSignupProps } from './types';

export const ReferralSignup: FC<IReferralSignupProps> = ({ cta, error }) => {
  const router = useRouter();
  const { featureFlags } = useContext(Settings);

  // Base64 decode the ?e (for email) param, and set it as the initial state
  const initialEmail = router.query.e?.toString() || '';
  const [email, setEmail] = useState(initialEmail ? atob(initialEmail) : '');

  const [isOpen, setIsOpen] = useState(false);
  const [referralState, setReferralState] = useState(ReferralState.idle);
  const [referralCode, setReferralCode] = useState('');

  if (!featureFlags?.memberGetMember) return null;

  const onFormSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const code = await generateReferralCode({
      email,
      setShareState: setReferralState,
    });
    setReferralCode(code);

    if (code) {
      trackMGM('Popup opened - landing page');
      setIsOpen(true);
      setEmail('');
    }
  };

  return (
    <>
      <St.Wrapper size="xs">
        <St.Form as="form" wrap="wrap" method="POST" onSubmit={onFormSubmit}>
          <FormField floatingLabel>
            <Input
              name="email"
              id="referral-email"
              type="email"
              floatingLabel
              bg="lightestGrey"
              required
              value={email}
              onInput={(event: ChangeEvent<HTMLInputElement>) =>
                setEmail(event.target.value)
              }
            />
            <Label htmlFor="referral-email" required>
              Your email
            </Label>
          </FormField>

          <Button
            loading={referralState === ReferralState.generating}
            loadingText="Generating code"
            type="submit"
          >
            {cta}
          </Button>
        </St.Form>

        {referralState === ReferralState.error && (
          <FieldError htmlFor="referral-email">{error}</FieldError>
        )}
      </St.Wrapper>

      {referralCode && (
        <ReferralPopup
          isReferralModalOpen={isOpen}
          setIsReferralModalOpen={setIsOpen}
          email={email}
          initialState={ReferralState.share}
          initialReferralCode={referralCode}
        />
      )}
    </>
  );
};
