import { IAttributeDefinitionWithValue } from 'utils/types';

import { formatAttribute } from '../product-attributes/utils';

interface IFormatAttributeLabelProps {
  attribute: IAttributeDefinitionWithValue;
  directValue?: any;
  hideTitle?: boolean;
  showShortTitle?: boolean;
  space?: string;
}

export const formatAttributeLabel = ({
  attribute: {
    title,
    shortTitle,
    prefix = '',
    suffix = '',
    value: attributeValue,
  },
  directValue,
  hideTitle = false,
  showShortTitle = false,
  space = ' ',
}: IFormatAttributeLabelProps): string => {
  let label = '';
  const value = formatAttribute(directValue || attributeValue || '');

  if (!hideTitle) {
    label += `${showShortTitle ? shortTitle || title : title}: `;
  }

  label += `${prefix}${value}${space}${suffix}`;

  return label.trim();
};
