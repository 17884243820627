import styled from 'styled-components';

import theme from 'utils/theme';

export const Label = styled.label`
  color: ${theme.colors.darkGrey};
  font-size: var(--step-0);
  display: block;
`;

export const Asterisk = styled.abbr`
  font-size: var(--step--1);
  opacity: 0.5;
  vertical-align: top;
  cursor: help;
  margin-left: 2px;
  font-weight: bold;
  &[title] {
    text-decoration: none;
  }
`;
