import React, { Ref } from 'react';

import * as St from './styles';
import { IInputProps } from './types';

const InputInnerEl = (
  {
    variant = 'primary',
    bg = 'white',
    hasError,
    blurPlaceholder,
    floatingLabel,
    trimOnBlur = true,
    ...props
  }: IInputProps,
  ref: Ref<HTMLInputElement | undefined>,
) => {
  const inputElComponent = (
    <St.Input
      {...props}
      onBlur={(event) => {
        if (trimOnBlur) {
          event.currentTarget.value = event.currentTarget.value.trim();
        }

        props?.onBlur && props.onBlur(event);
        return event;
      }}
      variant={variant}
      bg={bg}
      ref={ref as any}
      hasError={hasError}
      blurPlaceholder={!!blurPlaceholder}
      floatingLabel={floatingLabel}
      // Force :placeholder-shown to work
      placeholder={props.placeholder || (floatingLabel ? ' ' : undefined)}
    />
  );

  return (
    <>
      {blurPlaceholder ? (
        <St.InputWrapper>
          {inputElComponent}
          {blurPlaceholder ? (
            <St.BlurPlaceholder>{blurPlaceholder}</St.BlurPlaceholder>
          ) : null}
        </St.InputWrapper>
      ) : (
        inputElComponent
      )}
    </>
  );
};

export const Input = React.forwardRef<
  HTMLInputElement | undefined,
  IInputProps
>(InputInnerEl);

export default Input;
