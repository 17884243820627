import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { each } from 'lodash';

import { normalizeAttributesRaw } from 'utils/helpers';

import {
  DeliveryRange,
  GetDeliveryRatesForCartProps,
  ICustomerAddress,
  IProductWithQuantity,
  IShippingRatesResponse,
  RatesItems,
  RatesRequest,
} from './types';

const deliveryRangeRegex = /(\d+)\D*(\d+)/;

export const ESTIMATED_ADDRESS: ICustomerAddress = {
  address1: '1 Long Ln',
  city: 'London',
  postal_code: 'E1 4PG',
  country: 'GB',
};

async function getDeliveryRates(
  requestData: RatesRequest,
): Promise<Sproutl.IShipment> {
  try {
    let shippingRates: Sproutl.IShipment = {};

    const ratesUrl =
      typeof window === 'undefined'
        ? `${process.env.NEXT_PUBLIC_RATES_ENDPOINT}/rates`
        : '/api/rates/rates';

    const ratesRes: IShippingRatesResponse = await fetch(ratesUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    }).then((response) => response.json());

    const { rates } = ratesRes;

    //* Some info for debugging empty rates responses from the service
    if (!rates) {
      console.log(`Rates URL: ${ratesUrl}`, ratesRes);

      return shippingRates;
    }

    rates.forEach(({ deliveryOptions, partner }) => {
      shippingRates[partner] = {
        options: deliveryOptions,
      };
    });

    return shippingRates;
  } catch (error: any) {
    console.log(error);
    Sentry.captureException(error, { level: Severity.Fatal });
    throw new Error('Error in getting shipping rates');
  }
}

/**
 *
 * @param {GetDeliveryRatesForCartProps} props
 * @param {DeliveryAddressFields} props.shippingAddress - Used to override the cart shipping address
 * in cases where we want to get the rates before saving the address to the cart
 * @returns {Promise<Sproutl.IShipment>}
 */
export async function getDeliveryRatesForCart({
  cart,
  estimate = false,
  shippingAddressOverride,
}: GetDeliveryRatesForCartProps): Promise<Sproutl.IShipment> {
  try {
    const { shippingAddress, lineItems } = cart;

    const finalShippingAddress = shippingAddressOverride || shippingAddress;

    let destination: ICustomerAddress = {
      address1: finalShippingAddress?.streetName || '',
      city: finalShippingAddress?.city || '',
      postal_code: finalShippingAddress?.postalCode || '',
      country: finalShippingAddress?.country,
    };

    if (estimate === true) {
      destination = ESTIMATED_ADDRESS;
    }

    const shippingRates = await getDeliveryRates({
      options: {
        scope: 'sku',
      },
      rate: {
        destination,
        currency: 'GBP',
        items: lineItems.map((lineItem) => {
          const attributes = normalizeAttributesRaw(lineItem.variant);
          return {
            sku: lineItem.variant?.sku || null,
            quantity: lineItem.quantity,
            partner: lineItem.distributionChannel?.key || null,
            price: lineItem.price.value.centAmount,
            category_slug: attributes?.category_slug,
            indoor_outdoor: attributes?.indoor_outdoor || null,
            is_freight: attributes?.is_freight || null,
            small_item: attributes?.small_item || null,
            pallet_footprint: attributes?.pallet_footprint || null,
            box_number: attributes?.box_primary_num || null,
            length: attributes?.length || null,
            width: attributes?.width || null,
            height: attributes?.height || null,
            weight: attributes?.weight || null,
            weight_approx_product: attributes?.weight_approx_product || null,
            weight_approx_category: attributes?.weight_approx_category || null,
            volume: attributes?.volume || null,
            shipping_label_count: attributes?.shipping_label_count || null,
            packaging_length: attributes?.packaging_length || null,
            packaging_width: attributes?.packaging_width || null,
            packaging_height: attributes?.packaging_height || null,
            packaging_weight: attributes?.packaging_weight || null,
            packaging_small_item: attributes?.packaging_small_item || null,
            box_number_primary: attributes?.box_primary_num || null,
            box_number_secondary: attributes?.box_secondary_num || null,
            needs_pallet: attributes?.needs_pallet || null,
          };
        }),
      },
    });

    return shippingRates;
  } catch (error: any) {
    console.log(error);
    Sentry.captureException(error, { level: Severity.Fatal });
    throw new Error('Error in getting shipping rates');
  }
}

const estimatesCache = new Map();

export async function getEstimatedDeliveryForSKU(
  product: RatesItems,
): Promise<Sproutl.IShipmentOption> {
  const { sku, partner } = product;

  if (estimatesCache.has(sku)) {
    return estimatesCache.get(sku);
  }

  if (!partner) {
    throw new Error('Partner must be defined');
  }

  const rates = await getDeliveryRates({
    options: {
      scope: 'sku',
    },
    rate: {
      destination: ESTIMATED_ADDRESS,
      currency: 'GBP',
      items: [product],
    },
  });

  const estimatedDelivery = rates[partner].options[0];

  estimatesCache.set(product.sku, estimatedDelivery);

  return estimatedDelivery;
}

export async function getEstimatedDeliveryForSkus(
  products: IProductWithQuantity[],
): Promise<Sproutl.IShipment> {
  return await getDeliveryRates({
    options: {
      scope: 'sku',
    },
    rate: {
      destination: ESTIMATED_ADDRESS,
      currency: 'GBP',
      items: products
        .map(({ product, quantity }): RatesItems | null => {
          if (!product.selectedPartner) return null;

          return {
            sku: product.sproutlSku,
            quantity,
            partner: product.selectedPartner.slug,
            price: product.selectedPartner.centAmount,
            category_slug: product.categorySlug,
            indoor_outdoor: product.indoorOutdoor || null,
            is_freight: product.isFreight || null,
            small_item: product.packagingSmallItem || null,
            pallet_footprint: null,
            box_number: product.boxNumberPrimary || null,
            length: product?.length || null,
            width: product?.width || null,
            height: product?.height || null,
            weight: product?.weight || null,
            weight_approx_product: null,
            weight_approx_category: null,
            volume: product?.volume || null,
            shipping_label_count: null,
            packaging_length: null,
            packaging_width: null,
            packaging_height: null,
            packaging_weight: null,
            packaging_small_item: product.packagingSmallItem || null,
            box_number_primary: product.boxNumberPrimary || null,
            box_number_secondary: product.boxNumberSecondary || null,
            needs_pallet: product.needsPallet || null,
          };
        })
        .filter((rateItem): rateItem is RatesItems => !!rateItem),
    },
  });
}

/**
 * Splits the service desciption string into lower and upper delivery range
 * @param serviceDescription Example - 3-5 working days
 * @returns {DeliveryRange}
 */
function getDeliveryRangeFromServiceDescription(
  serviceDescription: string,
): DeliveryRange {
  let deliveryLower;
  let deliveryUpper;

  const deliveryRange = deliveryRangeRegex.exec(serviceDescription);

  if (deliveryRange) {
    [, deliveryLower, deliveryUpper] = deliveryRange;
  }

  return {
    deliveryLower: Number(deliveryLower),
    deliveryUpper: Number(deliveryUpper),
  };
}

/**
 * For use by Stripe Payment Request
 * Gets the Sproutl shipping rates and groups them into a single shipping option
 * for Express Checkout
 * @param rates
 * @returns
 */
export function getExpressCheckoutDeliveryRate(rates: Sproutl.IShipment) {
  let serviceDescription = '';
  let finalDeliveryLower = 0;
  let finalDeliveryUpper = 0;
  let amount = 0;

  each(rates, (rate) => {
    const option = rate.options?.[0];

    if (!option) return;

    // Get the delivery range
    const { deliveryLower, deliveryUpper } =
      getDeliveryRangeFromServiceDescription(option.service_description);

    // Compare the delivery range against the previous one, if either value is
    // higher then set that as the new delivery range and delivery detail
    if (
      deliveryLower &&
      deliveryUpper &&
      (deliveryLower > finalDeliveryLower || deliveryUpper > finalDeliveryUpper)
    ) {
      finalDeliveryLower = deliveryLower;
      finalDeliveryUpper = deliveryUpper;

      serviceDescription = option.service_description;
    }

    amount += option.price;
  });

  return [
    {
      id: 'sproutl_standard',
      label: 'Standard Delivery',
      detail: serviceDescription,
      amount,
    },
  ];
}
