import styled, { css } from 'styled-components';

import { ErrorMessage } from 'components/common/forms/NEW/field-error-message/styles';
import { Input } from 'components/common/forms/NEW/input/styles';
import { Label } from 'components/common/forms/NEW/label/styles';
import { Textarea } from 'components/common/forms/NEW/textarea/styles';
import theme from 'utils/theme';

import { IFieldProps } from './types';

export const FieldStyles = styled.div<IFieldProps>`
  ${Label} + *,
  * + ${ErrorMessage} {
    margin-top: var(--space-4xs);
  }

  ${(props) =>
    props.floatingLabel &&
    css`
      position: relative;

      ${Label} {
        position: absolute;
        margin-top: -1px;
        top: var(--space-s);
        left: var(--space-l);
        transition: transform ${theme.transitionSpeeds.fast}s ease-out;
        transform-origin: left top;
        cursor: text;
      }

      ${Input},
      ${Textarea} {
        /* Floating label active state */
        &:focus,
        &:not(:placeholder-shown),
        &:-webkit-autofill {
          + ${Label} {
            transform: scale(0.75) translateY(calc(-1 * var(--space-xs)));
          }
        }

        &::placeholder {
          transition: opacity ${theme.transitionSpeeds.fast}s ease-out;
          opacity: 0;
        }

        &:focus::placeholder {
          opacity: 1;
        }
      }
    `}
`;
