import React, { useState, useEffect } from 'react';

import { PortableText } from 'framework/sanity/portable-text';
import { trackHeaderAlert } from 'utils/gtm/events';
import { prepareTheme } from 'utils/theme';

import { BannerTopFadeOut, HeaderAlertLink, Wrapper } from './styles';
import { IHeaderAlertProps } from './types';

function HeaderAlert({
  alerts,
  alertTheme = 'coleusGreen:black',
  alertBackgroundTransparency = 100,
}: IHeaderAlertProps) {
  const [alertIndex, setAlertIndex] = useState(0);

  useEffect(() => {
    if (!alerts || alerts.length == 1) {
      return;
    }

    const interval = setInterval(() => {
      setAlertIndex((alertIndex) => {
        let nextIndex = alertIndex + 1;

        alertIndex = nextIndex >= alerts.length ? 0 : nextIndex;
        return alertIndex;
      });
    }, 6000);

    return () => clearInterval(interval);
  }, [alerts, setAlertIndex]);

  if (!alerts?.length) {
    return null;
  }

  return (
    <Wrapper {...prepareTheme(alertTheme, alertBackgroundTransparency)}>
      <BannerTopFadeOut>
        {alerts.map((alert, index) => {
          const blocks = alert?.alertName?.titleBlocks || [];
          if (!blocks.length) return null;

          return (
            <HeaderAlertLink
              as={alert.alertName?.url ? 'a' : 'span'}
              href={alert.alertName?.url ? alert.alertName.url : ''}
              target={alert.alertName ? 'blank' : ''}
              key={alert._key}
              active={index === alertIndex}
              onClick={() =>
                trackHeaderAlert(
                  alert.alertName?.trackingId || alert.alertName?.url || '',
                )
              }
            >
              <PortableText blocks={blocks} />
            </HeaderAlertLink>
          );
        })}
      </BannerTopFadeOut>
    </Wrapper>
  );
}

export default HeaderAlert;
