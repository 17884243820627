export const CollectionGroupFragment = `
  _key,
  title,
  description,
  id,
  type,
  multiplier,
  maxPerCarousel,
  maxPerProduct,
  minPerPartner,
  productsPerCarousel,
  hasSizeGuide,
  sizeGuideTitle,
  attributes
`;

export const CollectionPreviewFragment = `
  _id,
  title,
  slug,
  preview
`;
