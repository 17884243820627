import { MultipleQueriesQuery } from '@algolia/client-search';
import * as Sentry from '@sentry/nextjs';

import { ProductDataInterface } from 'components/layouts/product/types';
import { formatAttributeLabel } from 'components/product/variant-switcher/helpers';
import { getIndexes } from 'framework/algolia/api';
import {
  productHasPriceAndStock,
  productMetaAttributes,
} from 'framework/algolia/query-fragments';

const { anonUserToken, QUERY_INDEX } = getIndexes();

interface IGetSimilarProductsProps {
  maximumProductCount?: number;
  productData: ProductDataInterface;
}

export const getSimilarProducts = ({
  maximumProductCount = 10,
  productData: {
    variableAttributeDefinitions,
    projection: {
      productWithBestOffer: { sku },
      attributesNormalized: attributes = {},
    },
    name,
  },
}: IGetSimilarProductsProps): MultipleQueriesQuery[] => {
  const category: string = attributes.category_slug || '';
  const variationGroupCode = attributes.variation_group;
  if (!category) return [];

  try {
    const query = [
      name,
      ...variableAttributeDefinitions.map((attribute) =>
        formatAttributeLabel({ attribute, hideTitle: true }),
      ),
    ];

    const filters = [productHasPriceAndStock, `(NOT objectID:${sku})`];
    let optionalFilters: string[] = [];

    // * Omit the variationGroupCode
    if (variationGroupCode) {
      filters.push(`(NOT variationGroupCode:"${variationGroupCode}")`);
    }

    // * Add Categories as optional filters, deeper nesting first to gradually
    // * expand the Search and return the required number of Products.
    // * Excludes the top level Category to keep matches as relevant as possible.
    const categoryLevels = category.split('/');

    filters.push(`categories:${categoryLevels[0]}`);

    if (categoryLevels.length > 1) {
      categoryLevels.forEach((_, index) => {
        if (index < categoryLevels.length - 1) {
          optionalFilters.push(
            `categories:${categoryLevels
              .slice(0, categoryLevels.length - index)
              .join('/')}`,
          );
        }
      });
    }

    const queryString = query.filter(Boolean).join(' ');

    return [
      {
        indexName: QUERY_INDEX,
        params: {
          query: queryString,
          filters: filters.join(' AND '),
          optionalFilters,
          hitsPerPage: maximumProductCount,
          attributesToRetrieve: productMetaAttributes,
          distinct: 1,
          clickAnalytics: true,
          userToken: anonUserToken,
          removeStopWords: true,
          optionalWords: queryString.split(' '),
        },
      },
    ];
  } catch (error) {
    Sentry.captureException(error);
    return [];
  }
};
