import styled from 'styled-components';

import { ButtonEl } from '../button/styles';
import { Flow, FlowXFlex } from '../flow';
import { FormField } from '../forms/NEW/field';

export const Wrapper = styled(Flow)`
  text-align: center;
`;

export const Form = styled(FlowXFlex)`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;

  ${FormField} {
    min-width: 200px;
    flex: 1;
  }

  ${ButtonEl} {
    min-width: 200px;
  }
`;
