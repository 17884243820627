import Link from 'next/link';
import React from 'react';

import Container from 'components/common/container';
import {
  Twitter,
  Instagram,
  LinkedIn,
  YouTube,
  Pinterest,
  TikTok,
} from 'components/common/icons';
import ResponsiveBox from 'components/common/responsive-box';
import TrustpilotBanner from 'components/common/trustpilot-banner';
import { ISiteGlobalsFooterRightColumn } from 'utils/types';

import { Flow, FlowX } from '../flow';
import { VisuallyHidden } from '../text';

import { NavCol } from './nav-column';
import * as St from './styles';

export interface FooterLinkProps {
  _key: string;
  _type: 'link';
  path?: string;
  title?: string;
}

export interface FooterLinkColumnProps {
  _key: string;
  _type: 'linkColumn';
  title?: string;
  links?: FooterLinkProps[];
}

interface FooterProps {
  linkColumns?: FooterLinkColumnProps[];
  newsletterColumn?: {
    title?: string;
    body?: string;
  };
  rightColumn?: ISiteGlobalsFooterRightColumn;
}

export default function Footer({ linkColumns = [], rightColumn }: FooterProps) {
  return (
    <St.Wrapper>
      <St.StyledContainer>
        <St.Nav>
          {linkColumns.map((column) => (
            <NavCol key={column._key} column={column} />
          ))}
        </St.Nav>

        <Flow size="2xs" />

        <St.Sustainablilty size="2xs">
          {rightColumn && (
            <>
              {rightColumn.image && (
                <img
                  src="https://cdn.sanity.io/images/fpwrylru/prod/1668bd2d8c7af8ad558ed01131e71d41277f523b-366x196.svg"
                  alt={rightColumn.altText}
                  width="366"
                  height="195"
                />
              )}
              <p>{rightColumn.body}</p>
              {rightColumn.cta && rightColumn.ctaLink && (
                <p>
                  <Link href={rightColumn.ctaLink} passHref>
                    <a href={rightColumn.ctaLink}>
                      {rightColumn.cta}
                      <VisuallyHidden>
                        - Find out about Earthly and how we&apos;re removing
                        110% of our carbon footprint
                      </VisuallyHidden>
                    </a>
                  </Link>
                </p>
              )}
            </>
          )}
        </St.Sustainablilty>
      </St.StyledContainer>

      <Container>
        <ResponsiveBox aspectRatio={0.12}>
          <St.StyledLogo />
        </ResponsiveBox>
      </Container>

      <St.DarkWrapper>
        <Container>
          <FlowX>
            <a
              href="https://www.tiktok.com/@wearesproutl"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="TikTok"
            >
              <TikTok />
            </a>
            <a
              href="https://twitter.com/wearesproutl"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Twitter"
            >
              <Twitter />
            </a>
            <a
              href="https://www.instagram.com/wearesproutl/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Instagram"
            >
              <Instagram />
            </a>
            <a
              href="https://www.pinterest.co.uk/wearesproutl/"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Pinterest"
            >
              <Pinterest />
            </a>
            <a
              href="https://www.linkedin.com/company/sproutl"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="LinkedIn"
            >
              <LinkedIn />
            </a>
            <a
              href="https://www.youtube.com/channel/UC4_YW7uHqswTaGerrO-pfWg"
              target="_blank"
              rel="noreferrer noopener"
              aria-label="YouTube"
            >
              <YouTube />
            </a>
          </FlowX>

          <TrustpilotBanner theme="white" eventSource="footer" />

          <St.ContentInfo>
            <Link href="/about/privacy-policy">Privacy Policy</Link>
            <span>
              © {new Date().getFullYear()} Sproutl. All Rights Reserved.
            </span>
          </St.ContentInfo>
        </Container>
      </St.DarkWrapper>
    </St.Wrapper>
  );
}
