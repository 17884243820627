import { groq } from 'next-sanity';

import { BlocksInnerFragment, ProductsSkusQuery } from './fragments';

export const editsListingPageQuery = groq`
  *[_type == "editIndex"] {
    _id,
    title,
    body,
    showSubtitle,
    meta,
    edits[]->{
      slug,
      name,
      subTitle,
      banner,
      darkenOverlay
    }
  }
`;

export const editsPageQuery = groq`
  *[_type == "edit" && slug.current == $slug] {
    ...,
    banner {
      asset->
    },
    sets[] {
      ${BlocksInnerFragment},
      ${ProductsSkusQuery}
    }
  }
`;
