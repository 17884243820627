import { FC } from 'react';

import Section from 'components/common/section';

import SiteLink from '../site-link';
import { VisuallyHidden } from '../text';

import * as St from './styles';
import { IValuesBannerProps } from './types';

export const ValuesBanner: FC<IValuesBannerProps> = ({ images }) => {
  if (!images?.length) return null;

  return (
    <Section
      styles={{
        background: 'dahliaPink',
        topPadding: 'medium',
        bottomPadding: 'medium',
      }}
    >
      <VisuallyHidden as="h2">Sproutl Values</VisuallyHidden>
      <St.StyledContainer as="ul">
        {images.map((image) => (
          <li key={image._key}>
            <figure>
              <img src={image.asset.url} alt="" role="presentation" />
            </figure>
            {!!image.caption && (
              <h3>
                {image.url ? (
                  <SiteLink href={image.url}>{image.caption}</SiteLink>
                ) : (
                  image.caption
                )}
              </h3>
            )}
          </li>
        ))}
      </St.StyledContainer>
    </Section>
  );
};
