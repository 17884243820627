import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

import { Flow } from '../flow';

interface Heading2Props {
  inline?: boolean;
}

const Heading2 = styled.h2<Heading2Props>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-7);
  color: inherit;

  p + & {
    margin-top: 1em;
  }

  ${Flow} & {
    margin-bottom: 0;
  }
`;

export default Heading2;
