// Pots
export const POT_SIZE_MULTIPLIER = 1.5;
export const POT_SIZE_INDOOR_MAX_VARIANCE = 2;
export const POT_SIZE_INDOOR_MIN_VARIANCE = 1;
export const POT_SIZE_OUTDOOR_MAX_VARIANCE = 5;
export const POT_SIZE_OUTDOOR_MIN_VARIANCE = 2;
export const PLANTS_CATEGORIES_TO_EXCLUDE = ['plants/fruit-veg-and-herbs/veg'];
export const POT_SHAPES_TO_INCLUDE = [
  'Bell',
  'Classic',
  'Conical',
  'Cube',
  'Cylinder',
  'Egg',
  'Hex',
  'Jar',
  'Rectangular',
  'Round',
  'Square',
  'Tall Square',
];
export const POT_MATERIALS_TO_EXCLUDE = [
  'Faux Rattan',
  'Faux Rattan / Metal',
  'Hand Made Woven Basketware',
  'Plastic',
  'Poly rattan',
  'Polypropylene',
  'Polypropylene, Plastic',
  'Polyrattan',
  'Rattan effect',
  'Recycled Plastic',
  'Recycled Plastic, Metal',
  'Recycled Plastic, Polypropylene',
  'Recycled Plastic, Recycled Polypropylene, Rpp',
  'Recycled Polypropylene',
  'Regenerated Polypropylene',
  'Wicker and plastic',
];
