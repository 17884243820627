import styled from 'styled-components';

import theme from 'utils/theme';

export const ErrorMessage = styled.label`
  display: block;
  font-size: var(--step--1);
  line-height: 1.2;
  color: ${theme.colors.errorRed};
  letter-spacing: -0.02em;
  transition: opacity ${theme.transitionSpeeds.fast}s ease-out;
  opacity: 1;

  &:empty {
    opacity: 0;
  }

  /* Ensure it has height even when empty to avoid content jump */
  &:before {
    content: '';
    display: inline-block;
  }
`;

export const Message = styled.span`
  display: block;
  font-size: var(--step--2);
  line-height: 1.2;
  color: ${theme.colors.grey};
  font-weight: ${theme.weights.bold};
  text-align: right;
`;
