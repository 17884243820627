import { IProductMeta } from 'types/product';

import { getIndexes } from '..';
import {
  productHasPriceAndStock,
  productMetaAttributes,
} from '../../query-fragments';

type TSearchOptions = {
  attributesToRetrieve?: string[];
  distinct?: boolean;
  filters?: string;
  optionalFilters?: string | string[];
  hitsPerPage?: number;
};

const { anonUserToken, productIndex } = getIndexes();

const getProductMetaByQuery = async (
  query: string,
  options: TSearchOptions,
) => {
  const { hits, queryID } = await productIndex.search(query, {
    attributesToRetrieve: productMetaAttributes,
    distinct: 1,
    clickAnalytics: true,
    filters: productHasPriceAndStock,
    userToken: anonUserToken,
    removeStopWords: true,
    optionalWords: query.split(' '),
    ...(options || {}),
  });

  return {
    products: (<unknown>hits) as IProductMeta[],
    queryId: queryID,
  };
};

export default getProductMetaByQuery;
