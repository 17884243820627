import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

interface TitleHeadingProps {
  inline?: boolean;
}

const TitleHeading = styled.h1<TitleHeadingProps>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-9);
  color: inherit;

  p + & {
    margin-top: 1em;
  }
`;

export default TitleHeading;
