import { IProductVariant, TBestOffer } from 'types/product-commercetools';
import { ctLocalizedString } from 'utils/commercetools/product';
import { TChannel } from 'utils/commercetools/types';

import sortOffersByBestPrice from '../sort-offers-by-best-price';

function getVariantBestOffer({
  availability,
  prices,
}: Pick<IProductVariant, 'availability' | 'prices'>): TBestOffer | null {
  const { channels } = availability || {};

  if (!channels || !Object.keys(channels).length || !prices) {
    return null;
  }

  const offers = prices
    .filter((price) => !!price.channel?.id)
    .map((price) => {
      const priceAvailability = channels[price.channel?.id || ''];
      const partner =
        price.channel?.obj?.name && price.channel?.obj?.key
          ? {
              name: ctLocalizedString(price.channel.obj.name),
              slug: price.channel.obj.key,
            }
          : null;

      const channel = {
        typeId: 'channel',
        id: price.channel?.id || '',
      } as TChannel;

      return {
        isOnStock: priceAvailability ? priceAvailability.isOnStock : false,
        price: price.value,
        availableQuantity: priceAvailability?.availableQuantity || 0,
        channel,
        partner,
      };
    })
    .sort(sortOffersByBestPrice);

  const onStockOffers = offers.filter((offer) => offer.isOnStock);

  return onStockOffers[0] || offers[0] || null;
}

export default getVariantBestOffer;
