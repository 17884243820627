import React, { FC, useContext, useState } from 'react';

import Button from 'components/common/button';
import { Flow } from 'components/common/flow';
import { Close, Envelope } from 'components/common/icons';
import ModalNotification from 'components/common/modal-notification';
import * as Modal from 'components/common/modal/two-column-styles';
import { Settings } from 'contexts';
import {
  PortableText,
  PortableTextWithKeywordReplacement,
} from 'framework/sanity/portable-text';
import { urlFor } from 'framework/sanity/url-for';
import { trackMGM, trackMGMPopupClose } from 'utils/gtm/events';
import { tablet } from 'utils/media';

import {
  copyToClipboard,
  generateMailto,
  generateReferralCode,
  shareReferralCode,
} from './helpers';
import * as St from './styles';
import { IReferralPopupProps, ReferralState } from './types';

export const ReferralPopup: FC<IReferralPopupProps> = ({
  isReferralModalOpen,
  setIsReferralModalOpen,
  initialState = ReferralState.idle,
  initialReferralCode = '',
  email,
}) => {
  const { supplementaryContent } = useContext(Settings);

  const [shareState, setShareState] = useState(initialState);
  const isInFinalState =
    shareState === ReferralState.share || shareState === ReferralState.copied;
  const [referralCode, setReferralCode] = useState(initialReferralCode);

  const content = supplementaryContent?.mgm;
  if (!content || !content.popupInitialContent.length) return null;

  const image = content.ctaImage
    ? urlFor(content.ctaImage).width(600).auto('format').url()
    : '';
  const mobileImage = content.ctaImage
    ? urlFor(content.ctaImage).width(750).height(400).auto('format').url()
    : '';

  const dismissPopup = () => {
    setIsReferralModalOpen(false);
    trackMGMPopupClose(shareState);
  };

  const copyCode = () => {
    copyToClipboard(referralCode);
    setShareState(ReferralState.copied);
    trackMGM('Code copied');
  };

  const emailCode = () => {
    trackMGM('Code emailed');
  };

  const onClick = async () => {
    if (isInFinalState) {
      shareReferralCode({
        referralCode,
        ctaText: content.shareText,
        ctaSubject: content.shareSubject,
        onCopy: () => setShareState(ReferralState.copied),
      });

      trackMGM('Code shared');
    }

    if (shareState === ReferralState.idle) {
      setReferralCode(
        await generateReferralCode({
          email,
          setShareState,
        }),
      );
    }
  };

  return (
    <ModalNotification
      isBespokeModal
      isOpen={isReferralModalOpen}
      closeModal={dismissPopup}
    >
      <Modal.StyledContainer>
        <Modal.Wrapper
          isOpen={isReferralModalOpen}
          hasImage={!!content.popupImage}
          data-testid="referralPopup"
        >
          {image && (
            <figure>
              <picture>
                <source srcSet={image} media={tablet.replace('@media', '')} />
                <img src={mobileImage || image} alt="" />
              </picture>
            </figure>
          )}
          <Modal.ContentWrapper>
            <Modal.CloseButton
              type="button"
              data-testid="email-popup-close"
              onClick={dismissPopup}
              aria-label="Close popup"
            >
              <Close height={16} width={16} />
            </Modal.CloseButton>
            <Flow size="l">
              {isInFinalState ? (
                <PortableTextWithKeywordReplacement
                  renderContainerOnSingleChild
                  content={content.popupSuccessContent}
                  keywords={{
                    'referral code': (
                      <St.Code type="button" onClick={copyCode}>
                        {referralCode}
                      </St.Code>
                    ),
                  }}
                />
              ) : (
                <PortableText
                  renderContainerOnSingleChild
                  blocks={content.popupInitialContent}
                />
              )}

              <St.ButtonWrapper wrap="none">
                <Button
                  type="button"
                  onClick={onClick}
                  loading={shareState === ReferralState.generating}
                  loadingText={shareState}
                  width={isInFinalState ? 'half' : 'full'}
                >
                  {shareState === ReferralState.idle
                    ? content.popupInitialCta || shareState
                    : shareState}
                </Button>
                {isInFinalState && (
                  <a
                    href={generateMailto(
                      referralCode,
                      content.shareText,
                      content.shareSubject,
                    )}
                    target="_blank"
                    rel="noreferrer"
                    onClick={emailCode}
                  >
                    <Button variant="secondary" width="full" as="span">
                      Email Code <Envelope />
                    </Button>
                  </a>
                )}
              </St.ButtonWrapper>
            </Flow>
            {!!content.popupFooter?.length && (
              <St.Footer>
                <PortableText
                  renderContainerOnSingleChild
                  blocks={content.popupFooter}
                />
              </St.Footer>
            )}
          </Modal.ContentWrapper>
        </Modal.Wrapper>
      </Modal.StyledContainer>
    </ModalNotification>
  );
};
