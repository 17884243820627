import styled from 'styled-components';

import { mobile, tablet } from 'utils/media';

import Container from '../container';
import SiteLink from '../site-link';

interface IWrapperProps {
  backgroundHex: string;
  textHex: string;
}

export const StyledLink = styled(SiteLink)`
  display: block;
  padding: var(--space-3xs-2xs) 0;
  color: inherit;

  ${mobile} {
    font-size: var(--step--2);

    :not(:first-child) {
      display: none;
    }
  }
`;

export const Wrapper = styled.aside<IWrapperProps>`
  font-size: var(--step--1);
  background: linear-gradient(
      ${({ backgroundHex }) => `${backgroundHex}, ${backgroundHex}`}
    ),
    #fff;
  color: ${({ textHex }) => textHex};
  text-align: center;
  position: relative;

  ${tablet} {
    ${Container} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${StyledLink} {
      width: 50%;

      :only-child {
        width: 100%;
      }

      :first-child:not(:only-child) {
        text-align: right;
        padding-right: var(--space-3xs-3xl);
      }

      :last-child:not(:only-child) {
        text-align: left;
        padding-left: var(--space-3xs-3xl);
      }

      /* Force faux links to wrap as one */
      [style*='underline'] {
        white-space: nowrap;
      }

      & + *:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        border-left: 2px solid;
        height: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  p {
    margin: 0;

    & + p {
      margin-top: var(--space-3xs);
    }
  }
`;
