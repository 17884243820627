import { ProductProjection } from '@commercetools/platform-sdk';

import { IProductProjectionInProgress } from 'types/product-commercetools';
import { normalizeAttributes } from 'utils/helpers';

import getVariantBestOffer from '../get-variant-best-offer';

function getProjectionBestOffers(
  projection: ProductProjection,
): IProductProjectionInProgress {
  if (!projection) {
    return projection;
  }

  const { variants, masterVariant } = projection;
  const projectionWithOffers: IProductProjectionInProgress = {
    ...projection,
    masterVariant: {
      ...masterVariant,
      bestOffer: getVariantBestOffer(masterVariant),
      attributesNormalized: normalizeAttributes(masterVariant),
    },
    variants: [],
  };

  if (variants?.length) {
    projectionWithOffers.variants = variants.map((variant) => ({
      ...variant,
      bestOffer: getVariantBestOffer(variant),
      attributesNormalized: normalizeAttributes(variant),
    }));
  }

  return projectionWithOffers;
}

export default getProjectionBestOffers;
