export const PLPHeaderLinks = `
  "links": links[]{
    "contentItem": contentItem->{
      _type,
      name,
      title,
      "slug": slug.current
    },
    linkUrl,
    linkCopy
  }
`;
