export const GenusTileFragment = `
  _id,
  name,
  slug,
  botanicalName,
  // @TODO: This will fetch all photos regardless of index, look out for better solution
  images[] {
    'index': photoIndex - 1,
    'photos': productVariant->photos[] { ..., asset-> },
    'credit': productVariant->attributes[_key == 'botanic_name'][0].value,
  },
`;
