import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';

export const homePageQuery = groq`
  *[_type == "homepage" && _id == "homepage"] {
    _id,
    meta,
    blocks[] {
      ${BlocksInnerFragment}
    }
  }
`;
