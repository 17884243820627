import REWRITES from '../../rewrites';

export const stripQueryAndHash = (url: string) => url.split(/[#?]/i)[0];

export const getCanonicalDomain = () => {
  return `https://${
    process.env.NEXT_PUBLIC_HOST || process.env.NEXT_PUBLIC_VERCEL_URL
  }`;
};

/**
 * Transforms path string into an regex string with wildcard support
 * @example - pathToRegex('/something/*') => '^\/something\/.*$'
 */
export const pathToRegex = (path: string) =>
  `^${path.replace(/\*/gi, '.*').replace(/\//g, '/')}$`;

export const getCanonicalUrl = (asPath: string) => {
  const isIndex = asPath === '/index'; // A bug in nextjs where `asPath` for homepage is /index instead of /, may be related to https://github.com/vercel/next.js/issues/23849

  const rewritesRecord = REWRITES.find(
    ({ destination }) => destination === asPath,
  );

  return (
    getCanonicalDomain() +
    (!!rewritesRecord
      ? rewritesRecord.source
      : stripQueryAndHash(isIndex ? '/' : asPath))
  );
};
