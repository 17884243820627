import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

interface Heading1Props {
  inline?: boolean;
}

const Heading1 = styled.h1<Heading1Props>`
  ${headingFont}
  margin-top: 0;
  margin-bottom: ${({ inline }) => (inline ? 0 : '20px')};
  font-size: var(--step-9);
  color: inherit;

  p + & {
    margin-top: 1em;
  }
`;

export default Heading1;
