import { createGlobalStyle, css } from 'styled-components';

import { desktop } from 'utils/media';
import theme from 'utils/theme';

export const IntegrationsStyles = createGlobalStyle<{
  cookieAccepted: boolean;
}>`
/* Hotjar feedback */
._hj_feedback_container {
  z-index: ${theme.zIndexes.hotjarFeedback};
  position: relative;
}

/* Zendesk chat */
iframe#launcher {
  transition-property: opacity, top, margin !important;
  
  ${(props) =>
    props.cookieAccepted
      ? // No cookie banner
        css`
          z-index: ${theme.zIndexes.low} !important;
          margin: 10px 14px !important;

          ${desktop} {
            margin: 10px 20px !important;
            margin: 20px !important;
          }
        `
      : // Cookie banner
        css`
          z-index: ${theme.zIndexes.loader} !important;
          margin: 90px 16px !important;

          ${desktop} {
            margin: 20px !important;
          }
        `}
}

iframe#webWidget {
  z-index: ${theme.zIndexes.chatPopup} !important;
}
`;
