import React from 'react';

interface AftercareProps {
  wordMark?: string;
  graphic?: string;
  width?: number;
  height?: number;
}

export default function AftercareFullLogo({
  wordMark = '#FFF',
  graphic = '#FFF',
  width = 230,
  height = 39,
}: AftercareProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 230 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M198 20v-3.3a8.3 8.3 0 0 1-8.4-8.4V0h.5a10.3 10.3 0 0 1 9.8 10.3v.8h1.5v-.9a10.4 10.4 0 0 1 10-10.2h.4v8.3a8.5 8.5 0 0 1-3.5 6.9c-1.4 1-3.1 1.5-4.8 1.5v3.4M210.5 34.6l7.3-12.6a14 14 0 0 0 1.8-5.7l-.3-6.3a2.2 2.2 0 0 0-2.2-2.2 2.3 2.3 0 0 0-2.4 2.2V16a7 7 0 0 1-.6 3.1l-3 5.1c0 .2-.2.3-.3.4h-.5a.7.7 0 0 1-.3-1l1.8-3.2a2.2 2.2 0 0 0-2.4-3.3c-.6.2-1.1.6-1.4 1l-2.6 4.5c-.4.8-.6 1.6-.7 2.5l-.4 9.5M191 34.6 183.7 22a14 14 0 0 1-1.8-5.7l.4-6.3a2.2 2.2 0 0 1 2.1-2.2 2.3 2.3 0 0 1 2.4 2.2V16c0 1 .2 2.1.7 3.1l3 5.1c0 .2 0 .3.2.4h.5a.7.7 0 0 0 .3-1l-1.8-3.2a2.2 2.2 0 0 1 .8-3 2.2 2.2 0 0 1 3 .8l2.6 4.4c.4.8.6 1.6.7 2.5l.4 9.5"
        fill={graphic}
      />
      <path
        d="M12.4 28.7c0-2.2-1-3.7-3-4.6l-3.5-1c-1-.3-1.3-.6-1.3-1.2 0-.9.8-1.3 2.2-1.3 1 0 1.8.1 2.7.5.9.3 1.3.6 2.3 1.6v-4.9a9 9 0 0 0-5.2-1.6c-4 0-6.6 2.3-6.6 5.7 0 1.3.4 2.5 1.3 3.4.7.8 2 1.4 4 2 2.3.6 2.8.9 2.8 1.7 0 .9-.9 1.4-2.2 1.4-1 0-2-.2-3-.6-.8-.4-1.3-.7-2.3-1.7v4.5a9.5 9.5 0 0 0 5.6 1.6c3.6 0 6.2-2.3 6.2-5.5ZM28.6 25.9c0-4.6-1.8-7-5.2-7-1.4 0-2.6.4-3.5 1.1-.6.6-.9 1-1.4 2.2h-.1l.1-1.8v-1h-4.4v17.8H19v-2.4c0-1.1-.1-2-.3-3.1h.1c1 2 2.2 2.6 4 2.6 3.6 0 5.8-3.3 5.8-8.4Zm-10.1.7c0-2 1-3.3 2.8-3.3 1.7 0 2.6 1.2 2.6 3.2 0 2.2-1.1 3.7-2.8 3.7-1.6 0-2.6-1.3-2.6-3.6ZM35 30.6c0-3.1.3-4.7 1-5.7.5-.7 1.4-1 2.2-1 .9 0 1.3.1 2 .8h.2l.2-5c-.7-.6-1.4-.8-2.2-.8-1 0-1.8.5-2.5 1.3-.7 1-1 1.8-1.3 3.7h-.1l.3-4.5h-4.5v14.4h4.8v-3.2ZM54 32.3a9.2 9.2 0 0 0 1.8-6c0-4.9-2.3-7.4-7-7.4-4.9 0-7.3 2.5-7.3 7.5 0 2.5.7 4.8 2 6 1 1.3 2.7 2 5 2 2.4 0 4.2-.8 5.4-2ZM46 27c0-2.3.9-3.4 2.7-3.4 1.8 0 2.7 1 2.7 3.2 0 2.5-1 4-2.8 4-1.7 0-2.7-1.4-2.7-3.8ZM65.2 33.1c.6-.6 1-1.1 1.4-2.4h.1l-.1 1.6v1.5h4.6V19.4h-4.8v6.9c0 1.6 0 1.9-.3 2.4a2 2 0 0 1-1.9 1.4c-.7 0-1.2-.3-1.5-.8-.3-.5-.3-.7-.3-3v-6.9h-5v7l.1 3.5a6 6 0 0 0 .9 2.7c.8 1 2.1 1.7 3.6 1.7 1.3 0 2.4-.4 3.2-1.2ZM84 32.6v-3.4c-.8 1-1.6 1.4-2.7 1.4-.6 0-1.1-.2-1.4-.6-.3-.5-.4-1.1-.4-2.3v-4h4.9v-4.1h-5v-3.7H77c-.7 2.6-2.4 4.5-4.4 5v3H75l-.2 3.9c0 2.6.3 3.9 1 4.8.6 1.1 2 1.7 3.8 1.7 1.9 0 3.1-.5 4.4-1.7ZM91 16h-5v17.8h5V15.9ZM108.3 15.4c-5 0-8.4 4.6-8.4 9.5 0 5.7 3 9.7 8.4 9.7 3.8 0 6.7-2.3 7.7-5.7l-2.4-1.8h-.1c-.6 3-2.6 5-5.2 5-3.6 0-5.6-3-5.6-7.2 0-3.5 2.1-7 5.6-7 2.5 0 4 1.7 5 4.1l2.1-2c-.9-2-3.2-4.6-7-4.6ZM136.6 34.3 130 15.8h-4.2l-6.5 18.5h3l1.7-5h7.8l1.8 5h3Zm-8.7-16.2 3.1 8.7h-6.2l3-8.7ZM141.8 15.8v18.5h2.8v-7.8h1.7l5.4 7.8h3.6l-5.8-7.9c3.2-.2 5.7-1.9 5.7-5.2 0-3.5-2-5.4-6.1-5.4h-7.3Zm2.8 2.5h4c2.9 0 3.8.9 3.8 2.9S151 24 148.3 24h-3.7v-5.8ZM173 18.3v-2.5h-11.4v18.5h11.3v-2.5h-8.5V26h8.5v-2.3h-8.5v-5.5h8.5Z"
        fill={wordMark}
      />
    </svg>
  );
}
