import styled, { css } from 'styled-components';

import { DESKTOP_NAV_CHILD_HEIGHT } from 'components/common/header/constants';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

interface IStickyGlobalHeaderProps {
  staticHeader?: boolean;
}

export const StickyGlobalHeader = styled.header<IStickyGlobalHeaderProps>`
  z-index: ${theme.zIndexes.header};
  position: relative;

  ${({ staticHeader }) =>
    !staticHeader &&
    css`
      position: sticky;
      top: 0;

      /* ${desktop} {
        margin-bottom: ${DESKTOP_NAV_CHILD_HEIGHT}px;
      } */
    `}
`;

export const HeaderAlertContainer = styled.div`
  z-index: ${theme.zIndexes.normal};

  ${desktop} {
    z-index: ${theme.zIndexes.header + 1};
  }
`;
