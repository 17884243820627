import React from 'react';

interface SizeGuideProps {
  width?: number | string;
  height?: number | string;
}

export default function SizeGuide({ width = 12, height = 12 }: SizeGuideProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.137 9.333V2.657L5.56 4.05l1.124-1.1L3.905.227A.796.796 0 003.341 0a.81.81 0 00-.562.228L0 2.95l1.124 1.1 1.424-1.393v6.676h1.589z"
        fill="currentColor"
      />
      <path
        d="M4.137 2.667v6.676L5.56 7.95l1.124 1.1-2.78 2.723a.795.795 0 01-.563.228.808.808 0 01-.562-.228L0 9.05l1.124-1.1 1.424 1.393V2.667h1.589zM12 0H6.652v1.333H12V0zM12 2.667H8.709V4h3.29V2.667zM12 5.333H6.652v1.334H12V5.333zM12 8H8.709v1.333h3.29V8zM12 10.667H6.652V12H12v-1.333z"
        fill="currentColor"
      />
    </svg>
  );
}
