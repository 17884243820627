import styled from 'styled-components';

import { mobile } from 'utils/media';
import theme from 'utils/theme';

interface IWrapperProps {
  backgroundHex: string;
  textHex: string;
}

export const Wrapper = styled.div<IWrapperProps>`
  background: ${({ backgroundHex }) => backgroundHex};
  color: ${({ textHex }) => textHex};
  text-align: center;
  font-size: var(--step--1);
  z-index: ${theme.zIndexes.modals};
  position: relative;
  overflow: hidden;

  a {
    color: inherit;
  }

  ${mobile} {
    font-size: var(--step--2);
  }

  p {
    margin: 0;

    & + p {
      margin-top: var(--space-3xs);
    }
  }
`;

export const BannerTopFadeOut = styled.span`
  display: grid;
  align-items: center;
  width: 100%;
`;

interface HeaderAlertLinkProps {
  active: boolean;
}

export const HeaderAlertLink = styled.a<HeaderAlertLinkProps>`
  grid-column: -1 / 1;
  grid-row: -1 / 1;
  width: 100%;
  padding: var(--space-2xs);
  display: block;
  opacity: ${({ active }) => (active ? 1 : 0)};
  transition: opacity ${theme.transitionSpeeds.normal}s ease-in-out;
  transition-delay: ${({ active }) =>
    active ? `${theme.transitionSpeeds.normal}s` : 0};
  z-index: ${({ active }) => (active ? 1 : 0)};
`;
