import styled from 'styled-components';

import theme from 'utils/theme';

export const Figure = styled.figure`
  :not(:first-child),
  :not(:last-child) + * {
    --prose-flow: var(--space-xl-2xl);
  }

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: block;
  }

  figcaption {
    display: block;
    margin-top: var(--space-3xs);
    font-size: var(--step-0);

    span {
      display: block;
    }
  }
`;

export const Credit = styled.span`
  font-size: var(--step--2);
  text-align: right;
  color: ${theme.colors.darkGrey};
`;
