import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { pathToRegex, stripQueryAndHash } from 'utils/url';

export const ZENDESK_SUPPRESS_PATHS = ['/', '/collections/*'];

// @TODO: Sanity configurable paths
export const useZendeskChatSuppress = (paths: string[]) => {
  const router = useRouter();

  useEffect(() => {
    const suppressPathsRegex = new RegExp(
      paths
        .map((path) => `(${pathToRegex(stripQueryAndHash(path))})`)
        .join('|'),
    );

    window.__zendeskSuppressPathsRegex = suppressPathsRegex; // Expose to GTM

    const updateChatSuppress = (suppress: boolean) => {
      typeof window.zE === 'function' &&
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            chat: {
              suppress,
            },
          },
        });
    };

    const handleRouteChange = (path: string) => {
      const shouldBlockZendeskChat = !!path.match(suppressPathsRegex);
      updateChatSuppress(shouldBlockZendeskChat);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
