import styled from 'styled-components';

import { BreakpointWrapperProps } from './types';

export const BreakpointWrapper = styled.div<BreakpointWrapperProps>`
  display: none;
  ${(props) => props.media} {
    display: block;
  },
`;
