import { camelCase } from 'lodash';

import { extractVariableAttributes } from 'components/layouts/product/helpers';
import {
  IMappedProductTileData,
  IProductProjection,
} from 'types/product-commercetools';
import { ctLocalizedString } from 'utils/commercetools/product';
import { NormalizedAttributes } from 'utils/commercetools/types';
import { IAttributeDefinition } from 'utils/types';

function mapProjectionsToProductTile(
  projections: IProductProjection[],
  options: {
    enableQuickBuy?: boolean;
    attributeDefinitions?: IAttributeDefinition[];
  } = {},
): IMappedProductTileData[] {
  const productMapping = projections.map(
    (projection): IMappedProductTileData => {
      const { attributesNormalized, productWithBestOffer, name } = projection;
      const { bestOffer, images, key, sku } = productWithBestOffer || {};

      const price = bestOffer?.price.centAmount
        ? bestOffer?.price.centAmount / 100
        : NaN;

      let mappedProduct: IMappedProductTileData = {
        categorySlug: attributesNormalized?.category_slug || '',
        centAmount: bestOffer?.price.centAmount || NaN,
        images: images?.slice(0, 2) || [],
        productName: ctLocalizedString(name),
        price,
        selectedPartner: {
          centAmount: bestOffer?.price.centAmount || NaN,
          id: bestOffer?.channel?.id || '',
          price,
          slug: bestOffer?.partner?.slug || '',
          stockAmount: bestOffer?.availableQuantity || 0,
        },
        slug: key || '',
        sproutlSku: sku || '',
      };

      if (!!options.enableQuickBuy) {
        mappedProduct = {
          ...mappedProduct,
          ...extractOptionalAttributes(attributesNormalized),
          projection,
          images,
        };

        mappedProduct.whyWeLoveIt = attributesNormalized?.description;

        if (options.attributeDefinitions?.length) {
          mappedProduct.variableAttributeDefinitions =
            extractVariableAttributes(
              attributesNormalized,
              options.attributeDefinitions,
            );
        }
      }

      return mappedProduct;
    },
  );

  return productMapping;
}

function extractOptionalAttributes(attributesNormalized: NormalizedAttributes) {
  return Object.entries(attributesNormalized).reduce(
    (running, [key, value]) => {
      if (value === undefined) return running;

      return {
        ...running,
        [camelCase(key)]: value,
      };
    },
    {},
  );
}

export default mapProjectionsToProductTile;
