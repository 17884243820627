import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';

export const carePageQuery = groq`
*[_type == "care" && slug.current == $slug] {
  _id,
  name,
  meta,
  blocks[] {
    ${BlocksInnerFragment}
  }
}
`;
