const BodyInnerFragment = `
  ...,
  _type == "image" => {
    ...,
    asset->{
      _id,
      _ref,
      _type,
      altText
    }
  }
`;

export const BodyFragment = `
  _type == 'textBlock' => {
    "body": body[]{
      ${BodyInnerFragment}
    }
  },
  _type == 'twoColumnTextBlock' => {
    "columnOne": columnOne[]{
      ${BodyInnerFragment}
    },
    "columnTwo": columnTwo[]{
      ${BodyInnerFragment}
    }
  }
`;
