import styled, { css } from 'styled-components';

import theme from 'utils/theme';

import { Heading1, Heading2, Heading3, Heading4, Heading5 } from '../text';

interface ContainerProps {
  noGutter?: boolean;
  noPadding?: boolean;
  inset?: boolean;
  outset?: boolean;
  flow?: string;
}

const Container = styled.div<ContainerProps>`
  position: relative;
  max-width: 100%;
  margin: 0 auto;

  width: ${({ inset, outset, noGutter }) =>
    inset
      ? theme.pageWidth.inset
      : outset || noGutter
      ? theme.pageWidth.extended
      : theme.pageWidth.standard}px;

  ${(props) =>
    !props.noGutter &&
    css`
      padding: ${props.noPadding ? 0 : '0 var(--site-gutter)'};
    `};

  ${({ flow }) =>
    flow &&
    css`
      > ${Heading1},
        > ${Heading2},
        > ${Heading3},
        > ${Heading4},
        > ${Heading5},
        > p {
        margin-bottom: 0;
      }

      > * + * {
        margin-top: var(--flow, var(--space-${flow})) !important;
      }
    `}
`;

export default Container;
