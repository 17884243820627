import React, { FC } from 'react';

import { LoadingTextContainer, AnimatedDot } from './styles';
import { ILoadingTextProps } from './types';

const LoadingText: FC<ILoadingTextProps> = ({ text, alwaysShow = false }) => {
  return (
    <LoadingTextContainer alwaysShow={alwaysShow}>
      {text}
      <AnimatedDot delay={0.1} />
      <AnimatedDot delay={0.2} />
      <AnimatedDot delay={0.3} />
    </LoadingTextContainer>
  );
};

export default LoadingText;
