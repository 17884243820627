import styled, { CSSObject } from 'styled-components';

import { LoadingTextContainer } from 'components/common/loading-text/styles';
import { desktop } from 'utils/media';
import theme from 'utils/theme';

export type TButtonVariants =
  | 'primary'
  | 'secondary'
  | 'secondaryFilled'
  | 'secondaryOption'
  | 'tertiaryFilled'
  | 'circle'
  | 'inline'
  | 'link'
  | 'small'
  | 'pill';

export type TButtonWidths = 'auto' | 'half' | 'full';

interface ButtonElProps {
  colorScheme: Sproutl.ColorScheme;
  loadingText: string;
  disabled?: boolean;
  selected?: boolean;
  variant: TButtonVariants;
  width?: TButtonWidths;
  $loading?: boolean;
  hasIcon?: boolean;
}

const primaryColorSchemes: Partial<
  Record<
    Sproutl.ColorScheme,
    { color: string; borderColor: string; backgroundColor: string }
  >
> = {
  white: {
    color: theme.colors.black,
    borderColor: theme.colors.white,
    backgroundColor: theme.colors.white,
  },
  black: {
    color: theme.colors.white,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.black,
  },
  dahliaPink: {
    color: theme.colors.black,
    borderColor: theme.colors.dahliaPink,
    backgroundColor: theme.colors.dahliaPink,
  },
};

const primaryHoverSchemes: Partial<
  Record<
    Sproutl.ColorScheme,
    { color: string; borderColor: string; backgroundColor: string }
  >
> = {
  white: {
    color: theme.colors.black,
    borderColor: theme.colors.beige,
    backgroundColor: theme.colors.beige,
  },
  black: {
    color: theme.colors.white,
    borderColor: theme.colors.darkGrey,
    backgroundColor: theme.colors.darkGrey,
  },
  dahliaPink: {
    color: theme.colors.black,
    borderColor: theme.colors.dahliaPink,
    backgroundColor: theme.colors.dahliaPink,
  },
};

const secondaryColorSchemes: Record<
  string,
  {
    color: string;
    border?: number | string;
    borderColor?: string;
    backgroundColor: string;
  }
> = {
  blackTransparent: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: 'transparent',
  },
  blackWhite: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
  white: {
    color: theme.colors.black,
    borderColor: 'transparent',
    backgroundColor: theme.colors.white,
  },
  greenTextOnly: {
    color: theme.colors.darkGreen,
    border: 0,
    backgroundColor: 'transparent',
  },
  beige: {
    color: theme.colors.black,
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.beige,
  },
};

const smallColorSchemes: Record<
  string,
  {
    color: string;
    border?: number | string;
    borderColor?: string;
    backgroundColor: string;
    fontWeight?: number;
    textDecoration?: string;
  }
> = {
  beige: {
    color: theme.colors.black,
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.beige,
  },
  black: {
    color: theme.colors.white,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.black,
  },
  whiteTransparent: {
    color: theme.colors.white,
    borderColor: theme.colors.white,
    backgroundColor: 'transparent',
  },
  offWhite: {
    color: theme.colors.black,
    borderColor: theme.colors.lightGrey,
    backgroundColor: theme.colors.white,
  },
  white: {
    color: theme.colors.black,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.white,
  },
};

const linkColorSchemes: Record<
  string,
  {
    color: string;
  }
> = {
  green: {
    color: theme.colors.darkGreen,
  },
  black: {
    color: theme.colors.black,
  },
};

const smallHoverSchemes: Record<
  string,
  {
    color: string;
    border?: number | string;
    borderColor?: string;
    backgroundColor: string;
  }
> = {
  beige: {
    ...smallColorSchemes.beige,
    borderColor: theme.colors.irisPurple,
  },
  black: {
    ...smallColorSchemes.black,
    borderColor: theme.colors.darkGrey,
    backgroundColor: theme.colors.darkGrey,
  },
  whiteTransparent: {
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
    borderColor: theme.colors.white,
  },
  offWhite: {
    ...smallColorSchemes.offWhite,
    borderColor: theme.colors.irisPurple,
  },
  white: {
    color: theme.colors.white,
    borderColor: theme.colors.black,
    backgroundColor: theme.colors.black,
  },
};

const variantStyles = ({
  colorScheme,
  disabled,
  selected,
  hasIcon = false,
}: ButtonElProps): Record<string, CSSObject> => ({
  defaults: {
    margin: 0,
    fontSize: 'var(--step-0)',
    borderWidth: 2,
    cursor: 'pointer',
    borderRadius: 'var(--space-2xl)',
    alignItems: 'center',
    borderStyle: 'solid',
    display: 'inline-flex',
    justifyContent: 'center',
    textAlign: 'center',

    // Properties which are sometimes overriden
    padding: hasIcon
      ? 'var(--space-3xs-2xs) var(--space-s-l)'
      : 'var(--space-2xs-xs) var(--space-s-l)',
    fontWeight: theme.weights.bold,

    transition:
      'background-color ease-out 150ms, border-color ease-out 150ms, color ease-out 150ms, transform ease-out 150ms',

    '&:focus': {
      outline: 'none',
    },

    'svg + *': {
      marginLeft: '0.5em',
    },
  },

  primary: {
    ...(primaryColorSchemes[colorScheme] || {}),

    ':hover, a:hover &, &:focus': primaryHoverSchemes[colorScheme] || {},
  },

  secondary: {
    color: theme.colors[colorScheme],
    backgroundColor: 'transparent',
    borderColor: theme.colors[colorScheme],

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors[colorScheme],
      backgroundColor: theme.colors[colorScheme],
      color:
        colorScheme === 'pineGreen' ||
        colorScheme === 'black' ||
        colorScheme === 'thistleBlue'
          ? theme.colors.white
          : theme.colors.black,
    },
  },

  secondaryOption: {
    ...secondaryColorSchemes.blackWhite,

    borderColor: selected ? theme.colors.purple : theme.colors.midGrey,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  secondaryFilled: {
    ...secondaryColorSchemes.blackWhite,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  tertiaryFilled: {
    ...secondaryColorSchemes.blackWhite,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.black,
      backgroundColor: theme.colors.black,
      color: theme.colors.white,
    },
  },

  circle: {
    ...secondaryColorSchemes.blackTransparent,

    padding: '12px 11px',
    width: theme.sizes.button.height,

    ':hover, a:hover &, &:focus': {
      borderColor: theme.colors.purple,
    },
  },

  inline: {
    ...secondaryColorSchemes.greenTextOnly,

    padding: 0,
    fontWeight: theme.weights.normal,

    ':hover, a:hover &': {
      color: theme.colors.black,
    },
  },

  link: {
    ...secondaryColorSchemes.greenTextOnly,
    ...(linkColorSchemes[colorScheme] || {}),

    padding: 0,
    fontWeight: theme.weights.normal,

    textDecoration: 'underline',
  },

  small: {
    fontWeight: theme.weights.bold,

    ...(smallColorSchemes[colorScheme || 'beige'] || {}),

    padding: 'var(--space-2xs) var(--space-xs)',
    fontSize: 'var(--step--1)',

    ':hover, a:hover &, &:focus': {
      ...(!disabled ? smallHoverSchemes[colorScheme || 'beige'] || {} : {}),
    },

    ...(selected ? smallHoverSchemes[colorScheme || 'beige'] : {}),
  },

  pill: {
    ...smallColorSchemes['beige'],
    fontWeight: theme.weights.normal,
    padding: 'var(--space-xs-s) var(--space-l)',
    borderColor: 'transparent',

    ':hover, a:hover &, &:focus': {
      ...(!disabled ? smallHoverSchemes[colorScheme || 'beige'] || {} : {}),
    },
  },
});

const variantDisabledStyles = ({
  selected,
}: ButtonElProps): Record<string, any> => ({
  default: {
    color: theme.colors.midGrey,
    borderColor: theme.colors.midGrey,
    backgroundColor: theme.colors.lighterGrey,

    cursor: 'inherit',
    pointerEvents: 'none',

    '&, &:hover, &:focus': {
      borderColor: theme.colors.midGrey,
    },
  },

  secondaryOption: {
    background: theme.colors.lighterGrey,
    borderColor: selected ? theme.colors.black : theme.colors.midGrey,
    position: 'relative',
    overflow: 'hidden',

    ':before': {
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      right: 0,
      borderTop: '2px solid',
      borderColor: theme.colors.midGrey,

      transform: 'rotate(135deg)',
    },

    ':hover, &:focus': {
      borderColor: selected ? theme.colors.black : theme.colors.midGrey,
    },
  },

  inline: {
    color: theme.colors.grey,
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    pointerEvents: 'none',
    cursor: 'inherit',

    '&, &:hover, &:focus': {
      borderColor: 'transparent',
    },
  },

  small: {
    overflow: 'hidden',
    cursor: 'not-allowed',
    opacity: 0.5,

    ':before': {
      position: 'absolute',
      content: '""',
      left: 0,
      top: '50%',
      right: 0,
      borderTop: '2px solid',
      borderColor: theme.colors.midGrey,

      transform: 'rotate(135deg)',
    },
  },
});

export const ChildrenWrapper = styled.span`
  display: contents;
`;

export const ButtonEl = styled.button<ButtonElProps>((props) => {
  const { disabled, $loading = false, variant, width } = props;

  const variantStyle = variantStyles(props);
  const variantDisabledStyle = variantDisabledStyles(props);

  return {
    position: 'relative',

    // Set the width which can be overridden by the variants
    width: width === 'full' ? '100%' : width === 'half' ? '100%' : 'auto',
    [desktop]: {
      width: width === 'full' ? '100%' : width === 'half' ? '50%' : 'auto',
    },

    // Get default styles
    ...variantStyle.defaults,

    // Get variant specific styles
    ...variantStyle[variant],

    '&:active': {
      transform: 'scale(0.95)',
    },

    // Override with disabled styles if needed
    ...(disabled && !$loading
      ? variantDisabledStyle[variant] || variantDisabledStyle.default
      : {}),

    ...($loading
      ? {
          pointerEvents: 'none',
          userSelect: 'none',
          [LoadingTextContainer]: { display: 'flex' },
          [ChildrenWrapper]: { display: 'none' },
        }
      : {}),
  };
});
