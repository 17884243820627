const REWRITES = [
  {
    source: '/robots.txt',
    destination: `/robots.${
      process.env.VERCEL_ENV === 'production' ? 'production' : 'staging'
    }.txt`,
  },
  {
    source: '/api/rates/:path*',
    destination: `${process.env.NEXT_PUBLIC_RATES_ENDPOINT}/:path*`,
  },
  {
    source: '/about',
    destination: '/about/about',
  },
  {
    source: '/contact-us',
    destination: '/about/contact-us',
  },
  {
    source: '/terms-and-conditions',
    destination: '/about/terms-and-conditions',
  },
  {
    source: '/sitemaps/products.xml',
    destination:
      'https://storage.googleapis.com/sproutl-sitemap-prod/products.xml',
  },
  {
    source: '/:path*',
    destination: '/:path*',
  },
];

module.exports = REWRITES;
