import BlockContent from '@sanity/block-content-to-react';
import { createPortableTextComponent } from 'next-sanity';
import { ReactNode } from 'react';

import Button from 'components/common/button';
import { IconListItem } from 'components/common/icon-list-item';
import { TickBullet } from 'components/common/icons';
import { ProseBlockquote } from 'components/common/prose/blockquote';
import { ProseImage } from 'components/common/prose/image';
import { ReferralSignup } from 'components/common/referral-signup';
import SiteLink from 'components/common/site-link';
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Step,
  TitleHeading,
} from 'components/common/text';
import { IStepProps } from 'components/common/text/step';
import theme from 'utils/theme';

import { sanityConfig } from './config';

const HeadingRegex = /^h\d/;
const HeadingReplaceRegex = /[^\d]/g;

// Set up Portable Text serialization
const CTARenderer = ({
  node,
}: {
  node: {
    _key: string;
    _type: 'ctaBlock';
    alignment?: 'left' | 'center' | 'right';
    title?: string;
    url?: string;
  };
}) => {
  if (!node.url || !node.title) {
    return null;
  }

  return (
    <p
      style={{
        margin: 'var(--space-m-xl) 0',
        textAlign: node.alignment || 'center',
      }}
    >
      <SiteLink href={node.url}>
        <Button as="span" variant="primary">
          {node.title}
        </Button>
      </SiteLink>
    </p>
  );
};

const ReferralRenderer = ({
  node,
}: {
  node: { cta: string; error: string };
}) => {
  if (!node.cta) return null;
  return <ReferralSignup {...node} />;
};

const BlockRenderer = (props: any) => {
  const style = (props.node.style || 'normal') as string;

  const stepStyles: Record<string, IStepProps> = {
    large: { step: 2 },
    medium: { step: 1 },
    '-1': { step: -1 },
    small: { step: -2 },
    largeCenter: { step: 2, textAlign: 'center' },
    mediumCenter: { step: 1, textAlign: 'center' },
    center: { step: 0, textAlign: 'center' },
  };

  if (!!stepStyles[style]) {
    return <Step {...stepStyles[style]}>{props.children}</Step>;
  }

  const isCentered = style.startsWith('centerH');
  const isHeading = HeadingRegex.test(style);

  if (isCentered || isHeading) {
    const level = style.replace(HeadingReplaceRegex, '');
    const elementStyle: { textAlign?: 'center' } = {
      textAlign: isCentered ? 'center' : undefined,
    };

    if (level === '1') {
      return <Heading1 style={elementStyle}>{props.children}</Heading1>;
    }
    if (level === '2') {
      return <Heading2 style={elementStyle}>{props.children}</Heading2>;
    }
    if (level === '3') {
      return <Heading3 style={elementStyle}>{props.children}</Heading3>;
    }
    if (level === '4') {
      return <Heading4 style={elementStyle}>{props.children}</Heading4>;
    }

    if (level === '5') {
      return <Heading5 style={elementStyle}>{props.children}</Heading5>;
    }
  }

  if (style === 'titleHeading' || style === 'centerTitleHeading') {
    return (
      <TitleHeading
        style={{
          textAlign: style === 'centerTitleHeading' ? 'center' : undefined,
        }}
      >
        {props.children}
      </TitleHeading>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props);
};

const ListItemRenderer = (props: any) => {
  const { listItem } = props.node;

  if (listItem === 'logo') {
    return <IconListItem>{props.children}</IconListItem>;
  }

  if (listItem === 'tick') {
    return (
      <IconListItem
        iconSize="var(--space-s)"
        space="var(--space-s)"
        icon={<TickBullet />}
      >
        <Step step={-1} as="span">
          {props.children}
        </Step>
      </IconListItem>
    );
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.listItem(props);
};

const ListRenderer = (props: any) => {
  const { type } = props;

  if (type === 'logo') {
    return <ul>{props.children}</ul>;
  }

  if (type === 'tick') {
    return <ul style={{ padding: 0 }}>{props.children}</ul>;
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.list(props);
};

export const markSerializers = {
  colorAnnotation({
    mark,
    children,
  }: {
    mark: { color: string };
    children: ReactNode;
  }) {
    return (
      <span
        style={{
          color: mark.color ? theme.colors[mark.color] : 'inherit',
        }}
      >
        {children}
      </span>
    );
  },
};

export const PortableText = createPortableTextComponent({
  ...sanityConfig,
  // Serializers passed to @sanity/block-content-to-react
  // (https://github.com/sanity-io/block-content-to-react)
  serializers: {
    marks: markSerializers,
    listItem: ListItemRenderer,
    list: ListRenderer,
    types: {
      blockquoteBlock: ProseBlockquote,
      ctaBlock: CTARenderer,
      referBlock: ReferralRenderer,
      block: BlockRenderer,
      image: ProseImage,
    },
  },
});

type TAvailableKeywordReplacementKeys =
  | 'genus name'
  | 'diameter'
  | 'current height'
  | 'current spread'
  | 'referral code';

export const PortableTextWithKeywordReplacement = ({
  content,
  keywords = {},
  renderContainerOnSingleChild = false,
}: {
  content: Sanity.TPortableTextEntry | Sanity.TPortableTextEntry[];
  keywords: Partial<
    Record<TAvailableKeywordReplacementKeys, string | React.ReactNode>
  >;
  renderContainerOnSingleChild?: boolean;
}) => (
  <PortableText
    renderContainerOnSingleChild={renderContainerOnSingleChild}
    blocks={content}
    serializers={{
      listItem: ListItemRenderer,
      list: ListRenderer,
      types: {
        ctaBlock: CTARenderer,
        referBlock: ReferralRenderer,
        block: BlockRenderer,
      },
      marks: {
        ...markSerializers,
        keywordReplacement({
          mark,
        }: {
          // @TODO: Replacement casing (should it be capitalized/lowercasing automatically?)
          mark: { keyword: TAvailableKeywordReplacementKeys };
        }) {
          return keywords[mark.keyword] || '';
        },
      },
    }}
  />
);
