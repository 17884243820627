import { FC } from 'react';

import { Flow } from 'components/common/flow';
import SiteLink from 'components/common/site-link';
import { urlFor } from 'framework/sanity/url-for';

import * as St from './styles';
import { IProseImageProps } from './types';
import { calculateIntrinsicSize } from './utils';

export const ProseImage: FC<IProseImageProps> = ({ node }) => {
  const image =
    urlFor(node).width(800).auto('format').fit('max').quality(80).url() || '';
  if (!image) return null;

  const width = calculateIntrinsicSize(node.hotspot?.width);
  const height = calculateIntrinsicSize(node.hotspot?.height);

  const imageEl = (
    <img
      src={image}
      loading="lazy"
      alt={node.alt || node.asset?.altText || ''}
      width={width}
      height={height}
    />
  );

  return (
    <St.Figure>
      {node.href ? <SiteLink href={node.href}>{imageEl}</SiteLink> : imageEl}
      {(node.credit || node.caption) && (
        <Flow as="figcaption" size="xs">
          {node.credit && <St.Credit>{node.credit}</St.Credit>}
          {node.caption && <span>{node.caption}</span>}
        </Flow>
      )}
    </St.Figure>
  );
};
