import styled from 'styled-components';

import { ButtonEl } from 'components/common/button/styles';
import { FlowXFlex } from 'components/common/flow';
import theme from 'utils/theme';

export const Code = styled.button`
  background: ${theme.colors.dahliaPink};
  display: inline-block;
  padding: var(--space-xs);
  font-weight: ${theme.weights.bold};
  cursor: pointer;
`;

export const ButtonWrapper = styled(FlowXFlex)`
  a {
    width: 50%;
    flex-shrink: 0;

    ${ButtonEl} {
      height: 100%;
    }

    svg {
      display: block;
      margin-left: var(--space-2xs);
    }
  }
`;

export const Footer = styled.footer`
  margin-top: var(--space-xs);

  ~ div > * {
    margin: 0;

    + * {
       {
        margin-top: var(--space-xs);
      }
    }
  }
`;
