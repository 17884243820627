import {
  LocalizedString,
  Price,
  ProductVariantChannelAvailabilityMap,
} from '@commercetools/platform-sdk';

import { getVariantBestOffer } from 'framework/commercetools/utils';
import {
  LineItemDataFragment,
  ProductAvailabilityDataFragment,
} from 'generated/api/graphql';

type TProductUrlArgs =
  | { sproutlSku?: never; sku: string; slug: string }
  | { sproutlSku: string; sku?: never; slug: string };

export function getProductUrl(product: TProductUrlArgs): string {
  return `/products/${product.slug}-${product.sku || product.sproutlSku}`;
}

/**
 * TODO - Depricate this when products are mapped through the SDK
 */
export function pickBestOfferGraphQL(product: ProductAvailabilityDataFragment) {
  let isOnStock = false;
  let availableQuantity = 0;

  const formattedAvailability = product.availability?.channels.results.reduce(
    (running, result): ProductVariantChannelAvailabilityMap => {
      if (!result.channel?.id) return running;
      if (result.availability.isOnStock) {
        isOnStock = true;
        availableQuantity += result.availability.availableQuantity;
      }

      return {
        ...running,
        [result.channel.id]: {
          isOnStock: result.availability.isOnStock,
          availableQuantity: result.availability.availableQuantity,
        },
      };
    },
    {} as ProductVariantChannelAvailabilityMap,
  );

  const formattedPrices: Price[] | undefined = product.prices?.map((price) => {
    return {
      id: price.id || '',
      value: {
        type: 'centPrecision',
        currencyCode: 'GBP',
        ...price.value,
      },
      channel: {
        id: price.channel?.id || '',
        typeId: 'channel',
      },
    };
  });

  return getVariantBestOffer({
    availability: {
      isOnStock,
      availableQuantity,
      channels: formattedAvailability,
    },
    prices: formattedPrices,
  });
}

export function getStockAvailabilityFromItem(item: LineItemDataFragment) {
  const channelKey = item.distributionChannel?.key;

  const itemAvailability = item.variant?.availability?.channels.results.find(
    (prodItem) => prodItem.channel?.key === channelKey,
  );

  return (
    itemAvailability?.availability || {
      isOnStock: false,
      availableQuantity: 0,
    }
  );
}

interface ICalculatePotVolumeProps {
  height?: number;
  diameterTop?: number;
  width?: number;
  length?: number;
  volume?: number;
  shape?: string;
}

export function calculatePotVolume(details: ICalculatePotVolumeProps): number {
  const { height, diameterTop, width, length, volume, shape } = details;

  if (volume) return volume;

  if (
    shape &&
    height &&
    width &&
    length &&
    ['Square', 'Cube', 'Trough'].includes(shape)
  ) {
    return (height * width * length) / 1000;
  }

  if (
    shape &&
    height &&
    (diameterTop || width) &&
    length &&
    ['Bowl'].includes(shape)
  ) {
    return (
      (0.5 * Math.PI * Math.pow((diameterTop || width || 1) / 2, 2) * height) /
      1000
    );
  }

  const diameter = diameterTop || length;
  if (height && diameter) {
    const radius = diameter / 2;
    return (Math.PI * (radius * radius) * height) / 1000;
  }

  return 0;
}

export function ctLocalizedString(string?: LocalizedString): string {
  return string ? string['en-GB'] || string[Object.keys(string)[0]] || '' : '';
}
