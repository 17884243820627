import {
  ArticlesMetaQuery,
  BillboardQuery,
  BodyFragment,
  EmailSubscribeQuery,
  FilterableGridFragment,
  HeroTwoQuery,
  KitBlockFragment,
  LabelsQuery,
  LatestArticlesQuery,
  ProductsMetaQuery,
  ShortcutsQuery,
} from '.';

export const BlocksInnerFragment = `
  ...,
  "tabs": tabs[]{
    ...,
    ${ProductsMetaQuery},
  },
  ${ShortcutsQuery},
  ${ArticlesMetaQuery},
  ${LabelsQuery},
  ${LatestArticlesQuery},
  ${HeroTwoQuery},
  ${BillboardQuery},
  ${EmailSubscribeQuery},
  ${KitBlockFragment},
  ${FilterableGridFragment},
  ${BodyFragment}
`;
