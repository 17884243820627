import styled from 'styled-components';

import theme from 'utils/theme';

import { ICustomSectionSettings } from './types';

export interface SectionProps extends React.ComponentPropsWithRef<'section'> {
  bg?: 'light' | 'dark' | 'normal' | 'irisPurple' | 'purpleChalk' | null;
  padding?: 'none' | 'small' | 'medium';
  fullBleed?: boolean;
  styles?: ICustomSectionSettings;
}

const bgColors = {
  irisPurple: theme.colors.irisPurple,
  purpleChalk: theme.colors.purpleChalk,
  light: theme.colors.white,
  normal: theme.colors.offWhite,
  dark: theme.colors.darkBg,
};

const paddingSizes = {
  none: 0,
  tiny: 'var(--space-s-m)',
  small: 'var(--space-m-2xl)',
  medium: 'var(--space-2xl-3xl)',
};

const BaseSection = styled.section<SectionProps>`
  background-color: ${({ bg }) => (bg ? bgColors[bg] : undefined)};
  padding: ${({ padding = 'medium' }) =>
      padding ? paddingSizes[padding] : '0'}
    0;
`;

const ThemedSection = styled.section<{
  background: Sproutl.ColorScheme;
  text: Sproutl.ColorScheme;
  topPadding: string | number;
  bottomPadding: string | number;
}>`
  background-color: ${({ background }) => theme.colors[background]};
  color: ${({ text }) => theme.colors[text]};
  padding-bottom: ${({ bottomPadding }) => bottomPadding};
  padding-top: ${({ topPadding }) => topPadding};
`;

const FullBleedSection = styled(BaseSection)`
  & + & {
    margin-top: var(--space-s-l);
  }
`;

const LightSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const PurpleChalkSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const DefaultSection = styled(BaseSection)`
  & + & {
    padding-top: 0;
  }
`;

const Section = (props: SectionProps) => {
  let Comp = DefaultSection;

  if (props.styles) {
    return (
      <ThemedSection
        {...{
          ...props,
          background: props.styles.background || 'white',
          text: props.styles.text || 'black',
          topPadding: paddingSizes[props.styles.topPadding || 'none'],
          bottomPadding: paddingSizes[props.styles.bottomPadding || 'none'],
        }}
      />
    );
  }

  if (props.fullBleed) {
    Comp = FullBleedSection;
  } else if (props.bg === 'light') {
    Comp = LightSection;
  } else if (props.bg === 'purpleChalk') {
    Comp = PurpleChalkSection;
  }

  return <Comp {...props} />;
};

export default Section;
