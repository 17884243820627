import { ProductProjection } from '@commercetools/platform-sdk';

import { ctClient } from 'framework/commercetools/api';

async function getProjectionForSkus(
  sku: string | string[],
): Promise<ProductProjection[]> {
  const skusToFetch = Array.isArray(sku)
    ? sku.map((sku) => `"${sku}"`).join(',')
    : `"${sku}"`;

  return ctClient
    .productProjections()
    .search()
    .get({
      queryArgs: {
        expand: [
          'variants[*].prices[*].channel',
          'masterVariant.prices[*].channel',
        ],
        filter: [`variants.sku:${skusToFetch}`],
        limit: 200,
        markMatchingVariants: true,
        staged: false,
      },
    })
    .execute()
    .then((res: any) => res.body.results);
}

export default getProjectionForSkus;
