export interface IReferralPopupProps {
  email: string;
  initialState?: ReferralState;
  initialReferralCode?: string;
  isReferralModalOpen: boolean;
  setIsReferralModalOpen: (state: boolean) => void;
}

export enum ReferralState {
  idle = 'Spread the word',
  error = 'There was an error',
  generating = 'Generating your code',
  share = 'Share code',
  copied = 'Code copied',
}
