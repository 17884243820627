import { MultipleQueriesQuery } from '@algolia/client-search';
import striptags from 'striptags';

import {
  ProductDataInterface,
  TPDPAdditionalProducts,
  TPDPAdditionalSection,
} from 'components/layouts/product/types';
import { formatAttributeLabel } from 'components/product/variant-switcher/helpers';
import { TPartnerGiftMessagePermissions } from 'contexts';
import multiindexSearch from 'framework/algolia/api/multiindex-search';
import {
  getKitProducts,
  getRecommendedProducts,
  getSimilarProducts,
} from 'framework/algolia/api/multiindex-search/queries';
import { mapProjectionsToProductTile } from 'framework/commercetools/utils';
import { IProductMetaWithVariants } from 'types/product';
import { NormalizedAttributes } from 'utils/commercetools/types';
import {
  IAttributeDefinition,
  IAttributeDefinitionWithValue,
  IKit,
} from 'utils/types';

import { GetBreadcrumbObjArgs, BreadcrumbObj } from './types';

export const getBreadcrumbs = ({
  categoryTree,
  categorySlug,
}: GetBreadcrumbObjArgs): BreadcrumbObj[] => {
  if (!categoryTree || !categorySlug) {
    return [];
  }

  const categoryLabelsArray = categoryTree.split('///');
  const categorySlugArray = categorySlug.split('/');

  return categoryLabelsArray.reduce(
    (accBreadcrumbArray, currentLabel, labelIndex): BreadcrumbObj[] => {
      if (labelIndex + 1 === categoryLabelsArray.length) {
        // Reached the end of the array - concatenate all the slugs ...
        return [
          ...accBreadcrumbArray,
          { label: currentLabel, slug: categorySlugArray.join('/') },
        ];
      }

      // Concatenate the previous slugs into this one ...
      return [
        ...accBreadcrumbArray,
        {
          label: currentLabel,
          slug: categorySlugArray.slice(0, labelIndex + 1).join('/'),
        },
      ];
    },
    [] as BreadcrumbObj[],
  );
};

export function getGiftMessageAvailability(
  permissions?: TPartnerGiftMessagePermissions[],
  partners?: Sproutl.Partner[] | Sproutl.Partner | null,
): boolean {
  if (!permissions || !partners) return false;

  const internalPartners = Array.isArray(partners) ? partners : [partners];

  const permittedSlugs = permissions
    .filter(({ hasGiftMessaging }) => !!hasGiftMessaging)
    .flatMap(({ slug, ctKey }) => [slug, ctKey]);

  return internalPartners.some(({ slug }) => permittedSlugs.includes(slug));
}

// SKU checker as the partner isn't supplied for OOS products :(
export function isAKewPalmHouseSku(sku: string) {
  return [
    'SPRTL153623',
    'SPRTL153624',
    'SPRTL153621',
    'SPRTL153627',
    'SPRTL153622',
    'SPRTL153625',
    'SPRTL153626',
  ].includes(sku);
}

/**
 * Append variable attributes to the page title
 */
export function getVariantPageTitle(
  productData: Pick<
    ProductDataInterface,
    'name' | 'variableAttributeDefinitions'
  >,
  space?: string,
): string {
  return [
    productData.name,
    productData.variableAttributeDefinitions
      .map((attribute) =>
        formatAttributeLabel({ attribute, hideTitle: true, space }),
      )
      .join(' / '),
  ]
    .join(' ')
    .trim();
}

export function isCompostCompatible(attributes: NormalizedAttributes): boolean {
  const category = attributes.category_slug;
  const hasShape = attributes.shape;
  const isOutdoorPot = category === 'pots/outdoor-pots/pots';
  const isOutdoorPlant =
    category?.startsWith('plants') &&
    !category.startsWith('plants/houseplants');

  return !!isOutdoorPlant || (!!isOutdoorPot && !!hasShape);
}

export function firstParagraph(text: string = ''): string {
  const firstParagraphRegex = /<p>(.*?)<\/p>/;
  const matchingText = text.match(firstParagraphRegex);
  const cleanedText = striptags(matchingText?.[0] || '').trim();

  return cleanedText;
}

export function isAttributeVariable(
  attr: string,
  variationGroup: string,
): boolean {
  const internalAttr =
    {
      height_current: 'current_height',
      diameter_top: 'diameter',
      length_metres: 'metres',
    }[attr] || attr;

  return variationGroup.includes(internalAttr);
}

export function extractVariableAttributeKeys(
  attributes: NormalizedAttributes,
): string[] {
  let variationGroupCode = attributes.variation_group;
  if (!variationGroupCode) return [];

  const variableAttributeKeys: string[] = [];
  Object.keys(attributes)
    .sort((a, b) => b.length - a.length)
    .forEach((key) => {
      const groupCodeKey =
        {
          height_current: 'current_height',
          diameter_top: 'diameter',
          length_metres: 'metres',
        }[key] || key;

      if (variationGroupCode.includes(groupCodeKey)) {
        // Remove the key to prevent, say, height & current_height both matching
        variationGroupCode = variationGroupCode.replace(groupCodeKey, '');
        variableAttributeKeys.push(key);
      }
    });

  return variableAttributeKeys;
}

const sortedPDPAttributes = [
  'height_current',
  'diameter_top',
  'length',
  'height',
];

export function extractVariableAttributes(
  attributes: NormalizedAttributes,
  definitions: IAttributeDefinition[],
): IAttributeDefinitionWithValue[] {
  return extractVariableAttributeKeys(attributes)
    .map((key) => {
      const value = attributes[key];
      const definition = definitions.find(
        ({ slug: { current } }) => current === key,
      );

      if (value === undefined || !definition) return null;

      return {
        ...definition,
        value,
      };
    })
    .filter(
      (definition): definition is IAttributeDefinitionWithValue => !!definition,
    )
    .sort((a, b) => {
      const aIndex = sortedPDPAttributes.indexOf(a.slug.current);
      const bIndex = sortedPDPAttributes.indexOf(b.slug.current);

      return (aIndex !== -1 ? aIndex : 100) - (bIndex !== -1 ? bIndex : 100);
    });
}

export const isBulb = (category?: string) => {
  return category?.startsWith('bulbs') || category?.endsWith('bulbs');
};

export const fetchPDPAdditionals = async ({
  fullProductData,
  kit,
  attributes,
  partnerSlug,
  isInStock,
}: {
  fullProductData: ProductDataInterface;
  isInStock: boolean;
  kit: IKit | null;
  attributes: NormalizedAttributes;
  partnerSlug?: string;
}) => {
  // Set out queries
  const queries: Record<TPDPAdditionalSection, MultipleQueriesQuery[]> = {
    similar: [],
    recommended: [],
    kit: [],
  };

  // Prepare similar products query
  queries.similar = getSimilarProducts({
    maximumProductCount: 10,
    productData: fullProductData,
  });

  // Prepare recommended products query
  if (isInStock) {
    queries.recommended = getRecommendedProducts(
      attributes,
      fullProductData.projection.productWithBestOffer.bestOffer,
    );
  }

  // Prepare kit query
  if (kit) {
    const collectionGroups = attributes?.collection_group || [];
    queries.kit = getKitProducts(
      kit.pimId,
      kit.groupIds.filter((id) => !collectionGroups.includes(id)),
      partnerSlug,
    );
  }

  // Make the Algolia search
  const response = await multiindexSearch<IProductMetaWithVariants>(
    Object.values(queries).flatMap((queries) => queries),
  );

  // Extract the products from the response
  const results: TPDPAdditionalProducts = {
    similar: [],
    recommended: [],
    kit: [],
  };

  if (queries.similar.length) {
    results.similar = response.shift()?.hits || [];
  }

  if (queries.recommended.length) {
    results.recommended = response.shift()?.hits || [];
  }

  if (queries.kit.length) {
    results.kit = [
      mapProjectionsToProductTile([fullProductData.projection])[0],
      ...response.flatMap(({ hits }) => hits),
    ];
  }

  return results;
};
