import React, { Ref } from 'react';

import LoadingText from 'components/common/loading-text';

import { ButtonEl, ChildrenWrapper } from './styles';
import { ButtonProps } from './types';

function ButtonComponent(
  {
    id,
    as,
    name,
    loading,
    onClick,
    children,
    disabled,
    selected,
    colorScheme,
    width = 'auto',
    type = 'button',
    variant = 'primary',
    loadingText = 'Loading',
    'data-testid': dataTestId,
    ...rest
  }: ButtonProps,
  ref: Ref<HTMLButtonElement | undefined>,
) {
  let defaultColorScheme = undefined;

  switch (variant) {
    case 'primary':
      defaultColorScheme = 'black';
      break;
    case 'secondary':
      defaultColorScheme = 'black';
      break;
    case 'small':
      defaultColorScheme = 'beige';
      break;
    case 'link':
      defaultColorScheme = 'green';
      break;
    default:
      break;
  }

  return (
    <ButtonEl
      {...rest}
      ref={ref}
      as={as}
      id={id}
      name={name}
      width={width}
      onClick={onClick}
      variant={variant}
      $loading={loading}
      selected={selected}
      type={as ? null : type}
      data-testid={dataTestId}
      aria-selected={selected}
      disabled={loading || disabled}
      aria-disabled={loading || disabled}
      colorScheme={colorScheme || defaultColorScheme}
    >
      <LoadingText text={loadingText} />
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </ButtonEl>
  );
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ButtonComponent,
);

export default Button;
