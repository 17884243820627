import styled, { css } from 'styled-components';
import { createGlobalStyle } from 'styled-components';

import Container from 'components/common/container';
import SiteLink from 'components/common/site-link';
import { desktop, tablet } from 'utils/media';
import theme from 'utils/theme';

import { DESKTOP_NAV_HEIGHT, MOBILE_NAV_HEIGHT } from './constants';

export const GlobalHeaderStyle = createGlobalStyle`
  html,
  body {
    scroll-padding-top: ${MOBILE_NAV_HEIGHT}px;
  }

  ${desktop} {
    html,
    body {
      scroll-padding-top: ${DESKTOP_NAV_HEIGHT}px;
    }
  }
`;

export const RelativeWrapper = styled.div`
  position: relative;
`;

export const Background = styled.nav`
  background-color: ${theme.colors.white};
  position: relative;
  z-index: ${theme.zIndexes.header};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${MOBILE_NAV_HEIGHT}px;

  ${desktop} {
    height: ${DESKTOP_NAV_HEIGHT}px;
  }
`;

export const StyledContainer = styled(Container)`
  position: static;
  display: flex;
  padding: 0 6px 0 var(--site-gutter);
  align-items: center;
  width: 100%;
  height: 100%;

  ${tablet} {
    padding-right: var(--space-s);
  }

  ${desktop} {
    padding-right: var(--site-gutter);
    width: 100%;
    max-width: ${theme.pageWidth.extended}px;
  }
`;

export const LogoLink = styled.a`
  display: flex;
  align-items: center;
  color: ${theme.colors.irisPurple};

  svg {
    height: 20px;
    width: auto;

    ${desktop} {
      height: 26px;
    }
  }
`;

export const HeaderNavLinks = styled.ul`
  align-items: center;
  display: none;
  justify-content: flex-start;
  list-style-type: none;
  margin: 0 var(--site-gutter);
  padding: var(--space-3xs) 0 0; // Optical adjustment
  flex-shrink: 0.1;

  li + li {
    margin-left: var(--space-s-l);
  }

  li > * {
    font-family: ${theme.fonts.heading};
    padding: var(--space-xs) 0;
    position: relative;
    font-size: var(--step-1);

    &:hover,
    &:focus {
      color: ${theme.colors.irisPurple};
      text-decoration: underline;
    }

    /* Wide, invisible cover to allow the mouse to move down/across without triggering the next nav item */
    &:hover:after {
      content: '';
      position: absolute;
      height: var(--space-2xl);
      right: calc(-1 * var(--space-xl));
      left: calc(-1 * var(--space-xl));
      z-index: ${theme.zIndexes.low};
    }
  }

  ${desktop} {
    display: flex;
  }
`;

interface IHeaderNavButtonProps {
  active: boolean;
}

export const HeaderNavButton = styled.button<IHeaderNavButtonProps>`
  ${({ active }) =>
    active &&
    css`
      color: ${theme.colors.irisPurple};
      text-decoration: underline;
    `}
`;

export const HamburgerWrapper = styled.button`
  width: ${MOBILE_NAV_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  color: inherit;

  ${desktop} {
    display: none;
  }
`;

export const IconLink = styled(SiteLink)`
  color: ${theme.colors.black};
`;

export const IconPlainLink = styled.a`
  color: ${theme.colors.black};
`;

export const IconButton = styled.button`
  width: ${MOBILE_NAV_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    pointer-events: none;
  }

  ${desktop} {
    width: var(--space-xl-4xl);
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  ${desktop} {
    flex-shrink: 0;
  }
`;

export const SearchWrapper = styled.div`
  margin-left: auto;
  flex-shrink: 1;

  ${tablet} {
    max-width: 430px;
    flex-basis: 35vw;
    min-width: 220px;
    margin-right: auto;
  }
`;
