import styled from 'styled-components';

import { Flow } from 'components/common/flow';
import { Heading3 } from 'components/common/text';
import theme from 'utils/theme';

export const Advert = styled(Flow)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;

  :hover p {
    color: ${theme.colors.irisPurple};
  }

  a:after {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    z-index: ${theme.zIndexes.low};
    content: '';
  }

  figure {
    width: 100%;
  }

  p {
    margin: 0;
    text-decoration: underline;
  }

  ${Heading3} {
    font-size: var(--step-1);

    a {
      color: inherit;
    }
  }
`;
