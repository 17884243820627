import React from 'react';

import * as St from './styles';
import { ILabelProps } from './types';

export const Label = ({ children, required, ...props }: ILabelProps) => (
  <St.Label {...props}>
    {children}
    {required && <St.Asterisk title="This field is required">*</St.Asterisk>}
  </St.Label>
);

export default Label;
