import { createApiBuilderFromCtpClient } from '@commercetools/platform-sdk';
import {
  ClientBuilder,
  createAuthForClientCredentialsFlow,
  createHttpClient,
} from '@commercetools/sdk-client-v2';

const projectKey = process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY || '';

const authMiddlewareOptions = {
  host: 'https://auth.europe-west1.gcp.commercetools.com',
  projectKey,
  credentials: {
    clientId: process.env.NEXT_PUBLIC_COMMERCETOOLS_BASIC_CLIENT_ID || '',
    clientSecret:
      process.env.NEXT_PUBLIC_COMMERCETOOLS_BASIC_CLIENT_SECRET || '',
  },
  scopes: [
    `view_products:${projectKey}`,
    `view_published_products:${projectKey}`,
  ],
};

const httpMiddlewareOptions = {
  host: 'https://api.europe-west1.gcp.commercetools.com',
};

const ctClientBuilder = new ClientBuilder()
  .withProjectKey(projectKey)
  .withMiddleware(createAuthForClientCredentialsFlow(authMiddlewareOptions))
  .withMiddleware(createHttpClient(httpMiddlewareOptions))
  .withUserAgentMiddleware()
  .build();

const ctClient = createApiBuilderFromCtpClient(ctClientBuilder).withProjectKey({
  projectKey,
});

export default ctClient;
