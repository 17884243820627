import React from 'react';

export default function CardPaymentMethods() {
  return (
    <svg
      width="155"
      height="40"
      viewBox="0 0 155 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M43.03 13.45h-2.62c-.79 0-1.4.25-1.76 1.04l-5 11.39h3.53s.61-1.52.73-1.89h4.33c.12.43.43 1.83.43 1.83h3.16l-2.8-12.37zm-4.14 7.98c.3-.73 1.34-3.47 1.34-3.47 0 .06.3-.73.43-1.16l.24 1.1s.67 2.98.8 3.6h-2.81v-.07zm-5 .37c0 2.56-2.31 4.26-5.9 4.26a10 10 0 0 1-3.78-.67l.48-2.8.43.18a6.7 6.7 0 0 0 3.17.67c.97 0 2-.36 2-1.22 0-.54-.42-.9-1.76-1.52-1.28-.6-2.98-1.58-2.98-3.35 0-2.44 2.37-4.08 5.72-4.08 1.28 0 2.38.24 3.05.55l-.49 2.68-.24-.25a6.55 6.55 0 0 0-2.56-.48c-1.28.06-1.89.6-1.89 1.1 0 .54.73.97 1.9 1.52 1.94.91 2.85 1.95 2.85 3.4z"
        fill="#3362AB"
      />
      <path
        d="m4.17 13.57.06-.24h5.24c.73 0 1.27.24 1.46 1.04l1.16 5.48c-1.16-2.93-3.84-5.3-7.92-6.28z"
        fill="#F9B50B"
      />
      <path
        d="m19.46 13.45-5.3 12.37h-3.6L7.52 15.46c2.19 1.4 4.02 3.6 4.69 5.12l.36 1.28 3.3-8.47h3.59v.06zm1.4-.06h3.35l-2.13 12.43h-3.35l2.13-12.43z"
        fill="#3362AB"
      />
      <path
        d="m85.22 9.8-10.76.04.32 19.7 10.76-.04-.32-19.7z"
        fill="#FF5F00"
      />
      <path
        d="M75.17 19.73a12.46 12.46 0 0 1 4.54-9.88 12.32 12.32 0 0 0-7.67-2.66 12.25 12.25 0 0 0-12.15 12.59 12.64 12.64 0 0 0 12.57 12.5 12.03 12.03 0 0 0 7.57-2.71 12.78 12.78 0 0 1-4.86-9.84z"
        fill="#EB001B"
      />
      <path
        d="M100.11 19.56a12.25 12.25 0 0 1-12.15 12.58c-2.88.01-5.54-1-7.66-2.66a12.37 12.37 0 0 0 4.53-9.87 12.83 12.83 0 0 0-4.86-9.84 12.03 12.03 0 0 1 7.57-2.71 12.67 12.67 0 0 1 12.57 12.5z"
        fill="#F79E1B"
      />
      <path
        d="M117.5 5.62h35a2.5 2.5 0 0 1 2.5 2.5v23.76a2.5 2.5 0 0 1-2.5 2.5h-35a2.5 2.5 0 0 1-2.5-2.5V8.13a2.5 2.5 0 0 1 2.5-2.5z"
        fill="#26A6D1"
      />
      <path
        d="m121.45 15.62-3.95 8.75h4.73l.58-1.4h1.34l.6 1.4h5.2V23.3l.46 1.07h2.7l.46-1.1v1.1h10.82l1.32-1.36 1.23 1.36h5.56l-3.96-4.35 3.96-4.4h-5.47l-1.28 1.34-1.2-1.34h-11.77l-1.02 2.26-1.03-2.26H126v1.03l-.52-1.03h-4.04zm.92 1.25h2.3l2.62 5.92v-5.92h2.53l2.02 4.25 1.86-4.25h2.52v6.27h-1.53l-.01-4.92-2.23 4.92h-1.37l-2.24-4.92v4.92h-3.15l-.6-1.4h-3.21l-.6 1.4h-1.68l2.77-6.27zm15.25 0h6.21l1.9 2.05 1.97-2.05h1.9l-2.9 3.15 2.9 3.11h-2l-1.9-2.07-1.96 2.07h-6.13v-6.26zm-14.13 1.06-1.06 2.5h2.12l-1.06-2.5zm15.66.23v1.15h3.4v1.28h-3.4v1.25h3.8l1.77-1.85-1.7-1.83h-3.87z"
        fill="#fff"
      />
    </svg>
  );
}
