import { pickBy } from 'lodash';

import { AddressFields } from './checkout/types';

const defaultAddressAttrKeys: Array<keyof AddressFields> = [
  'firstName',
  'lastName',
  'streetName',
  'additionalStreetInfo',
  'city',
  'postalCode',
  'phone',
  'email',
  'country',
];

export const defaultAddressAttrs: AddressFields = {
  firstName: '',
  lastName: '',
  streetName: '',
  additionalStreetInfo: '',
  city: '',
  postalCode: '',
  phone: '',
  email: '',
  country: 'GB',
};

export const getAddressFromObject = (address: any) => {
  return {
    ...defaultAddressAttrs,
    ...pickBy(
      address,
      (value, key) =>
        value !== null &&
        defaultAddressAttrKeys.includes(key as keyof AddressFields),
    ),
  };
};
