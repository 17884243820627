import React from 'react';

export default function Door() {
  return (
    <svg
      viewBox="0 0 51 91"
      height="120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4 7.5a25.3 25.3 0 0 1 43.3 18V91H0V25.5c0-6.8 2.7-13.3 7.4-18Zm1.5 44a2.2 2.2 0 1 0 0-4.3 2.2 2.2 0 0 0 0 4.3Z"
        fill="#000"
      />
    </svg>
  );
}
