import 'styles/reset.css';
import 'styles/utopia.css';
import 'styles/global.css';

import { ApolloProvider } from '@apollo/client/react';
import { UserProvider } from '@auth0/nextjs-auth0';
import cookies from 'cookies';
import { NextPage } from 'next';
import { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import React, { ReactElement, ReactNode, useEffect } from 'react';

import { useCookieAccepted } from 'components/common/cookie-banner/utils';
import { getDefaultLayout } from 'components/layouts/default';
import { ISettings, Settings } from 'contexts';
import { RecentlyViewedProvider } from 'contexts/recently-viewed';
import { filterDataToSingleItem } from 'framework/sanity/filter-data-to-single-item';
import { siteGlobalsQuery } from 'framework/sanity/queries';
import { getClient } from 'framework/sanity/sanity-client';
import { IntegrationsStyles } from 'styles/integrations';
import { findCookieFromReq, prepareAssignments } from 'utils/ab-testing';
import { COOKIE_NAME } from 'utils/ab-testing/constants';
import { useApollo } from 'utils/apollo-client';
import { useSetFeatureFlags } from 'utils/featureFlags';
import { useGtm } from 'utils/gtm/hooks';
import theme from 'utils/theme';
import {
  IAttributeDefinition,
  IPublicPartnerSettings,
  ISiteGlobals,
} from 'utils/types';
import {
  useZendeskChatSuppress,
  ZENDESK_SUPPRESS_PATHS,
} from 'utils/zendesk/hooks';

interface MyAppProps extends AppProps {
  props: {
    settings: ISettings;
    siteGlobals: ISiteGlobals;
  };
  Component: NextPage & {
    getLayout?: (page: ReactElement, siteGlobals: ISiteGlobals) => ReactNode;
  };
}

function MyApp({ Component, pageProps, props }: MyAppProps) {
  const apolloClient = useApollo(pageProps);

  useGtm(props.settings.abTests);

  useSetFeatureFlags();

  useZendeskChatSuppress(ZENDESK_SUPPRESS_PATHS);

  const hasCookieAccepted = useCookieAccepted();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || getDefaultLayout;

  useEffect(() => {
    // Clear the Algolia cache at load so we can properly handle pagination
    sessionStorage.removeItem('ais.infiniteHits');
  }, []);

  return (
    <UserProvider>
      <ApolloProvider client={apolloClient}>
        <Settings.Provider value={props.settings}>
          <RecentlyViewedProvider>
            <IntegrationsStyles cookieAccepted={hasCookieAccepted} />
            <NextNProgress
              color={theme.colors.pink}
              options={{ showSpinner: false }}
            />
            {getLayout(<Component {...pageProps} />, props.siteGlobals)}
          </RecentlyViewedProvider>
        </Settings.Provider>
      </ApolloProvider>
    </UserProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const [appProps, data] = await Promise.all([
    App.getInitialProps(appContext),
    getClient().fetch(siteGlobalsQuery),
  ]);

  const siteGlobals = filterDataToSingleItem(data.siteGlobals);
  const partners = filterDataToSingleItem(data.partners);
  const zendesk = filterDataToSingleItem(data.zendesk);
  const trustpilot = filterDataToSingleItem(data.trustpilot);
  const supplementaryContent = filterDataToSingleItem(
    data.supplementaryContent,
  );
  const emailPopup = filterDataToSingleItem(data.emailPopup);
  const addonSettings = filterDataToSingleItem(data.addonSettings);
  const featureFlags = filterDataToSingleItem(data.featureFlags);
  const publicPartners = filterDataToSingleItem<IPublicPartnerSettings>(
    data.publicPartners,
  );

  // AB testing cookies
  const currentCookie = findCookieFromReq(appContext?.ctx?.req);
  const [newCookie, abTests] = prepareAssignments(currentCookie);

  const needsCookieSet =
    (!!newCookie || !!currentCookie) && newCookie !== currentCookie;
  const isPrebuiltErrorPage = !['/404', '/500'].includes(
    appContext.router.route,
  );

  // Set the cookie if it's different to the current cookie
  if (
    isPrebuiltErrorPage &&
    needsCookieSet &&
    appContext.ctx.req &&
    appContext.ctx.res
  ) {
    const expires = new Date();
    expires.setFullYear(expires.getFullYear() + 1);

    new cookies(appContext.ctx.req, appContext.ctx.res).set(
      COOKIE_NAME,
      newCookie,
      {
        path: '/',
        expires,
      },
    );
  }

  const attributeDefinitions: IAttributeDefinition[] = [
    ...(data?.attributeDefinitions || []),
  ];
  attributeDefinitions.sort((a, b) => a.order - b.order);

  return {
    ...appProps,
    props: {
      siteGlobals,
      settings: {
        partners: {
          ...partners,
          publicPartners: publicPartners?.partners || [],
        },
        zendesk,
        trustpilot,
        supplementaryContent,
        emailPopup,
        abTests,
        addonSettings,
        featureFlags,
        attributeDefinitions: data?.attributeDefinitions || [],
      },
    },
  };
};

export default MyApp;
