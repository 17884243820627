import jsCookie from 'js-cookie';
import { each } from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const defaultValues: Record<string, boolean> = {
  express_pay: true,
  gift_message: true,
  login_button: true,
  pdp_v2: true,
  aftercare: true,
  collections: true,
};

const OFF_VALUES = ['off', 'false', 'hide', '0'];

/**
 * Global hook to set any feature flag regardless of what page the user is on
 */
export function useSetFeatureFlags() {
  const router = useRouter();

  useEffect(() => {
    // Go through each query string param
    each(router.query, (value, param) => {
      // Check the value is not an array and the param is a feature flag
      if (typeof value === 'string' && param.startsWith('feature_')) {
        // Check the value isn't switching the feature flag off
        if (OFF_VALUES.indexOf(value) > -1) {
          // Remove the feature flag cookie
          jsCookie.remove(param);
        } else {
          // Set the feature flag cookie
          jsCookie.set(param, 'enabled', {
            path: '/',
            expires: 365,
          });
        }
      }
    });
  }, [router.query]);
}

/**
 * Hook to check the feature flag in a specific area
 * @param {string} key The feature flag key
 * @returns {boolean}
 */
export function useFeatureFlag(key: string): boolean {
  const [state, setState] = useState(
    key in defaultValues ? defaultValues[key] : false,
  );

  const router = useRouter();

  useEffect(() => {
    if (key in defaultValues) {
      return setState(defaultValues[key]);
    }

    return setState(!!jsCookie.get(`feature_${key}`));
  }, [key, router.query]);

  return state;
}
