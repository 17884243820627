import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';
import { ContentBlocksFragment } from './fragments/contentBlocks';
import { KitBlockFragment } from './fragments/kit';
import { PLPHeaderLinks } from './fragments/plpHeaderLinks';

export const categoryPageQuery = groq`
  {
    "categoryDetails": *[_type == "category" && $category == slug.current] {
      ...,
      "title": name,
      "image": image {
        ...,
        asset->
      },
      ${PLPHeaderLinks},
      "parents": parents[]->{
        name,
        parents[]->{
          name,
          parents[]->{
            name
          }
        },
      },
      ${KitBlockFragment},
      ${ContentBlocksFragment},
      "plpBlocksContent": plpBlocksContent.blocks[] {
        ${BlocksInnerFragment}
      }
    }
  }
`;
