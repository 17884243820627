import { useUser } from '@auth0/nextjs-auth0';
import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import { escapeRegExp } from 'lodash';

import {
  CartDataFragment,
  CartUpdateAction,
  CreateCartDocument,
  CreateCartMutation,
  CreateCartMutationVariables,
  GetCartByIdDocument,
  GetCartByIdQuery,
  GetCartByIdQueryVariables,
  LineItemDataFragment,
  MyCartDocument,
  MyCartQuery,
  MyCartQueryVariables,
  MyCartUpdateAction,
  UpdateCartDocument,
  UpdateCartMutation,
  UpdateCartMutationVariables,
  UpdateMyCartDocument,
  UpdateMyCartMutation,
  UpdateMyCartMutationVariables,
  useMyCartQuery,
} from 'generated/api/graphql';
import { fallbackApolloClient, initializeApollo } from 'utils/apollo-client';
import { normalizeCustomFields } from 'utils/helpers';

import { getStockAvailabilityFromItem } from './product';
import { useHasAnonymousToken } from './tokens/anonymous-token';
import {
  AddBillingToCartProps,
  AddedLineItem,
  AddPaymentToCartProps,
  GetGroupedActiveCartOrCartByIdProps,
  GetGroupedCartByIdProps,
  GetGroupedActiveCartProps,
  IChannelGroup,
  IGroupedActiveCart,
  IGroupedCart,
  RecalculateMyCartProps,
  IGetVariantAttributesRawBySkuArgs,
  IAddShippingToCartProps,
} from './types';

export const SPROUTL_ORDER = 'sproutl-order';
export const MARKETING_OPT_IN = 'marketingOptIn';
export const GIFT_MESSAGE = 'giftMessage';
export const ORIGIN = 'origin';
export const SPROUTL_LINE_ITEM = 'sproutl-lineitem';

export async function getActiveCart(): Promise<CartDataFragment> {
  const apolloClient = initializeApollo();

  // @todo change this to use useMyCartQuery
  const myCart = await apolloClient.query<MyCartQuery, MyCartQueryVariables>({
    query: MyCartDocument,
    fetchPolicy: 'network-only',
  });

  if (myCart.data.me.activeCart) {
    return myCart.data.me.activeCart;
  }

  const createdCart = await apolloClient.mutate<
    CreateCartMutation,
    CreateCartMutationVariables
  >({
    mutation: CreateCartDocument,
  });

  if (createdCart.data?.createMyCart) {
    apolloClient.writeQuery({
      query: MyCartDocument,
      data: {
        me: {
          activeCart: createdCart.data.createMyCart,
        },
      },
    });

    return createdCart.data.createMyCart;
  }

  if (createdCart.errors) {
    Sentry.captureException(createdCart.errors, { level: Severity.Fatal });
  }

  throw new Error('Cart: Failed to getActiveCart');
}

/**
 * Clears the apollo cart cache
 */
export function clearApolloCartCache() {
  const apolloClient = initializeApollo();

  apolloClient.writeQuery({
    query: MyCartDocument,
    data: {
      me: {
        activeCart: null,
      },
    },
  });
}

export const groupItemsByChannel = (
  lineItems: LineItemDataFragment[],
): IChannelGroup[] => {
  const map = new Map();

  lineItems.forEach((lineItem) => {
    const key = lineItem.distributionChannel?.id;
    if (!map.has(key)) {
      map.set(key, {
        partner: {
          name: lineItem.distributionChannel?.name,
          slug: lineItem.distributionChannel?.key,
        },
        items: [],
      });
    }
    map.get(key).items.push(lineItem);
  });

  return [...map.values()];
};

/**
 * @param {CartDataFragment} cart
 * @returns {IGroupedCart}
 */
function groupCartLineItemsByChannel(cart: CartDataFragment): IGroupedCart {
  return {
    ...cart,
    groupedChannels: groupItemsByChannel(cart.lineItems),
    custom: {
      ...cart.custom,
      customFields: normalizeCustomFields(cart.custom),
    },
  };
}

export async function addLineItems(items: AddedLineItem[], origin: string) {
  const apolloClient = initializeApollo();
  const myCart = await getActiveCart();

  try {
    const mutationActions: MyCartUpdateAction[] = [];

    items.forEach((lineItem) => {
      const { sku, quantity, channel } = lineItem;
      const matchingCartItem = myCart.lineItems.find(
        ({ variant }) => variant?.sku === lineItem.sku,
      );

      if (!!matchingCartItem) {
        const matchingCartItemOrigin =
          normalizeCustomFields(matchingCartItem.custom).origin || '';
        const updatedLineItemOrigin = matchingCartItemOrigin.split(',');
        let updatedCustomFieldOrigin = {};

        if (!updatedLineItemOrigin.includes(origin)) {
          updatedLineItemOrigin.push(origin);

          mutationActions.push({
            setLineItemCustomField: {
              lineItemId: matchingCartItem.id,
              name: ORIGIN,
              value: escapeRegExp(`"${updatedLineItemOrigin.join(',')}"`),
            },
          });
        }

        mutationActions.push({
          changeLineItemQuantity: {
            lineItemId: matchingCartItem.id,
            quantity: matchingCartItem.quantity + quantity,
          },
          ...updatedCustomFieldOrigin,
        });

        return;
      }

      mutationActions.push({
        addLineItem: {
          sku,
          quantity,
          supplyChannel: { id: channel.id },
          distributionChannel: { id: channel.id },
          custom: {
            type: {
              key: SPROUTL_LINE_ITEM,
            },
            fields: [
              {
                name: ORIGIN,
                value: escapeRegExp(`"${origin}"`),
              },
            ],
          },
        },
      });
    });

    return await apolloClient.mutate<
      UpdateMyCartMutation,
      UpdateMyCartMutationVariables
    >({
      mutation: UpdateMyCartDocument,
      variables: {
        id: myCart.id,
        version: myCart.version,
        actions: mutationActions,
      },
    });
  } catch (e: any) {
    Sentry.captureException(e, { level: Severity.Fatal });
    throw new Error(`Basket: Failed to add item to basket. ${e?.message}`);
  }
}

/**
 * Get GroupedActiveCart in server side operations
 * @async
 * @param {GetGroupedActiveCartProps} props
 * @returns {Promise<IGroupedCart | null>}
 */
export async function getGroupedActiveCart({
  token,
  client,
}: GetGroupedActiveCartProps): Promise<IGroupedCart | null> {
  const apolloClient = fallbackApolloClient(client);

  const { data } = await apolloClient.query<MyCartQuery, MyCartQueryVariables>({
    query: MyCartDocument,
    context: {
      token,
    },
  });

  const { activeCart } = data.me;

  return activeCart ? groupCartLineItemsByChannel(activeCart) : null;
}

/**
 * Get GroupedActiveCart in server side operations
 * @async
 * @param {GetGroupedCartByIdProps} props
 * @returns {Promise<IGroupedCart | null>}
 */
export async function getGroupedCartById({
  id,
  token,
  client,
}: GetGroupedCartByIdProps): Promise<IGroupedCart | null> {
  const apolloClient = fallbackApolloClient(client);

  const { data } = await apolloClient.query<
    GetCartByIdQuery,
    GetCartByIdQueryVariables
  >({
    query: GetCartByIdDocument,
    variables: {
      id,
    },
    context: {
      token,
    },
  });

  const { cart } = data;

  return cart ? groupCartLineItemsByChannel(cart) : null;
}

/**
 * Gets a cart by ID if it's available or the customer's active cart
 * @param props
 * @returns {Promise<IGroupedCart | null>}
 */
export async function getGroupedActiveCartOrCartById({
  id,
  serverToken,
  customerToken,
  client,
}: GetGroupedActiveCartOrCartByIdProps) {
  if (id) {
    // Get groupedCart by cartId
    return getGroupedCartById({
      token: serverToken,
      client,
      id,
    });
  } else {
    // Get groupedCart for current customer using their token
    return getGroupedActiveCart({
      token: customerToken,
      client,
    });
  }
}

/**
 * React hook to get the GroupedActiveCart
 * @returns {GroupedActiveCart}
 */
export function useGroupedActiveCart(
  options: Parameters<typeof useMyCartQuery>[0] = {},
): IGroupedActiveCart {
  const hasAnonymousToken = useHasAnonymousToken();
  const hasUserToken = !!useUser().user;

  const hasToken = hasAnonymousToken || hasUserToken;

  const { data, loading, refetch } = useMyCartQuery({
    ...options,
    skip: !hasToken,
    onError: (error) => {
      !!options.onError && options.onError(error);
      Sentry.captureException(error, { level: Severity.Fatal });
    },
  });

  return {
    data: data?.me.activeCart
      ? groupCartLineItemsByChannel(data.me.activeCart)
      : null,
    loading,
    refetch,
  };
}

/**
 * Add an existing payment to an existing cart
 * @async
 * @param {AddPaymentToCartProps} props
 * @returns {Promise<IGroupedActiveCart>}
 */
export async function addPaymentToCart({
  data,
  token,
  client,
}: AddPaymentToCartProps): Promise<IGroupedCart> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, paymentId, cartVersion } = data;

  const result = await apolloClient.mutate<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >({
    mutation: UpdateCartDocument,
    variables: {
      actions: {
        addPayment: {
          payment: {
            typeId: 'payment',
            id: paymentId,
          },
        },
      },
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateCart) {
    return groupCartLineItemsByChannel(result.data.updateCart);
  }

  if (result.errors) {
    Sentry.captureException(result.errors, { level: Severity.Fatal });
  }

  throw new Error('Cart: Failed to addPaymentToCart');
}

export function isCartInStock(cart: CartDataFragment | null): boolean {
  if (!cart) {
    return false;
  }

  return cart.lineItems.every((item) => {
    const { isOnStock, availableQuantity } = getStockAvailabilityFromItem(item);
    return isOnStock && item.quantity <= availableQuantity;
  });
}

/**
 * Trigger the cart update recalculate action
 * @async
 * @param {RecalculateMyCartProps} props
 * @returns {Promise<IGroupedActiveCart>}
 */
export async function recalculateMyCart({
  data,
  token,
  client,
}: RecalculateMyCartProps): Promise<IGroupedCart> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, cartVersion } = data;

  const result = await apolloClient.mutate<
    UpdateMyCartMutation,
    UpdateMyCartMutationVariables
  >({
    mutation: UpdateMyCartDocument,
    variables: {
      actions: {
        recalculate: {
          updateProductData: true,
        },
      },
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateMyCart) {
    return groupCartLineItemsByChannel(result.data.updateMyCart);
  }

  if (result.errors) {
    Sentry.captureException(result.errors, { level: Severity.Fatal });
  }

  throw new Error('Cart: Failed to recalculateMyCart');
}

/**
 * Add billing address to cart and trigger recalculate action
 * @async
 * @param {AddBillingToCartProps} props
 * @returns {Promise<CartDataFragment>}
 */
export async function addBillingToCart({
  data,
  token,
  client,
}: AddBillingToCartProps): Promise<CartDataFragment> {
  const apolloClient = fallbackApolloClient(client);

  const {
    cartId,
    cartVersion,
    billingAddress,
    joinMailingList,
    iterableData = {},
  } = data;

  const result = await apolloClient.mutate<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >({
    mutation: UpdateCartDocument,
    variables: {
      actions: [
        {
          setBillingAddress: {
            address: billingAddress,
          },
        },
        {
          setCustomField: {
            name: MARKETING_OPT_IN,
            value: !!joinMailingList ? 'true' : 'false',
          },
        },
        ...Object.entries(iterableData).map(
          ([name, value]): CartUpdateAction => {
            return {
              setCustomField: {
                name,
                value: JSON.stringify(value),
              },
            };
          },
        ),
        {
          recalculate: {},
        },
      ],
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateCart) {
    return result.data.updateCart;
  }

  if (result.errors) {
    Sentry.captureException(result.errors, { level: Severity.Fatal });
  }

  throw new Error('Cart: Failed to addBillingRecalculateCart');
}

/**
 * Add shipping address to cart
 * @async
 * @param {IAddShippingToCartProps} props
 * @returns {Promise<CartDataFragment>}
 */
export async function addShippingToCart({
  data,
  token,
  client,
}: IAddShippingToCartProps): Promise<IGroupedCart> {
  const apolloClient = fallbackApolloClient(client);

  const { cartId, cartVersion, shippingAddress } = data;

  const result = await apolloClient.mutate<
    UpdateCartMutation,
    UpdateCartMutationVariables
  >({
    mutation: UpdateCartDocument,
    variables: {
      actions: [
        {
          setShippingAddress: {
            address: shippingAddress,
          },
        },
      ],
      id: cartId,
      version: cartVersion,
    },
    context: {
      token,
    },
  });

  if (result.data?.updateCart) {
    return groupCartLineItemsByChannel(result.data.updateCart);
  }

  if (result.errors) {
    Sentry.captureException(result.errors, { level: Severity.Fatal });
  }

  throw new Error('Cart: Failed to addBillingRecalculateCart');
}

export function getVariantAttributesRawBySku({
  sku,
  lineItems,
}: IGetVariantAttributesRawBySkuArgs) {
  return lineItems.find(({ variant }) => variant?.sku === sku)?.variant
    ?.attributesRaw;
}
