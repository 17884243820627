import { TExperiment } from './types';

export const ExperimentIds = {
  emailPopupTimes:
    process.env.VERCEL_ENV === 'production'
      ? 'VHj7a4hHTzi_HTCu7NiFXQ'
      : 'e-ddhnA9ShyJiHEmrzN3LA',
};

const experiments: TExperiment[] = [
  {
    name: 'Email Popup Timings',
    id: ExperimentIds.emailPopupTimes,
    variants: [
      {
        name: 'original',
        id: '0',
        weight: 33.333,
      },
      {
        name: 'Slower Popup',
        id: '1',
        weight: 33.333,
      },
      {
        name: 'Quicker Return',
        id: '2',
        weight: 33.333,
      },
    ],
  },
];

export default experiments;
