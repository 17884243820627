import { createInMemoryCache } from '@algolia/cache-in-memory';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import aa from 'search-insights';

import { updateAlgoliaUserToken } from 'utils/gtm/events';

function getSearchClient(): {
  anonUserToken?: string;
  searchClient: SearchClient;
} {
  const searchClient = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
    process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
    {
      responsesCache: createInMemoryCache(),
      requestsCache: createInMemoryCache({ serializable: false }),
    },
  );
  let anonUserToken;

  if (typeof window !== 'undefined') {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
      // creates or uses an existing a userToken
      useCookie: true,
    });

    aa('getUserToken', null, (err, userToken) => {
      if (err) {
        console.error(err);
        return;
      }

      anonUserToken = userToken;
    });

    aa(
      'onUserTokenChange',
      (userToken) => {
        updateAlgoliaUserToken(userToken);

        anonUserToken = userToken;
      },
      { immediate: true },
    );
  }

  return { anonUserToken, searchClient };
}

export default getSearchClient;
