export const ArticleFragment = `
  _createdAt,
  _id,
  title,
  lede,
  slug,
  image {
    ...,
    asset->{
      url
    }
  },
  headerBlocks,
  footerBlocks,
  author->,
  category->,
  contentTags[]->{
    _id
  }
`;

export const ArticlesMetaQuery = `
  "articles": articles[]->{
    ${ArticleFragment}
  }
`;
