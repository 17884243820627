export const KitFragment = `
  _id,
  groupIds,
  pimId,
  title,
  description,
  cta
`;

export const KitBlockFragment = `
  "kit": kit->{
    ...,
  }
`;
