import { MultipleQueriesQuery } from '@algolia/client-search';

import { getIndexes } from 'framework/algolia/api';
import {
  productHasPriceAndStock,
  productMetaAttributes,
} from 'framework/algolia/query-fragments';

const { anonUserToken, QUERY_INDEX } = getIndexes();

export const getKitProducts = (
  collectionId: string,
  groupIds: string[],
  partner?: string,
): MultipleQueriesQuery[] => {
  if (!partner) return [];

  return groupIds.map((groupId) => {
    return {
      indexName: QUERY_INDEX,
      params: {
        attributesToRetrieve: productMetaAttributes,
        clickAnalytics: true,
        filters: `collection:${collectionId} AND collectionGroup:${groupId} AND ${productHasPriceAndStock} AND selectedPartner.slug:${partner}`,
        userToken: anonUserToken,
        hitsPerPage: 1,
      },
    };
  });
};
