import { FC } from 'react';

import SiteLink from 'components/common/site-link';
import { PortableText } from 'framework/sanity/portable-text';

import * as St from './styles';
import { IProseBlockquoteProps } from './types';

export const ProseBlockquote: FC<IProseBlockquoteProps> = ({
  node: { quote, citation, href },
}) => {
  return (
    <St.Blockquote>
      <PortableText blocks={quote} />
      {citation && (
        <cite>
          {href ? (
            <SiteLink
              href={href}
              external={
                href.startsWith('http') && !href.includes('sproutl.com')
              }
            >
              {citation}
            </SiteLink>
          ) : (
            citation
          )}
        </cite>
      )}
    </St.Blockquote>
  );
};
