import React, { FC } from 'react';

import * as St from './styles';
import { IFieldErrorMessageProps } from './types';

// @TODO: aria-describedby on input
// @TODO: visually hidden "Error:"
export const FieldError = ({ children, ...props }: IFieldErrorMessageProps) => (
  <St.ErrorMessage {...props}>{children}</St.ErrorMessage>
);

export const FieldMessage: FC = ({ children }) => (
  <St.Message>{children}</St.Message>
);
