import styled from 'styled-components';

import Container from 'components/common/container';
import { Heading3, Heading4 } from 'components/common/text';
import { mobile, tablet } from 'utils/media';
import theme from 'utils/theme';

interface IWrapperProps {
  isOpen: boolean;
  hasImage: boolean;
}

export const StyledContainer = styled(Container)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

interface ICloseButtonProps {
  mobileColor?: Sproutl.ColorScheme;
}

export const CloseButton = styled.button<ICloseButtonProps>`
  width: var(--space-s);
  height: var(--space-s);
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  position: absolute;
  right: var(--space-l-xl);
  top: var(--space-l-xl);

  ${mobile} {
    color: ${({ mobileColor }) => theme.colors[mobileColor || 'black']};
  }
`;

export const ContentWrapper = styled.div`
  padding: var(--space-l-xl);
`;

export const Wrapper = styled.div<IWrapperProps>`
  background-color: ${theme.colors.white};
  border-top-left-radius: var(--space-xl);
  border-top-right-radius: var(--space-xl);
  bottom: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  left: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  transform: translateY(${({ isOpen }) => (isOpen ? 0 : '100%')});
  transition: all ${theme.transitionSpeeds.normal}s ease-out;
  will-change: transform;
  width: 100%;
  display: grid;

  figure {
    position: relative;
  }

  img {
    border-top-left-radius: var(--space-xl);
    border-top-right-radius: var(--space-xl);
    object-fit: cover;
    width: 100%;
  }

  ${Heading3},
  ${Heading4} {
    font-size: var(--step-3);
    padding-right: var(--space-2xl);
  }

  footer {
    color: ${theme.colors.doveGrey};

    p {
      margin: 0;

      + * {
        margin-top: var(--space-4xs);
      }
    }

    a {
      text-decoration: underline;
      color: inherit;
    }
  }

  ${mobile} {
    max-height: calc(100vh - var(--site-gutter) * 2);
    overflow-y: auto;
    max-width: 500px;
  }

  ${tablet} {
    position: absolute;
    border-radius: var(--space-xl);
    top: 140px;
    bottom: auto;
    transform: translateY(${({ isOpen }) => (isOpen ? '24px' : '-100%')});
    width: 100%;
    grid-template-columns: ${({ hasImage }) => (hasImage ? '3fr 4fr' : '1fr')};
    max-width: ${({ hasImage }) => (hasImage ? '760px' : '500px')};
    overflow: hidden;

    img {
      border-radius: 0;
      height: 100%;
      position: absolute;
    }
  }
`;
