import { intersectionWith } from 'lodash';

import { productHasPriceAndStock } from 'framework/algolia/query-fragments';
import { NormalizedAttributes } from 'utils/commercetools/types';

import {
  PLANTS_CATEGORIES_TO_EXCLUDE,
  POT_SIZE_MULTIPLIER,
  POT_SIZE_INDOOR_MAX_VARIANCE,
  POT_SIZE_INDOOR_MIN_VARIANCE,
  POT_SIZE_OUTDOOR_MAX_VARIANCE,
  POT_SIZE_OUTDOOR_MIN_VARIANCE,
  POT_SHAPES_TO_INCLUDE,
  POT_MATERIALS_TO_EXCLUDE,
} from './constants';

const getCuratedProducts = (products: string[]) => {
  const queryFilters = products.map((sku) => `sproutlSku:${sku}`);

  return [`(${queryFilters.join(' OR ')})`];
};

const getPlantFilters = (attributes: NormalizedAttributes): string[] => {
  const diameterTop = attributes.diameter_top;
  const indoorOutdoor = attributes.indoor_outdoor || [];
  const queryFilters = ['categories:plants', 'canPlantIntoAPot:true'];
  const material: string[] = attributes.material;
  const shape: string[] = attributes.shape;
  const isIndoor = indoorOutdoor.includes('Indoor');
  const isOutdoor = indoorOutdoor.includes('Outdoor');
  const shouldSkipRecommendations =
    !intersectionWith(POT_SHAPES_TO_INCLUDE, shape).length ||
    intersectionWith(POT_MATERIALS_TO_EXCLUDE, material).length ||
    (!isIndoor && !isOutdoor);

  if (shouldSkipRecommendations) {
    return [];
  }

  if (isOutdoor) {
    queryFilters.push(
      `potDiameter:${Math.floor(
        (diameterTop - POT_SIZE_OUTDOOR_MAX_VARIANCE) / POT_SIZE_MULTIPLIER,
      )} TO ${Math.ceil(
        (diameterTop - POT_SIZE_OUTDOOR_MIN_VARIANCE) / POT_SIZE_MULTIPLIER,
      )}`,
      "tags:'Outdoor plant'",
    );
  } else if (isIndoor) {
    queryFilters.push(
      `potDiameter:${Math.floor(
        diameterTop - POT_SIZE_INDOOR_MAX_VARIANCE,
      )} TO ${Math.ceil(diameterTop - POT_SIZE_INDOOR_MIN_VARIANCE)}`,
      "tags:'Indoor plant'",
    );
  }

  // Manual exclusions
  queryFilters.push(
    `NOT categories:'${PLANTS_CATEGORIES_TO_EXCLUDE.join(
      "' AND NOT categories:'",
    )}'`,
  );

  return queryFilters;
};

const getPotFilters = (attributes: NormalizedAttributes): string[] => {
  const potDiameter = attributes.pot_diameter;
  const queryFilters = [
    '(categories:pots/outdoor-pots/pots OR categories:pots/indoor-pots/pots OR categories:pots/indoor-pots/hanging-planters OR categories:pots/outdoor-pots/window-boxes-and-troughs OR categories:pots/outdoor-pots/window-hanging-baskets)',
  ];

  const isIndoor = !!attributes.indoor_outdoor?.includes('Indoor');
  const isOutdoor = !!attributes.indoor_outdoor?.includes('Outdoor');
  const canPlantIntoAPot = !!attributes.how_to_plant_into_a_pot;

  if (PLANTS_CATEGORIES_TO_EXCLUDE.includes(attributes.category_slug)) {
    return [];
  }

  if (!canPlantIntoAPot || (!isIndoor && !isOutdoor)) {
    return [];
  }

  if (isOutdoor) {
    queryFilters.push(
      `diameterTop:${Math.floor(
        potDiameter * POT_SIZE_MULTIPLIER + POT_SIZE_OUTDOOR_MIN_VARIANCE,
      )} TO ${Math.ceil(
        potDiameter * POT_SIZE_MULTIPLIER + POT_SIZE_OUTDOOR_MAX_VARIANCE,
      )}`,
      'indoorOutdoor:Outdoor',
    );
  } else if (isIndoor) {
    queryFilters.push(
      `diameterTop:${potDiameter + POT_SIZE_INDOOR_MIN_VARIANCE} TO ${
        potDiameter + POT_SIZE_INDOOR_MAX_VARIANCE
      }`,
      'indoorOutdoor:Indoor',
    );
  }

  // Manual exclusions
  // TODO: remove when we can reconcile materials types
  queryFilters.push(`(shape:'${POT_SHAPES_TO_INCLUDE.join("' OR shape:'")}')`);
  queryFilters.push(
    `NOT material:'${POT_MATERIALS_TO_EXCLUDE.join("' AND NOT material:'")}'`,
  );

  return queryFilters;
};

const getBulbFilters = (attributes: NormalizedAttributes): string[] => {
  const { genus_common, sku } = attributes;
  const queryFilters = ['categories:bulbs', `NOT sproutlSku:${sku}`];

  if (!!genus_common) {
    queryFilters.push(`genusCommon:${genus_common}`);
  }

  return queryFilters;
};

export const getQueryFilters = (attributes: NormalizedAttributes): string[] => {
  const { supplemental_product_other } = attributes;
  const category = attributes.category_slug || '';
  let queryFilters: string[] = [];

  switch (true) {
    case supplemental_product_other?.length > 0:
      queryFilters = getCuratedProducts(supplemental_product_other);
      break;
    case category.includes('pots') &&
      !category.endsWith('accessories') &&
      !!attributes.diameter_top:
      queryFilters = getPlantFilters(attributes);
      break;
    case category.includes('plants') && !!attributes.pot_diameter:
      queryFilters = getPotFilters(attributes);
      break;
    case category.includes('bulbs'):
      queryFilters = getBulbFilters(attributes);
      break;
    default:
      queryFilters = [];
  }

  if (queryFilters.length === 0) {
    return [];
  }

  return [productHasPriceAndStock, ...queryFilters];
};
