import { debounce } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useEffectOnce } from 'react-use';

import { CHECKOUT_STEPS } from 'pages/checkout/hooks';
import { TAssignedVariant } from 'utils/ab-testing/types';
import { ActiveStep } from 'utils/checkout/types';
import { IGroupedCart, MappedSearchResponse } from 'utils/commercetools/types';

import {
  ITrackViewProductProps,
  trackAddDeliveryInfo,
  trackAddPaymentInfo,
  trackBeginCheckout,
  trackViewBasket,
  trackViewProduct,
  trackViewProductsList,
} from './events';
import {
  dataLayerPageView,
  dataLayerSetExperiment,
  dataLayerUpdatePage,
} from './utils';

/**
 * useGtm hook which initialises Google Tag Manager
 */
export function useGtm(abTests?: TAssignedVariant[]) {
  const router = useRouter();
  const { asPath, isReady, events } = router;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDataLayerPageView = useCallback(
    debounce(
      (url: string) => {
        dataLayerPageView(url);
        abTests?.forEach(dataLayerSetExperiment);
      },
      50,
      {
        leading: true,
        trailing: false,
      },
    ),
    [dataLayerPageView, dataLayerSetExperiment, abTests],
  );

  useEffectOnce(() => {
    if (process.env.NEXT_PUBLIC_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GTM_ID,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
        events: {
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
          page: asPath,
          originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`,
        },
      });
    }

    // Push initial page view to GA
    debouncedDataLayerPageView(asPath);
  });

  useEffect(() => {
    if (!isReady) {
      return;
    }

    events.on('routeChangeStart', dataLayerUpdatePage);
    events.on('routeChangeComplete', debouncedDataLayerPageView);

    return () => {
      events.off('routeChangeStart', dataLayerUpdatePage);
      events.off('routeChangeComplete', debouncedDataLayerPageView);
    };
  }, [events, isReady, debouncedDataLayerPageView]);
}

export function useTrackViewProductsList(
  list: string,
  search: MappedSearchResponse,
) {
  useEffect(() => {
    const products = search.results;
    trackViewProductsList(list, products);
  }, [list, search]);
}

export function useTrackViewProduct({
  sku,
  name,
  price,
  category,
}: ITrackViewProductProps) {
  useEffect(() => {
    trackViewProduct({
      sku,
      name,
      price,
      category,
    });
  }, [sku, name, price, category]);
}

export function useTrackViewBasket(cart: IGroupedCart | null) {
  useEffect(() => {
    if (!cart?.lineItems || !cart?.totalPrice) {
      return;
    }

    trackViewBasket(cart.totalPrice, cart.lineItems);
  }, [cart?.totalPrice, cart?.lineItems]);
}

export function useTrackCheckout(
  cart: IGroupedCart | null,
  activeStep: ActiveStep,
) {
  useEffect(() => {
    if (!cart?.lineItems || !cart?.totalPrice) {
      return;
    }

    switch (activeStep) {
      case CHECKOUT_STEPS.DETAILS:
        trackBeginCheckout(cart.totalPrice, cart.lineItems);
        break;
      case CHECKOUT_STEPS.DELIVERY:
        trackAddDeliveryInfo(cart.totalPrice, cart.lineItems);
        break;
      case CHECKOUT_STEPS.PAYMENT:
        trackAddPaymentInfo(cart.totalPrice, cart.lineItems);
        break;
    }
  }, [activeStep, cart?.totalPrice, cart?.lineItems]);
}
