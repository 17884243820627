import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import axios from 'axios';

import { ReferralCodeResponse } from 'utils/checkout/types';
import { trackMGM } from 'utils/gtm/events';

import { ReferralState } from './types';

export const generateReferralCode = async ({
  email,
  setShareState,
}: {
  email: string;
  setShareState: (state: ReferralState) => void;
}): Promise<string> => {
  try {
    setShareState(ReferralState.generating);
    const response = await axios.post<ReferralCodeResponse>(
      `/api/checkout/generate-referral-code`,
      { email },
    );

    setShareState(ReferralState.share);
    // TODO: tracking id
    trackMGM('Code generated');
    return response.data.referralCode || '';
  } catch (error: any) {
    // Silently catch
    Sentry.captureException(error, { level: Severity.Critical });
    setShareState(ReferralState.error);
  }

  return '';
};

export const copyToClipboard = (value: string) => {
  const el = document.createElement('input');
  el.value = value;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
};

export const generateReferralMessage = (code: string, ctaText: string) => {
  return `${ctaText?.replace('{{ referralCode }}', code)}`;
};

export const generateMailto = (
  code: string,
  ctaText: string,
  ctaSubject: string,
) => {
  return `mailto:?subject=${encodeURI(ctaSubject)}&body=${encodeURI(
    generateReferralMessage(code, ctaText),
  )}`;
};

export const shareReferralCode = async ({
  referralCode,
  ctaText,
  ctaSubject,
  onCopy,
}: {
  referralCode: string;
  ctaText: string;
  ctaSubject: string;
  onCopy: () => void;
}) => {
  const title = ctaSubject;
  const text = generateReferralMessage(
    referralCode,
    `${ctaSubject} - ${ctaText}`,
  );

  if (!navigator.share) {
    copyToClipboard(referralCode);
    onCopy();
    return;
  }

  try {
    await navigator.share({
      title,
      text,
    });
  } catch (e) {}
};
