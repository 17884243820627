export const productHasPrice = 'centAmount > 0';
export const productHasStock = 'stockAmount > 0';
export const productHasPageviews = 'pageviews > 0';
export const productHasPriceAndStock = `${productHasPrice} AND ${productHasStock}`;

export const productMetaAttributes = [
  'sproutlSku',
  'brandName',
  'categoryLabels',
  'categorySlug',
  'centAmount',
  'images',
  'productName',
  'quantityMultiplier',
  'range',
  'selectedPartner',
  'slug',
  'variants',
  'variantStock',
  'variationGroupCode',
  'quantityMultiplier',
  'unitsPerPack',
];

export const productMetaExtendedAttributes = [
  ...productMetaAttributes,
  'categorySlug',
  'partners',
  'lightCondition',
  'diameterTop',
  'volume',
  'heightCurrent',
  'potVolume',
  'colour',
  'shape',
  'height',
  'width',
  'length',
  'material',
  'spreadCurrent',
  'toolType',
  'boxNumberPrimary',
  'boxNumberSecondary',
  'isFreight',
  'compostType',
  'compostNeeded',
  'indoorOutdoor',
  'tags',
  'genusCommon',
];

export const productAttributes = [
  ...productMetaAttributes,
  ...productMetaExtendedAttributes,
  'heightEventual',
  'spreadEventual',
  'description',
  'categories',
  'stockAmount',
  'lifecycle',
  'plantTime',
  'botanicName',
  'floweringSeason',
  'howToPlantIntro',
  'howToPlantIntoAPot',
  'howToPlantIntoTheGround',
  'effortLevel',
  'location',
  'foliage',
  'toolHandleType',
  'toolJob',
];

export const productIsCompost =
  '(categories:care-and-essentials/compost-and-fertilisers/compost)';
