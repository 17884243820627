import styled, { css } from 'styled-components';

import { FlowStyleProps } from './types';

export const Flow = styled.div<FlowStyleProps>`
  > * + * {
    margin-top: ${({ size = 's' }) =>
      `var(--flow, var(--space-${size}))`}!important;
  }
`;

export const FlowX = styled.div<FlowStyleProps>`
  > * + * {
    margin-left: ${({ size = 's' }) =>
      `var(--flow-x, var(--flow, var(--space-${size})))`}!important;
  }
`;

interface FlowXFlexStyleProps extends FlowStyleProps {
  alignItems?: 'center' | 'flex-start' | 'flex-end';
  wrap?: 'wrap' | 'none';
}

export const FlowXFlex = styled.div<FlowXFlexStyleProps>`
  --x: ${({ size = 's' }) =>
    `var(--flow-x, var(--flow, var(--space-${size})))`};
  --y: var(--flow-x-y, var(--x));
  display: flex;
  gap: var(--y) var(--x);
  flex-wrap: ${({ wrap = 'wrap' }) => wrap};

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}
`;
