import { groq } from 'next-sanity';

import { BlocksInnerFragment } from './fragments';

export const subscribePageQuery = groq`
  *[_type == "emailSubscribePage" && slug.current == $slug] {
    ...,
    blocks[] {
      ${BlocksInnerFragment}
    }
  }
`;
