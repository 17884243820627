import styled, { css } from 'styled-components';

export interface IStepProps {
  step?: number | string;
  textAlign?: 'center' | 'left' | 'right';
}

const Step = styled.p<IStepProps>`
  font-size: var(--step-${({ step = 0 }) => step});

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}
`;

export default Step;
