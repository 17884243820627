import styled from 'styled-components';

import { headingFont } from 'utils/shared-styles';

export const Blockquote = styled.blockquote`
  margin: 0;

  :not(:first-child),
  :not(:last-child) + * {
    --prose-flow: var(--space-xl-2xl);
  }

  p {
    ${headingFont}
    font-size: var(--step-4);

    & + p {
      margin-top: var(--space-s);
    }
  }

  cite {
    font-style: normal;
    font-size: var(--step-0);
    margin-top: var(--space-2xs);
    display: block;
  }

  a {
    text-decoration: underline;
    color: inherit;

    :hover {
      text-decoration: none;
    }
  }
`;
