import { ArticleFragment } from './articles';
import { CollectionPreviewFragment } from './collections';
import { GenusTileFragment } from './genus';

export const ContentBlocksFragment = `
"contentBlocks": contentBlocks[]{
  ...,
  contentTag->,
  "content": *[_type in ["article", "genus", "collection", "category", "partner", "edit"] && ^.contentTag._ref in contentTags[]._ref] | order(_createdAt desc)[0..2] {
    "article": select(_type == "article" => {
      ${ArticleFragment}
    }),
    "genus": select(_type == "genus" => {
      ${GenusTileFragment}
    }),
    "collection": select(_type == "collection" => {
      ${CollectionPreviewFragment}
    }),
    "category": select(_type == "category" => {
      _id,
      title,
      slug,
      "image": image {
        ...,
        asset->
      },
      preview
    }),
    "partner": select(_type == "partner" => {
      _id,
      title,
      slug,
      preview
    }),
    "edit": select(_type == "edit" => {
      _id,
      name,
      subTitle,
      slug,
      banner
    })
  }
}
`;
