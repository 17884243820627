import { ImageLoaderProps } from 'next/image';

export default function imagekitLoader({
  src,
  width,
  quality = 80,
}: Pick<ImageLoaderProps, 'src' | 'width' | 'quality'>): string {
  // Demo: https://ik.imagekit.io/demo/img/plant.jpeg?tr=w-300,c-at_max
  const params = ['f-auto', `w-${width}`, 'ar-1-1'];
  if (quality) {
    params.push(`q-${quality}`);
  }
  return `${src}?tr=${params.join()}`;
}
