import { groq } from 'next-sanity';

import { publicPartnersFragment } from './fragments/publicPartners';

export const siteGlobalsQuery = groq`
  {
    "siteGlobals": *[_type == "siteSettings" && _id == "siteSettings"] {
      ...,
      pressBanner {
        images[] {
          ...,
          asset->{
            url
          }
        }
      },
      valuesBanner {
        images[] {
          ...,
          asset->{
            url
          }
        }
      },
      search {
        ...,
        popularContent {
          ...,
          "articles": articles[]->{
            _createdAt,
            title,
            slug,
            image {
              ...,
              asset->{
                url
              }
            }
          }
        }
      },
    },
    "partners": *[_type == "partnerSettings" && _id == "partnerSettings"] {
      ...,
      giftMessaging {
        ...,
        permissions[] {
          "hasGiftMessaging": hasGiftMessaging,
          "name": partnerRef->name,
          "slug": partnerRef->slug.current,
          "ctKey": partnerRef->commercetoolsKey
        }
      },
    },
    "zendesk": *[_type == "zendeskSettings" && _id == "zendeskSettings"],
    "trustpilot": *[_type == "trustpilotSettings" && _id == "trustpilotSettings"],
    "supplementaryContent": *[_type == "supplementaryContent" && _id == "supplementaryContent"],
    "emailPopup": *[_type == "emailPopup" && _id == "emailPopup"],
    "addonSettings": *[_type == "addonSettings" && _id == "addonSettings"],
    "featureFlags": *[_type == "featureFlags" && _id == "featureFlags"],
    "attributeDefinitions": *[_type == "attributeDefinition"] {
      ...,
      values[] {
        ...,
        "icon": icon->icon
      },
      "algoliaId": algoliaId.current,
    },
    ${publicPartnersFragment}
  }
`;
