import styled from 'styled-components';

import { mobile } from 'utils/media';
import theme from 'utils/theme';

import Container from '../container';

export const StyledContainer = styled(Container)`
  display: flex;
  list-style: none;
  justify-content: space-around;
  color: ${theme.colors.deepRed};
  text-align: center;
  font-size: var(--step-1);
  gap: var(--site-gutter);
  flex-wrap: wrap;

  figure {
    height: 55px;
    margin-bottom: var(--space-s-l);
  }

  li {
    max-width: 270px;
    min-width: 100px;
    position: relative;
  }

  h3 {
    margin: 0;
    white-space: pre-line;

    a {
      color: inherit;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }

      &:after {
        content: '';
        position: absolute;
        z-index: ${theme.zIndexes.low};
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  ${mobile} {
    li {
      width: calc(50% - var(--site-gutter) / 2);
      min-width: auto;
    }
  }

  @media (prefers-reduced-motion) {
    .reduce-motion {
      animation: none !important;
    }
  }
`;
