import NextImage from 'next/image';
import React from 'react';

import { urlFor } from 'framework/sanity/url-for';

import {
  SanityImageLoader,
  ImageKitImageLoader,
  CloudinaryImageLoader,
} from './loaders';
import { BreakpointWrapper } from './styles';
import { ImageProps } from './types';

const atMediaRegex = /@media/gm;

function isImageKitSrc(src: string) {
  return src.includes('ik.imagekit.io');
}

export default function Image({
  src,
  alt,
  sizes,
  loader = 'sanity',
  loading = 'lazy',
  objectFit,
  aspectRatio,
  blurDataURL,
}: ImageProps) {
  // Default objectFit depending on the loader, sanity is used for CMS
  // while the others for product images
  if (!objectFit) {
    objectFit = loader === 'sanity' ? 'cover' : 'contain';
  }

  // Remove "@media" from sizes string
  const parsedSizes = sizes ? sizes.replace(atMediaRegex, '') : undefined;

  // Get original image URL
  const imageSource: string | null =
    loader === 'sanity'
      ? urlFor(src).width(100).height(100).auto('format').url()
      : src?.secure_url || src?.url || src;

  // If we don't have an original image, return null
  if (!imageSource) {
    return null;
  }

  if (typeof aspectRatio === 'object') {
    return (
      <>
        {Object.entries(aspectRatio).map(([media, breakpointAspectRatio]) => (
          <BreakpointWrapper key={media} media={media}>
            <NextImage
              layout="fill"
              // @todo Once product images are migrated to imagekit we can remove the cloudinary loader
              loader={
                loader === 'sanity'
                  ? SanityImageLoader(src, breakpointAspectRatio)
                  : isImageKitSrc(imageSource)
                  ? ImageKitImageLoader
                  : CloudinaryImageLoader
              }
              objectFit={objectFit}
              src={imageSource as string}
              sizes={parsedSizes}
              blurDataURL={blurDataURL}
              alt={alt || src?.altText}
              loading={loading}
            />
          </BreakpointWrapper>
        ))}
      </>
    );
  }

  return (
    <NextImage
      layout="fill"
      // @todo Once product images are migrated to imagekit we can remove the cloudinary loader
      loader={
        loader === 'sanity'
          ? SanityImageLoader(src, aspectRatio)
          : isImageKitSrc(imageSource)
          ? ImageKitImageLoader
          : CloudinaryImageLoader
      }
      objectFit={objectFit}
      src={imageSource}
      sizes={parsedSizes}
      blurDataURL={blurDataURL}
      alt={alt}
      loading={loading}
    />
  );
}
