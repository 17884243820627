export function setMobileDialogOpen(open: boolean) {
  if (typeof window !== 'undefined' && document) {
    document.body.classList[open ? 'add' : 'remove']('mobileDialogOpen');
  }
}

export function isMobileDialogOpen() {
  if (typeof window !== 'undefined' && document) {
    return document.body.classList.contains('mobileDialogOpen');
  }
}
