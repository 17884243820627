import { IncomingMessage } from 'http';

import { COOKIE_NAME } from './constants';
import experiments from './experiments';
import { TAssignedVariant, TExperiment } from './types';

export function getFreshAssignments(
  altExperiments?: TExperiment[],
): TAssignedVariant[] {
  let n = Math.random() * 100;

  const assignments = (altExperiments || experiments).map(
    (exp: TExperiment) => {
      const variant = exp.variants.find((v) => {
        if (v.weight >= n) return true;
        n -= v.weight;
      }) || { id: '0' };

      return {
        id: exp.id,
        variant: variant.id,
      };
    },
  );

  return assignments;
}

export function stringifyAssignments(
  assignedVariants?: TAssignedVariant[],
): string {
  const assignments = assignedVariants || getFreshAssignments();
  return assignments.map(({ id, variant }) => `${id}.${variant}`).join('|');
}

export function parseAssignments(cookieData: string = ''): TAssignedVariant[] {
  return cookieData
    .split('|')
    .filter(Boolean)
    .map((exp) => {
      const [id, variant] = exp.split('.');

      return {
        id,
        variant,
      };
    });
}

export function isMatchingAbTest(
  abTests: TAssignedVariant[] = [],
  testId: string,
  variantId?: string,
) {
  return !!abTests.find(
    ({ id, variant }) => id === testId && (!variantId || variant === variantId),
  );
}

export function prepareAssignments(
  currentCookie: string,
  altExperiments?: TExperiment[],
): [string, TAssignedVariant[]] {
  const internalExperiments = altExperiments || experiments;

  const currentAssignments = parseAssignments(currentCookie || '');
  const freshAssignments = getFreshAssignments(internalExperiments);
  const newAssigments: TAssignedVariant[] = [];

  // Back out early if there are no experiments
  if (!internalExperiments.length) return ['', []];

  // Loop all the experiments
  internalExperiments.forEach(({ id: expId, variants }) => {
    const variantIds = variants.map(({ id }) => id);

    // Try to find an experiment with the same ID & variant ID
    const matchingAssignment = currentAssignments.find(({ id, variant }) => {
      return id === expId && variantIds.includes(variant);
    });

    // Pick either the current, or a fresh match
    if (matchingAssignment) {
      newAssigments.push(matchingAssignment);
    } else {
      const freshAssignment = freshAssignments.find(({ id }) => id === expId);
      if (freshAssignment) {
        newAssigments.push(freshAssignment);
      }
    }
  });

  return [stringifyAssignments(newAssigments), newAssigments];
}

/**
 * IncomingMessage doesn't have a cookies definition, so fake the cookies object
 */
export function findCookieFromReq(req?: IncomingMessage): string {
  const fallbackRequest = { cookies: { [COOKIE_NAME]: '' } };
  const internalReq = req || fallbackRequest;
  const allCookies =
    'cookies' in internalReq
      ? internalReq.cookies || fallbackRequest.cookies
      : fallbackRequest.cookies;

  return allCookies[COOKIE_NAME] || '';
}
