const colors: Record<string, string> = {
  white: '#ffffff',
  offWhite: '#f9f6f3',
  vistaWhite: '#FBF8F6',
  darkBg: '#F2ECE7',
  roseWhite: '#F2ECE7',
  beige: '#F2ECE7',
  black: '#000000',
  offBlack: '#1A1A1A',
  lightestGrey: '#F7F7F7',
  lighterGrey: '#E9E8E7',
  lightGrey: '#E0DDDB',
  midGrey: '#B3B3B3',
  grey: '#727272',
  doveGrey: '#696969',
  darkGrey: '#484848',
  darkBlue: '#00366B',
  lightGreen: '#A3FF64',
  darkGreen: '#004A29',
  darkGreenBorder: 'rgba(0, 73, 40, 0.1)',
  sustainableGreen: '#D1FFB1',
  gold: '#FFDC40',
  purple: '#9571F9',
  pink: '#FFD1FF',
  label: 'rgba(0, 0, 0, 0.5)',
  scrollbarBackground: 'rgba(0, 74, 41, 0.2)',
  iconUnderlay: 'rgba(0, 0, 0, 0.15)',
  heroUnderlay: 'rgba(0, 0, 0, 0.3)',
  modalBackground: 'rgba(0, 0, 0, 0.5)',
  modalBackgroundLight: 'rgba(0, 0, 0, 0.1)',
  selectHighlight: 'rgba(0, 0, 0, 0.05)',
  loadingOverlay: 'rgba(167, 122, 255, 0.9)',
  errorRed: '#E02900',
  coleusGreen: '#a3ff64',
  irisPurple: '#9571F9',
  purpleChalk: '#F2EBFF',
  dahliaPink: '#FFD1FF',
  hydrangeaBlue: '#03dbff',
  pineGreen: '#004a29',
  pineGreenFaded: 'rgba(0, 74, 41, 0.3)',
  daffodilYellow: '#ffdc40',
  poppyRed: '#ff552e',
  deepRed: '#F52D00',
  thistleBlue: '#00366b',
  blueChalk: '#ede4ff',
  whiteTransparent: 'rgba(255, 255, 255, 0)',
};

const theme = {
  fonts: {
    heading: `'Antique Olive', system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
    body: `Patron, system-ui, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji'`,
  },
  colors,
  weights: {
    normal: 400,
    bold: 700,
    headingBold: 900,
  },
  transitionSpeeds: {
    fastest: 0.1,
    fast: 0.2,
    normal: 0.3,
    slow: 0.4,
  },
  pageWidth: {
    extraSmall: 400,
    small: 768,
    inset: 1140,
    standard: 1440,
    extended: 1920,
  },
  zIndexes: {
    chatPopup: 60,
    loader: 50,
    modals: 40,
    cookies: 39,
    hotjarFeedback: 38,
    header: 30,
    high: 20,
    mid: 10,
    low: 1, // fixes
    normal: 0,
  },
  radii: {
    small: 'var(--space-s)',
    tile: '17%',
    default: 'var(--space-s-2xl)',
    medium: 'var(--space-s-3xl)',
    plpTile: 'var(--space-xl-3xl)',
  },
  boxShadows: {
    light: '0px var(--space-3xs) var(--space-2xs) rgba(0, 0, 0, 0.1);',
    large: '0 0 var(--space-m) rgba(0, 0, 0, 0.05)',
    header: '0 var(--space-3xs) var(--space-xl) rgba(0, 0, 0, 0.1)',
  },
  sizes: {
    input: {
      slimHeight: 40,
      height: 48,
    },
    button: {
      height: 48,
    },
    fonts: {
      input: 16,
    },
    cookies: {
      bar: 150,
      barTight: 100,
    },
    modals: {
      thin: 460,
      normal: 1000,
    },
  },
};

export const transparencyToHex = (transparency: number): string => {
  return (
    {
      100: 'FF',
      90: 'E6',
      80: 'CC',
      70: 'BF',
      60: '99',
      50: '80',
      40: '66',
      30: '4D',
      20: '33',
      10: '1A',
      0: '00',
    }[transparency] || 'FF'
  );
};

export const prepareTheme = (
  cmsTheme: string = '',
  transparency: number = 100,
  defaultBg = 'white',
  defaultText = 'black',
) => {
  let [background, text] = cmsTheme.split(':');
  background = background || defaultBg;
  text = text || defaultText;

  return {
    backgroundHex: `${theme.colors[background]}${transparencyToHex(
      transparency,
    )}`,
    textHex: theme.colors[text],
    background: background as Sproutl.ColorScheme,
    text: text as Sproutl.ColorScheme,
  };
};

export default theme;
