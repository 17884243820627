import styled, { css } from 'styled-components';

import Container from 'components/common/container';
import { Flow } from 'components/common/flow';
import { desktop, largeDesktop } from 'utils/media';
import theme from 'utils/theme';
import { IDelayedRenderProps } from 'utils/types';

import {
  DESKTOP_NAV_CHILD_HEIGHT,
  DESKTOP_NAV_HEIGHT,
} from '../../../constants';

export const DesktopOnly = styled.div`
  display: none;

  ${desktop} {
    display: block;
  }
`;

interface IWrapperProps {
  isVisible: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  z-index: ${theme.zIndexes.header - 1};
  height: ${DESKTOP_NAV_CHILD_HEIGHT}px;
  position: absolute;
  width: 100%;
  transition: ${theme.transitionSpeeds.slow}s transform;
  transform: ${({ isVisible }) => `translateY(${isVisible ? '0%' : '-100%'})`};
  box-shadow: ${theme.boxShadows.header};
`;

interface IUnderlayProps extends IDelayedRenderProps {
  isClear: boolean;
}

export const Underlay = styled.div<IUnderlayProps>`
  position: fixed;
  top: ${DESKTOP_NAV_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.modalBackground};
  z-index: ${theme.zIndexes.mid};

  transition: ${(props) =>
    `opacity ${props.transitionDuration}ms, visibility ${props.transitionDuration}ms`};
  opacity: ${({ isClear, isOpen }) => (isOpen && !isClear ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

export const TabsRow = styled.div`
  background-color: ${theme.colors.white};
  padding: 0 0 var(--space-l);

  a {
    font-size: var(--step--1);

    & + a {
      margin-left: var(--space-s-m);
    }
  }

  @media (min-width: 1150px) {
    padding: 0 0 var(--space-m);

    a {
      font-size: var(--step-0);
    }
  }

  ${largeDesktop} {
    a + a {
      margin-left: var(--space-m-xl);
    }
  }
`;

export const TabsFlexRow = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 0 var(--site-gutter);
`;

export const ContentRow = styled.div`
  padding: var(--space-m-xl) 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 100%;
  background-color: ${theme.colors.offWhite};

  ${Container} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const LeftColumn = styled(Flow)`
  padding: 0 var(--site-gutter);
  flex-grow: 1;

  p {
    max-width: 60ch;
    margin: 0;

    + * {
      margin-top: var(--space-2xs);
    }

    a {
      color: inherit;
      text-decoration: underline;

      :hover {
        color: ${theme.colors.irisPurple};
      }
    }
  }
`;

export const Columns = styled.div`
  column-gap: var(--space-s);
  column-width: min(190px, 15vw);
  width: 100%;
`;

interface IColumnProps {
  allowWrap?: boolean;
}

export const Column = styled.div<IColumnProps>`
  position: relative;
  ${({ allowWrap }) =>
    !allowWrap &&
    css`
      break-inside: avoid;
      overflow: hidden;
    `}

  + * {
    margin-top: var(--space-xl);
  }

  > strong,
  > a {
    font-family: ${theme.fonts.heading};
    font-weight: ${theme.weights.normal};
  }

  ul {
    padding-left: 0;
    margin: var(--space-2xs) 0 0;

    &:empty {
      display: none;
    }
  }

  li {
    list-style: none;
    line-height: 1.4;

    a {
      display: flex;
      align-items: center;
    }

    img {
      margin-right: var(--space-xs);
      vertical-align: middle;
      width: 25px;
    }

    + li {
      margin-top: var(--space-2xs);
    }
  }
`;

interface IAdditionalContentProps {
  prioritiseAdverts?: boolean;
  advertCount: number;
}

export const AdditionalContent = styled.ul<IAdditionalContentProps>`
  padding: 0 var(--site-gutter) 0 0;
  margin: 0;
  display: flex;
  gap: var(--space-s) var(--space-s-l);
  flex-wrap: wrap;
  flex-basis: ${({ prioritiseAdverts }) => (prioritiseAdverts ? '40%' : '20%')};

  ${largeDesktop} {
    flex-basis: ${({ prioritiseAdverts, advertCount }) =>
      prioritiseAdverts ? '50%' : advertCount === 1 ? '25%' : '35%'};
  }

  > * {
    flex: 1;
    min-width: 150px;
  }
`;
