import * as Sentry from '@sentry/nextjs';
import { Severity } from '@sentry/nextjs';
import axios from 'axios';

import { AddressFields, ConfirmOrderResponse } from 'utils/checkout/types';
import { IGroupedOrder } from 'utils/commercetools/types';
import { throwServerSideError } from 'utils/helpers';

/**
 * Sends an API call to confirm the order providing the billingAddress
 * @async
 * @param {AddressFields} billingAddress
 * @returns {Promise<IGroupedOrder>} order
 */
export async function checkoutConfirmOrder({
  billingAddress,
  joinMailingList,
}: {
  billingAddress?: AddressFields;
  joinMailingList?: boolean;
} = {}): Promise<IGroupedOrder> {
  throwServerSideError();

  try {
    const result: ConfirmOrderResponse = await axios({
      method: 'post',
      url: `/api/checkout/confirm-order`,
      data: { billingAddress, joinMailingList },
    }).then((response) => response.data as ConfirmOrderResponse);

    if (result.error) {
      throw new Error(result.error?.message);
    }

    const { order } = result;

    if (!order || !order.id) {
      throw new Error('Checkout Confirm Order: Could not find order');
    }

    return order;
  } catch (error: any) {
    console.error(error);
    console.error(error.response?.data?.error?.message);
    Sentry.captureException(error, {
      contexts: {
        response: error.response,
        request: error.request,
        errorMessage: error.response?.data?.error?.message,
      },
      level: Severity.Fatal,
    });

    if (error.response?.data?.error?.message) {
      throw new Error(error.response?.data?.error?.message);
    }

    if (error.message) {
      throw new Error(error.message);
    }

    throw new Error('Failed to create order, please try again');
  }
}
