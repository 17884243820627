import React from 'react';

interface EcoProps {
  width?: number;
  height?: number;
}

export default function Eco({ width = 24, height = 24 }: EcoProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.4 15a7.3 7.3 0 0 0 6.2 4.3 7.2 7.2 0 0 0 6.7-3.6A7.4 7.4 0 0 0 15 5.2a7 7 0 0 0-2.7-.6h-.6A7.4 7.4 0 0 0 5.4 15Zm4.5-8.8c.4.3.8.8.8 1.2a.7.7 0 0 1 0 .5c-1 1.4-1.3 1.8-1.4 3.2 0 1.5-.5 3-3 3a6.3 6.3 0 0 1 3.6-8Zm3.1 12c0-.7-.2-1.2-.4-1.8-.1-.1-.6-1.8 0-2.8.8-1 1.7-.7 1.7-.6h.1s1.7.2 2.8 2.4A6.2 6.2 0 0 1 13 18Zm2.7-9c-.3-.4-.8-.6-1.3-.6-.4 0-.6 0-.8-.6-.2-.7.7-1.3 1.1-1.5 1.3.6 2.4 1.7 3 3a1.9 1.9 0 0 1-2-.4Z"
        fill="#000"
      />
      <path
        d="m23.6 5.8-.4.2c-.5.1-1 .4-1.3.8A11.2 11.2 0 0 0 12.5 1c-3 0-5.8 1-8 3.1-2.1 2-3.4 5-3.5 8v.3h1.4v-.3a10 10 0 0 1 7.1-9.3A9.6 9.6 0 0 1 20.3 7l-.8.1-.4.1.4 1.4.4-.1a1.2 1.2 0 0 1 1.4.5l.2.5v.5l1.4-.5-.1-.4v-.2c-.2-.3-.1-.7 0-1l.8-.5.4-.1-.4-1.4ZM21.7 12c0 2.6-1.1 5-3 6.9a9.7 9.7 0 0 1-6.8 2.8h-.1a9.7 9.7 0 0 1-8.2-4.6l.9-.1.4-.1-.4-1.4-.4.1a1.2 1.2 0 0 1-1.4-.5l-.2-.5-.1-.4-1.3.4.2.6a1.2 1.2 0 0 1-.2 1c-.1.2-.4.4-.7.5l-.4.1.4 1.4.4-.1c.5-.2 1-.5 1.3-1a11.3 11.3 0 0 0 9.7 6h.1c3 0 5.8-1.2 7.8-3.2 2.1-2.1 3.3-5 3.4-8v-.4h-1.4v.5Z"
        fill="#000"
      />
    </svg>
  );
}
