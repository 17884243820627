import styled, { css } from 'styled-components';

import theme from 'utils/theme';

import { Label } from '../label/styles';

interface ITextareaProps {
  bg: 'white' | 'lightestGrey';
  hasError?: boolean;
  floatingLabel?: boolean;
}

export const Textarea = styled.textarea<ITextareaProps>`
  width: 100%;
  display: block;
  border: 1px solid ${theme.colors.lightGrey};
  background-color: ${(props) => theme.colors[props.bg]};
  padding: var(--space-xs) var(--space-l);
  border-radius: var(--space-xl);
  min-height: 100px;
  resize: none;
  transition: border-color ${theme.transitionSpeeds.fast}s ease-out;

  ${(props) =>
    props.floatingLabel &&
    css`
      padding-top: var(--space-m);

      /* Increase label's visibility when scrolling textarea */
      + ${Label} {
        /* @TODO: This may be prettier/better */
        background-color: ${theme.colors.white};
      }
    `}

  &:focus {
    background-color: ${theme.colors.white};
    border-color: ${theme.colors.purple};
    outline: none;
    box-shadow: 0 0 0 1px ${theme.colors.purple};
  }

  &::placeholder {
    color: ${theme.colors.midGrey};
    opacity: 1;
  }

  /* @TODO: Some styling for scrolling, cause it looks really bad with rounded corners */

  ${(props) =>
    props.hasError &&
    css`
      border-color: ${theme.colors.errorRed};
    `}
`;
