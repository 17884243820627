import React from 'react';

interface Props {
  height?: number;
}

export default function Pot({ height = 80 }: Props) {
  return (
    <svg
      viewBox="0 0 60 61"
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m0 0 3 18h54l3-18H0ZM11 61h38l5-39H6l5 39Z" fill="#000" />
    </svg>
  );
}
