/**
 * Derived from https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
 */

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import * as Sentry from '@sentry/nextjs';
import { SentryLink } from 'apollo-link-sentry';
import deepmerge from 'deepmerge';
import { isEqual } from 'lodash';
import { useMemo } from 'react';

import { getLimitedCTClientAccessToken } from 'utils/commercetools/tokens/limited-client-token';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

// This is only initialized client-side so there's no client sharing during SSR
let apolloClient: ApolloClient<NormalizedCacheObject>;

function createApolloClient() {
  const clientUri =
    typeof window === 'undefined'
      ? `https://api.europe-west1.gcp.commercetools.com/${process.env.NEXT_PUBLIC_COMMERCETOOLS_PROJECT_KEY}/graphql`
      : `${window.location.origin}/api/commercetools/proxy`;

  // Default settings for the RetryLink https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  const retryLink = new RetryLink();
  const httpLink = createHttpLink({
    uri: clientUri,
  });

  const sentryLink = new SentryLink({
    setFingerprint: true,
    setTransaction: false,
    uri: clientUri,
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: false,
      includeFetchResult: false,
      includeError: true,
      includeCache: false,
    },
  });

  // log query errors
  const errorLink = onError((error) => {
    const { networkError } = error;

    if (networkError)
      Sentry.captureException(
        `[Network error] createApolloClient: ${networkError}`,
      );
  });

  const authLink = setContext(async (_, { headers, token }) => {
    let accessToken = token;

    if (!accessToken && typeof window === 'undefined') {
      accessToken = await getLimitedCTClientAccessToken();
    }

    return {
      headers: {
        ...headers,
        ...(accessToken ? { authorization: `Bearer ${accessToken}` } : {}),
      },
    };
  });

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: ApolloLink.from([
      sentryLink,
      authLink,
      retryLink,
      errorLink,
      httpLink,
    ]),
    connectToDevTools: process.env.VERCEL_ENV !== 'production',
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            me: {
              merge(existing, incoming, { mergeObjects }) {
                if (incoming === null) {
                  return null;
                }

                return mergeObjects(existing, incoming);
              },
            },
          },
        },
        Cart: {
          fields: {
            discountCodes: {
              merge(_existing = [], incoming: any[]) {
                return incoming;
              },
            },
          },
        },
        ProductVariant: {
          keyFields: ['sku'],
        },
        Order: {
          keyFields: ['orderNumber'],
        },
        DiscountCodeInfo: {
          keyFields: ['discountCodeRef', ['id']],
        },
      },
    }),
  });
}

export function initializeApollo(initialState: any = null) {
  const currentApolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = currentApolloClient.extract();

    // Merge data passed from getStaticProps/getServerSideProps into the existing cache
    // this will overwrite any client-side cache with the server-side returned data
    const data = deepmerge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    currentApolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') {
    return currentApolloClient;
  }

  // Assign newly created apolloClient to global apolloClient to avoid creating
  // multiple instances client-side
  if (!apolloClient) {
    apolloClient = currentApolloClient;
  }
  return currentApolloClient;
}

/**
 * To be used in getServerSideProps or getStaticProps to output the apollo client
 * cache on the page for hydration
 * @param client
 * @param pageProps
 * @returns pageProps
 */
export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: { revalidate?: number; props: any },
) {
  if (pageProps?.props) {
    // eslint-disable-next-line no-param-reassign
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

/**
 * To be used by Next to initialize the apollo cache
 * @param pageProps
 * @returns store
 */
export function useApollo(pageProps: any) {
  const state =
    typeof pageProps === 'object' && APOLLO_STATE_PROP_NAME in pageProps
      ? pageProps[APOLLO_STATE_PROP_NAME]
      : null;
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}

/**
 * initializes an Apollo client if it's undefined, used to simplify the fallback when the apollo
 * client is optional
 * @param {ApolloClient<NormalizedCacheObject>} client
 * @returns {ApolloClient<NormalizedCacheObject>}
 */
export function fallbackApolloClient(
  client?: ApolloClient<NormalizedCacheObject>,
): ApolloClient<NormalizedCacheObject> {
  if (!client) {
    return initializeApollo();
  }
  return client;
}
