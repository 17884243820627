import React from 'react';

import theme from 'utils/theme';

export default function TickBullet({
  className = '',
  width = 25,
  height = 25,
  fill = theme.colors.irisPurple,
}) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 23a10.4 10.4 0 1 0 0-21 10.4 10.4 0 0 0 0 21Zm0 2a12.5 12.5 0 1 0 0-25 12.5 12.5 0 0 0 0 25Z"
        fill={fill}
      />
      <path
        className="tick"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 7.4 19.5 9 11 18l-.3-.2-5.2-5.4L7 10.8l4 4.2 7-7.6Z"
        fill={fill}
      />
    </svg>
  );
}
