import { FC } from 'react';

import SiteLink from '../site-link';

import * as St from './styles';
import { IBoldableCTAProps, IBoldableLinkProps } from './types';

export const BoldableCTA: FC<IBoldableCTAProps> = ({
  label,
  icon,
  colorScheme,
  onClick,
}) => {
  return (
    <St.CTA onClick={onClick} type="button" colorScheme={colorScheme}>
      {icon}
      <St.BoldableLabel label={label}>{label}</St.BoldableLabel>
    </St.CTA>
  );
};

export const BoldableSiteLink: FC<IBoldableLinkProps> = ({
  label,
  ...props
}) => {
  return (
    <St.CTALink {...props} as={props.href === '' ? 'button' : SiteLink}>
      <St.BoldableLabel label={label}>{label}</St.BoldableLabel>
    </St.CTALink>
  );
};
